import React, { useState, useContext, useEffect } from 'react'
import DoughnutChart from '../components/DoughnutChart'
import WelcomePopup from '../components/WelcomePopup'
import { Dropdown, Image, Header } from 'semantic-ui-react';
import { BarChart } from '../components/BarChart';
import { sortUnitOptions, CardOptions, trigger } from "../constant/constant";

export default function BankIdDashboard({ dashboardData }) {


  const [dateHandle, setDateHandle] = useState()
  const [date_filter, set_date_filter] = useState("All")
  const [dateHandleBankIdSuccess, setDateHandleBankIdSuccess] = useState('all')
  const [dateHandleBankIdFailed, setDateHandleBankIdFailed] = useState('all')
  const [dateHandleBankIdSkipped, setDateHandleBankIdSkipped] = useState('all')
  const [dateHandleBankIdBarChart, setDateHandleBankIdBarChart] = useState('all')
  let barChartData = {}
  let doughtnutChartData = {}
  let success = []
  let failed = []
  let initated = []
  if (dashboardData?.length && dateHandle) {
    doughtnutChartData = {
      initiated: (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === dateHandle))[0])?.find((data) => data?.feature_event === "BANK_ID_INITIATED")?.count || 0,
      success: (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === dateHandle))[0])?.find((data) => data?.feature_event === "BANK_ID_SUCCESS")?.count || 0,
      failed: (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === dateHandle))[0])?.find((data) => data?.feature_event === "BANK_ID_FAILED")?.count || 0
    }
  }

  const handleDropdownChange = (e, data, index) => {
    setDateHandle(sortUnitOptions.find((option) => option.value === data.value).name)
    set_date_filter(data.value)
    if(Number(index)===0){
        setDateHandleBankIdSuccess(sortUnitOptions.find((option) => option.value === data.value).name)  
    }
    if(Number(index)===1){
        setDateHandleBankIdFailed(sortUnitOptions.find((option) => option.value === data.value).name)  
    }
    if(Number(index)===2){
        setDateHandleBankIdSkipped(sortUnitOptions.find((option) => option.value === data.value).name)  
    }
    if(Number(index)===3){
        setDateHandleBankIdBarChart(sortUnitOptions.find((option) => option.value === data.value).name)  
    }
}

  const WidgetData = [
    {
      icon: '/assets/images/successful.svg',
      title: 'Successful Authentication',
      amount: dateHandleBankIdSuccess && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === dateHandleBankIdSuccess))[0])?.find((data) => data?.feature_event === "BANK_ID_SUCCESS")?.count || 0 : 0,
      // day: date_filter,
      personalUserCount: dateHandleBankIdSuccess && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === `personal_user_${dateHandleBankIdSuccess}`))[0])?.find((data) => data?.feature_event === "BANK_ID_SUCCESS")?.count || 0 : 0,
      businessUserCount: dateHandleBankIdSuccess && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === `business_user_${dateHandleBankIdSuccess}`))[0])?.find((data) => data?.feature_event === "BANK_ID_SUCCESS")?.count || 0 : 0,
    },
    {
      icon: '/assets/images/failed.svg',
      title: 'Failed Authentication',
      amount: dateHandleBankIdFailed && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === dateHandleBankIdFailed))[0])?.find((data) => data?.feature_event === "BANK_ID_FAILED")?.count || 0 : 0,
      day: 'Today',
      
      personalUserCount: dateHandleBankIdFailed && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === `personal_user_${dateHandleBankIdFailed}`))[0])?.find((data) => data?.feature_event === "BANK_ID_FAILED")?.count || 0 : 0,
      businessUserCount: dateHandleBankIdFailed && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === `business_user_${dateHandleBankIdFailed}`))[0])?.find((data) => data?.feature_event === "BANK_ID_FAILED")?.count || 0 : 0,
    },
    {
      icon: '/assets/images/locked-out.svg',
      title: 'Initiated Authentication',
      amount: dateHandleBankIdSkipped && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === dateHandleBankIdSkipped))[0])?.find((data) => data?.feature_event === "BANK_ID_INITIATED")?.count || 0 : 0,
      day: "All",
      personalUserCount: dateHandleBankIdSkipped && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === `personal_user_${dateHandleBankIdSkipped}`))[0])?.find((data) => data?.feature_event === "BANK_ID_INITIATED")?.count || 0 : 0,
      businessUserCount: dateHandleBankIdSkipped && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === `business_user_${dateHandleBankIdSkipped}`))[0])?.find((data) => data?.feature_event === "BANK_ID_INITIATED")?.count || 0 : 0,
    },
  ]

  const HandleFilterDays = (e, data) => {
    setDateHandle(sortUnitOptions.find((option) => option.value === data.value).name)
    set_date_filter(data.value)
  }

  dashboardData.map((data) => {
    if (Object.keys(data)[0] === "last_five_months") {
      data[Object.keys(data)[0]].map((data1) => {
        if (data1.feature_event.includes("BANK_ID")) {

          const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
          ];

          let month = monthNames[data1["Year-Month"].split("-")[1] - 1]
          if (data1.feature_event.includes("SUCCESS")) {
            success.push({ count: data1.Count, month: month })
          }
          else if (data1.feature_event.includes("FAILED")) {
            failed.push({ count: data1.Count, month: month })
          }
          else if (data1.feature_event.includes("INITIATED")) {
            initated.push({ count: data1.Count, month: month })
          }


        }
      })
      barChartData = { "initated": initated, "success": success, "failed": failed }
    }
  })


  useEffect(() => {
    setDateHandle("all")
  }, [])



  return (
    <div className='py-2'>
      <div className='mx-2'>
        <h2 className='text-white fs-6 mb-4 fw-300 d-flex align-items-center'><span className='fs-8'><img height='17' width='17' src='/assets/images/dashboard.svg' alt='Filter' /><span className='veritical-align-text-top pl-1 fs-7'>Welcome To Dashboard</span></span></h2>
        <div className='widgets'>
          <div className='row'>
            {WidgetData.map((data,index) => (
              <div className='col-lg-4 col-md-4 col-sm-12 pr-1 pr-sm-0 px-sm-1 mb-2'>
                <div style={{ borderRadius: '2px' }} className='bg-white shadow-sm py-2 mh-100 px-2'>
                  <div className='row align-items-center'>
                    <div className='col-lg-2 col-md-2 col-sm-2'>
                      <div>
                        <img className='w-75 h-75' src={data.icon} alt='Icon' />
                      </div>
                    </div>
                    <div className='col-lg-10 col-md-10 col-sm-10'>
                      <div className='d-flex align-items-center justify-content-between mb-1'>
                        <p className='fs-7 text-gray fw-500 text-truncate'>{data.title}</p>
                        <div className='widget-dropdown text-right'>
                          <Dropdown downward floating options={CardOptions} trigger={trigger} icon="null" onChange={(e,data1)=>handleDropdownChange(e,data1,index)} />
                        </div>
                      </div>
                      <div className='d-flex align-items-start justify-content-between'>
                        <div>
                          <h5 className='fs-4 fw-600 mb-1'>{data.amount}</h5>
                          {/* <p className='font-8 text-dark'><span>{data.day}</span></p> */}
                          {/* <p className='font-8 text-dark'><span className='fs-9em'>Personal Users - {data.personalUserCount}</span><span className='fs-9em ml-2'>Business Users - {data.businessUserCount}</span></p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='row my-2'>
          <div className='col-lg-6 col-md-6 col-sm-12 pr-1 pr-sm-0 px-sm-1 mb-2'>
            <div className='bar-chart-div mh-100 bg-white shadow-sm border-radius-5'>
              <h5 className='border-bottom py-3 px-3 fs-6 fw-500 text-dark'>Authentication Status</h5>
              <div className='p-5 p-sm-1'>
                <BarChart dashboardData={barChartData} />
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 pr-1 pr-sm-0 px-sm-1 mb-2'>
            <div className='doughnut-chart-div mh-100 bg-white shadow-sm border-radius-5'>
              <div className='border-bottom py-3 px-3 d-flex align-items-center justify-content-between'>
                <h5 className='fs-6 fw-500 text-dark'>Authentication Status</h5>
                <div className='sort-div text-right'>
                  <Header as='h4'>
                    <Header.Content>
                      <Dropdown
                        floating
                        inline
                        options={sortUnitOptions}
                        // defaultValue={sortUnitOptions[0].value}
                        value={date_filter}
                        onChange={HandleFilterDays}
                      />
                    </Header.Content>
                  </Header>
                </div>
              </div>
              <div className='p-5 p-sm-1'>
                <DoughnutChart dashboardData={doughtnutChartData} />
              </div>
            </div>
          </div>
        </div>
        <WelcomePopup />
      </div>
    </div>
  )
}
