import React from 'react'
import CreditCheckSetting from "./CreditCheckSetting";
import EsignatureSetting from './EsignatureSetting';
import BankIdSetting from "./BankIdSetting";
import WelcomePopup from '../components/WelcomePopup';

const Settings = (props) => {
  return (
    <>
      {props.creditcheck && <CreditCheckSetting />}
      {props.esign && <EsignatureSetting />}
      {props.bankid && <BankIdSetting />}
      <WelcomePopup />
    </>
  )
}

export default Settings