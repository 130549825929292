import React, { useState, useEffect, useContext, useRef } from "react";
import { Dropdown, Pagination, Input, Icon, Popup } from "semantic-ui-react";
import WelcomePopup from "../components/WelcomePopup";
import TenantsProfile from "./TenantsProfile";
import { IntegrationService } from '../services/integrationService';
import { STATUS, EVENT_TYPE, TosterVariant, ROW_OPTION, tenantLocationOption, tenantTypeOption, tenantStatusOption } from "../constant/constant"
import { appContext } from '../context/appContext';


export default function EsignautreTenants(props) {
  const [filterData, setFilterData] = useState({})
  const [tenantEsignDetails, setTenantEsignDetails] = useState([])
  /* pagination and filter */
  const rowOptions = [10, 25, 50]
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("")

  const [esignFilter, setEsignFilter] = useState({ location: "", tenant_status: "", tenant_type: "", esign_status: "", })
  const [showprofile, setShowProfile] = useState(false);
  const [tenantDetails, setTenantDetails] = useState({})
  // const [checkedAll, setCheckedAll] = useState(false);
  // const [checked, setChecked] = useState({
  //   chk: false,
  //   chk1: false,
  // });
  const [locations, setLocations] = useState([])
  const initialCount = useRef(0);
  const tenantsData = [
    {
      tenantsChecked: "chk",
      profileImage: "/assets/images/post-tenant-img.png",
      tenantName: "Alexendria Aliex",
      tenantEmail: "Alex@gmail.com",
      tenantNumber: "+91 79294548574",
      tenantType: "Business User",
      eSignStatus: "Success",
      creditScore: "AAA",
    },
    {
      tenantsChecked: "chk1",
      profileImage: "/assets/images/post-tenant-img.png",
      tenantName: "Alexendria Aliex",
      tenantEmail: "Alex@gmail.com",
      tenantNumber: "+91 79294548574",
      tenantType: "Business User",
      eSignStatus: "Failed",
      creditScore: "AA",
    },
  ];


  const eSignStatusOption = [
    {
      key: 1,
      text: "All",
      value: "All",
    },
    {
      key: 2,
      text: "Signed",
      value: "SIGNED",
    },
    {
      key: 3,
      text: "Initiated",
      value: "INITIATED",
    },
    // {
    //   key: 4,
    //   text: "Failed",
    //   value: "FAILED",
    // },
    // {
    //   key: 5,
    //   text: "Expired",
    //   value: "EXPIRED",
    // },
  ];

  /*context*/
  const { setSpinnerVisibility, showToaster, setCheckedValue, checkedValue } = useContext(appContext)

  /* service */
  const integrationService = new IntegrationService();

  const integerationAPI = async (payloadData) => {
    try {

      const { data } = await integrationService.integration({
        event_type: EVENT_TYPE.getTenantEsignDetails,
        rows_per_page: rowsPerPage,
        page: activePage,
        search: search,
        ...filterData
      })
      if (data?.status === STATUS.success && data.body) {
        setTenantEsignDetails(data.body.result)
        data.body.result.length === 0 && setActivePage(1)
        setTotalPages(data.body.pageCount)
      }
    } catch (error) {
      console.log("error", error);
      return error
    }
  }

  /* get locations api */
  const getLocation = async () => {
    try {
      // setSpinnerVisibility(true)
      const { data } = await integrationService.integration({
        event_type: EVENT_TYPE.getLocation,
      })
      // setSpinnerVisibility(false)
      if (data?.status === STATUS.success && data.body) {
        let locationData = data?.body?.length > 1 ? [{
          key: 0,
          value: "All",
          text: "All"
        }] : [];
        data?.body?.map((location, index) => {
          if (location && location.name) {
            locationData.push({
              key: index + 1,
              value: location?.name,
              text: location?.name
            })
          }

        })
        setLocations(locationData)

      }
    } catch (error) {
      // setSpinnerVisibility(false)
      console.log("error", error);
    }
  }

  /* Service End */

  const handleFilter = (e, data) => {
    data.value === "All" ? setEsignFilter({ ...esignFilter, [data.name]: "" }) : setEsignFilter({ ...esignFilter, [data.name]: data.value })
    // setEsignFilter({ ...esignFilter, [data.name]: data.value })
  }

  const handleFilterButtonTrigger = (e) => {
    e.preventDefault();
    setFilterData(esignFilter);
  }

  // const toggleCheck = (inputName) => {
  //   setChecked((prevState) => {
  //     const newState = { ...prevState };
  //     newState[inputName] = !prevState[inputName];
  //     return newState;
  //   });
  // };

  // const selectAll = (value) => {
  //   setCheckedAll(value);
  //   setChecked((prevState) => {
  //     const newState = { ...prevState };
  //     for (const inputName in newState) {
  //       newState[inputName] = value;
  //     }
  //     return newState;
  //   });
  // };

  const handlePaginationChange = (e, { activePage }) => { setActivePage(activePage) }
  const handleTotalRows = (e) => { setRowsPerPage(e.target.value) }
  const handleSearch = (e) => {
    if (e.target.value.length) {
      setSearch(e.target.value);
      setActivePage(1);
    }
    else setSearch("");
  }

  const handleCheckbox = (e, value) => {
    if (e.target.name === "currentPageCheck") {
      if (e.target.checked) {
        setCheckedValue(tenantEsignDetails)
      }
      else {
        setCheckedValue([])
      }
    }
    else if (e.target.name === "checkedItem") {
      if (e.target.checked) {
        setCheckedValue((prev) => ([...prev, value]))
      }
      else {
        setCheckedValue((prev) => prev.filter((data) => JSON.stringify(data) !== JSON.stringify(value)))
      }
    }

  }

  // useEffect(() => {
  //   let allChecked = true;
  //   for (const inputName in checked) {
  //     if (checked[inputName] === false) {
  //       allChecked = false;
  //     }
  //   }
  //   if (allChecked) {
  //     setCheckedAll(true);
  //   } else {
  //     setCheckedAll(false);
  //   }
  // }, [checked]);
  const initialCallWithloder = async () => {
    setSpinnerVisibility(true)
    await integerationAPI();
    setSpinnerVisibility(false)
  }

  useEffect(() => {
    if (initialCount) {
      integerationAPI()
    }
  }, [search])

  useEffect(() => {
    initialCallWithloder();
    initialCount.current = initialCount.current + 1

  }, [activePage, rowsPerPage, filterData])

  useEffect(() => {
    getLocation()
    setCheckedValue([])
    return () => {
      setCheckedValue([])
    }
  }, [])


  return (
    <div className="py-2">
      <div className="mx-2">
        <h2 className="text-white fs-6 mb-4 fw-300 d-flex align-items-center">
          Tenants
          <span className="fs-8 PageSub-title px-1 ml-1">
            <img
              height="17"
              width="17"
              src="/assets/images/bank-id.svg"
              alt="Filter"
            />
            <span className="veritical-align-text-top pl-1">
              E-Signature Filter
            </span>
          </span>
        </h2>
        {!showprofile && (
          <div className="tenants-filter-div">
            <div className="bg-white shadow border-radius-15 mb-3">
              <h5 className="border-bottom py-3 px-3 fs-6 fw-500 text-primary-light">
                E-SIGN FILTER
              </h5>
              <div className="px-1 py-3">
                <form class="ui form">
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12 px-2 mb-2 mb-2">
                      <div class="field">
                        <label className="fw-500 mb-1">Select Location</label>
                        <Dropdown
                          placeholder="Select Location"
                          clearable
                          fluid
                          floating
                          selection
                          options={locations}
                          name={"location"}
                          forceSelection={false}
                          selectOnBlur={false}
                          onChange={handleFilter}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 px-2 mb-2">
                      <div class="field">
                        <label className="fw-500 mb-1">Tenant Status</label>
                        <Dropdown
                          placeholder="Tenant Status"
                          clearable
                          fluid
                          floating
                          selection
                          forceSelection={false}
                          selectOnBlur={false}
                          options={tenantStatusOption}
                          name={"tenant_status"}
                          onChange={handleFilter}
                        />

                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 px-2 mb-2">
                      <div class="field">
                        <label className="fw-500 mb-1">Tenant Type</label>
                        <Dropdown
                          placeholder="Tenant Type"
                          clearable
                          fluid
                          floating
                          selection
                          options={tenantTypeOption}
                          forceSelection={false}
                          selectOnBlur={false}
                          name={"tenant_type"}
                          onChange={handleFilter}
                        />

                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12 px-2 mb-2">
                      <div class="field">
                        <label className="fw-500 mb-1">E-sign Status</label>
                        <Dropdown
                          placeholder="E-Signature verified status"
                          clearable
                          fluid
                          floating
                          selection
                          options={eSignStatusOption}
                          name={"esign_status"}
                          forceSelection={false}
                          selectOnBlur={false}
                          onChange={handleFilter}
                        />

                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 px-2">
                      <button
                        class="ui button bg-primary text-white px-4 fs-7 fw-400"
                        onClick={handleFilterButtonTrigger}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="tenants-table-div">
              <div className="row pt-4 pb-2">
                <div className="col-lg-6 col-md-6 col-sm-12 text-left text-sm-center mb-1">
                  <label className="show-entry py-1 px-1 border-radius-5 d-inline-block">
                    Show
                    <select className="border-0 cursor-pointer outline-0" onChange={handleTotalRows}>
                      {ROW_OPTION.map((option) => {
                        return <option value={option}>{option}</option>
                      })}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-sm-center text-right mb-1">
                  <div>
                    <div>
                      <Input
                        className="w-75"
                        iconPosition="left"
                        icon="search"
                        placeholder="Search..."
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tenantsTable-content bg-white shadow-sm border-radius-10 overflow-x-auto w-100">
                <div className="tenantsTable">
                  <table className="w-100 table-layout-fixed">
                    <thead>
                      <tr>
                        {/*  'DONT DELETE' ===> there is no functinality added to the checkbox leter we can uncomment this*/}

                        {/* <th className="text-center w-10">
                        <input
                            type="checkbox"
                            // onChange={(event) =>
                            //   selectAll(event.target.checked)
                            // }
                            name="currentPageCheck"
                            onChange={(e)=>handleCheckbox(e)}
                            checked={tenantEsignDetails?.length && tenantEsignDetails?.every((data)=>checkedValue?.some((data1)=>JSON.stringify(data1)===JSON.stringify(data)))}
                          />
                        </th> */}
                        <th className="text-left">CUSTOMERS</th>
                        {/* <th className="text-center">UNIT INFORMATION</th> */}
                        <th className="text-center">TENANT TYPE</th>
                        <th className="text-center">E-SIGN STATUS</th>
                      </tr>
                    </thead>
                  </table>
                  <div className="ScrollBody overflow-y-auto">
                    <table className="w-100 table-layout-fixed" textAlign='left'>
                      <tbody>
                        {tenantEsignDetails?.length ? tenantEsignDetails?.map((tenant_details) => {
                          return (
                            <tr>
                              {/*  'DONT DELETE' ===> there is no functinality added to the checkbox leter we can uncomment this*/}

                              {/* <td className="text-center w-10">
                              <input
                                  type="checkbox"
                                  name="checkedItem"
                                  // onChange={() =>
                                  //   toggleCheck(tenant_details?.tenantsChecked)
                                  // }
                                  onChange={(e) => handleCheckbox(e,tenant_details)}
                                  checked={checkedValue?.some((data)=>JSON?.stringify(data)===JSON?.stringify(tenant_details))}
                                />
                              </td> */}
                              <td className="text-left">
                                <div className="d-flex justify-content-left align-items-start cursor-pointer" onClick={() => { setTenantDetails(tenant_details); setShowProfile(true) }}>
                                  <div className="tenants-profile ">
                                    <img
                                      src={tenant_details.photo_path || '/assets/images/dummy-profile-pic.jpg'}
                                      alt="Profile"
                                      rounded
                                    />
                                  </div>
                                  <div className="ml-2 text-left">
                                    <Popup wide inverted size='tiny'
                                      trigger={<span><p className="fs-6 fw-500 text-break">{(tenant_details?.first_name ? tenant_details?.first_name : '') + ' ' + (tenant_details?.last_name ? tenant_details?.last_name : '')}</p><p className="text-break">{tenant_details.email}</p><p>{tenant_details.mobile}</p></span>}
                                      content={<span><p className="fs-6 fw-500 text-break">{(tenant_details?.first_name ? tenant_details?.first_name : '') + ' ' + (tenant_details?.last_name ? tenant_details?.last_name : '')}</p><p className="text-break">{tenant_details.email}</p><p>{tenant_details.mobile}</p></span>}
                                      hideOnScroll
                                    />
                                    {/* <p
                                      className="fs-6 fw-500 text-break"

                                    >
                                      {(tenant_details?.first_name ? tenant_details?.first_name : '') + ' ' + (tenant_details?.last_name ? tenant_details?.last_name : '')}
                                    </p>
                                    <p className="text-break">{tenant_details.email}</p>
                                    <p>{tenant_details.mobile}</p> */}
                                  </div>
                                </div>
                              </td>
                              {/* <td>
                              <div className="fs-10 w-7 text-center">
                                    <p
                                      className="fs-6  fw-500"
                                    >
                                      {tenant_details?.unit_number}
                                    </p>
                                    <p>{tenant_details?.building_name}</p>
                                    <p>{tenant_details?.location}</p>
                                  </div>
                              </td> */}

                              <td className="fs-10 text-center">
                                <p>{tenant_details.business_user ? "Business User" : "Personal User"}</p>
                              </td>
                              <td className="text-center">
                                {/* <label
                                  className={`${tenant_details.eSignStatus === "Success"
                                    ? "label-success"
                                    : "label-danger"
                                    }`}
                                >
                                  {tenant_details.eSignStatus}
                                </label> */}

                                {tenant_details?.esign_details?.esign_status ? tenant_details?.esign_details?.esign_status : "N/A"}
                              </td>
                            </tr>
                          );
                        }) :
                          <tr>
                            <td align='center' colSpan={4}>
                              No Records
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="pagination-div mt-2 mb-3 text-center">
                <Pagination
                  ellipsisItem={{
                    content: <Icon name="ellipsis horizontal" />,
                    icon: (totalPages > 10)
                  }}
                  firstItem={{
                    content: <Icon name="angle double left" />,
                    icon: true,
                    disabled: (activePage === 1)
                  }}
                  lastItem={{
                    content: <Icon name="angle double right" />,
                    icon: true,
                    disabled: (!totalPages || activePage === totalPages)
                  }}
                  prevItem={{ content: <Icon name="angle left" />, icon: true, disabled: (activePage === 1) }}
                  nextItem={{
                    content: <Icon name="angle right" />,
                    icon: true,
                    disabled: (!totalPages || activePage === totalPages)
                  }}
                  onPageChange={handlePaginationChange}
                  activePage={activePage}
                  totalPages={totalPages}
                  disabled={totalPages === 1}
                />
              </div>
            </div>
          </div>
        )}
        {showprofile && (
          <TenantsProfile onProfile={() => setShowProfile(false)} tenantEsignDetailsProp={tenantDetails} esign={props.esign} />
        )}
        <WelcomePopup />
      </div>
    </div>
  );
}
