import { API_ENDPOINTS } from "../constant/constant";
import { httpService } from "./httpBaseService";

export class IntegrationService extends httpService{
    responce={}
     async integration(integrationData){
        try{
            const responce=await this.httpClient.post(API_ENDPOINTS.integration,integrationData)
            return responce;
        }
        catch(error){
            return error
        }
    }

    async creditCheck(integrationData){
        try{
            const responce=await this.httpClient.post(API_ENDPOINTS.creditCheck,integrationData)
            return responce;
        }
        catch(error){
            return error
        }
    }
}