import React, { useState, useEffect, useContext, useRef } from 'react';
import { Checkbox, Input } from 'semantic-ui-react'
import WelcomePopup from '../components/WelcomePopup';
import { useForm } from "react-hook-form"
import { IntegrationService } from "../services/integrationService";
import { STATUS, EVENT_TYPE, TosterVariant, getEsignSettings, upsertEsignSettings } from "../constant/constant"
import { appContext } from "../context/appContext";

const EsignatureSetting = () => {

    const { setValue, register, handleSubmit, formState: { errors }, trigger, watch, setError, clearErrors } = useForm({
        defaultValues: {
            esign_validity: "0",
            esign_link_expiry: "0",
            is_enabled_in_booking_portal: false,
            is_enabled_in_management_software: false,
            booking_portal_for_person: false,
            booking_portal_for_business: false,
            management_software_for_person: false,
            management_software_for_business: false
        }
    }
    )
    // const [agreement1, setAgreement1] = useState(true);
    // const [agreement2, setAgreement2] = useState(true);
    // const [agreement3, setAgreement3] = useState(true);
    // const [agreement4, setAgreement4] = useState(true);

    /*context*/
    const { setSpinnerVisibility, showToaster, userDetails } = useContext(appContext)

    /* service */
    const integrationService = new IntegrationService();

    const integerationAPI = async (payloadData) => {
        try {
            setSpinnerVisibility(true)
            const { data } = await integrationService.integration({
                ...payloadData
            })
            setSpinnerVisibility(false)
            return data
        } catch (error) {
            setSpinnerVisibility(false)
            showToaster(TosterVariant.failed, "")
            console.log("error", error);
            return error
        }
    }
    /* Service End */

    /* submit handler for form */
    const onSubmit = async (formData) => {
        try {
            const payload = {
                event_type: EVENT_TYPE.upsertEsignSettings,
                country_code: "NOR",
                integrated_with: "signicat",
                is_enabled_in_management_software: formData.is_enabled_in_management_software,
                enable_in_booking_portal_for: (formData.booking_portal_for_person && formData.booking_portal_for_business) ? "BOTH" : (formData.booking_portal_for_person ? "PERSONAL" : formData.booking_portal_for_business ? "BUSINESS" : ""),
                is_enabled_in_booking_portal: formData.is_enabled_in_booking_portal,
                enable_in_management_software_for: (formData.management_software_for_person && formData.management_software_for_business) ? "BOTH" : (formData.management_software_for_person ? "PERSONAL" : formData.management_software_for_business ? "BUSINESS" : ""),
                esign_validity: formData.esign_validity,
                esign_link_expiry: formData.esign_link_expiry,
            }

            if ( watch("esign_validity") && watch("esign_validity")>=0 && watch("esign_validity") <= 24) {
                clearErrors('esign_validity')
            }
            else {
                setError('esign_validity', { type: 'custom', message: 'Value range must be 0 to 24' })
                return 
            }
            const response = await integerationAPI(payload)
            if (response.status === STATUS.success) {
                showToaster(TosterVariant.success, " E-Signature  updated successfully.")
            }
        } catch (error) {
            showToaster(TosterVariant.failed, "Failed to Save Esign Settings")
        }

    }

    /* getting the datas of credit check Settings */
    const getCreditCheckSettings = async () => {
        try {
            const payload = {
                event_type: EVENT_TYPE.getEsignSettings,
                country_code: "NOR",
                integrated_with: "signicat"
            }
            const data = await integerationAPI(payload);
            if (data?.status === STATUS.success) {
                setValue("is_enabled_in_management_software", data.body.is_enabled_in_management_software)
                setValue("is_enabled_in_booking_portal", data.body.is_enabled_in_booking_portal)
                setValue("esign_link_expiry", data.body.esign_link_expiry)
                setValue("esign_validity", data.body.esign_validity)
                setValue("management_software_for_business", (data?.body?.enable_in_management_software_for === "BOTH" || data?.body?.enable_in_management_software_for === "BUSINESS") ? true : false)
                setValue("management_software_for_person", (data?.body?.enable_in_management_software_for === "BOTH" || data?.body?.enable_in_management_software_for === "PERSONAL") ? true : false)
                setValue("booking_portal_for_business", (data?.body?.enable_in_booking_portal_for === "BOTH" || data?.body?.enable_in_booking_portal_for === "BUSINESS") ? true : false)
                setValue("booking_portal_for_person", (data?.body?.enable_in_booking_portal_for === "BOTH" || data?.body?.enable_in_booking_portal_for === "PERSONAL") ? true : false)
            }
        } catch (error) {
            showToaster(TosterVariant.failed, "fetch esign settings details failed")
            console.log(error);
        }
    }

    /* onChange function for checkbox */
    const handleCheckedChange = (e, { name, checked }) => {
        setValue(name, checked);
        trigger(name);
    };

   
    // const handleChanged1 = (event) => {
    //     setAgreement1(event.target.checked);
    //   }
    //   const handleChanged2 = (event) => {
    //     setAgreement2(event.target.checked);
    //   }
    //   const handleChanged3 = (event) => {
    //     setAgreement3(event.target.checked);
    //   }
    //   const handleChanged4 = (event) => {
    //     setAgreement4(event.target.checked);
    //   }
     
    
  
    // const handleChanged1 = (event) => {
    //     setAgreement1(event.target.checked);
    //   }
     
    //   const handleChanged3 = (event) => {
    //     setAgreement3(event.target.checked);
    //   }

    /* onChange function for simaticUI inputfield */
    const handlValueChange = (e, { name, value }) => {
        if(!isNaN(value)){
        if (name === "esign_validity") {
            if (value && value>=0 && value <= 24) {
                clearErrors(name)
                setValue(name, value);
                // trigger(name);
            }
            else {
                setError(name, { type: 'custom', message: 'Value range must be 0 to 24' })
                setValue(name, value);
                // trigger(name);
            }

        }
        else if (name === "esign_link_expiry") {
            if(!value){
                setError(name, { type: 'custom', message: 'Value is required' })
                setValue(name, value);
            }
            else if (value && value>0 && value <= 365) {
                clearErrors(name)
                setValue(name, value);
            }
            else {
                setError(name, { type: 'custom', message: 'Invalid range' })
                setValue(name, value);
            }

        }
    }
    }
    useEffect(()=>{
        if(watch("is_enabled_in_management_software") && !(watch("management_software_for_business") || watch("management_software_for_person"))){
           setError('is_enabled_in_management_software' , { type: 'custom', message: '*Please select atleast one option' })
           return
       }
       else{
           clearErrors('is_enabled_in_management_software')
       }

       if(watch("is_enabled_in_booking_portal") && !(watch("booking_portal_for_person") || watch("booking_portal_for_business"))){
           setError('is_enabled_in_booking_portal' , { type: 'custom', message: '*Please select atleast one option' })
           return
       }
       else{
           clearErrors('is_enabled_in_booking_portal')
       }
    },[watch("is_enabled_in_management_software") ,watch("management_software_for_business"),watch("management_software_for_person"),watch("is_enabled_in_booking_portal") , watch("booking_portal_for_person"), watch("booking_portal_for_business")])

    useEffect(() => {
        getCreditCheckSettings();
    }, []) 


    return (
        <div className='py-2'>
            <div className='mx-2'>
                <h2 className='text-white fs-6 mb-4 fw-300 d-flex align-items-center'>
                    Settings
                    <span className='fs-8 PageSub-title px-1 ml-1'>
                        <img height='17' width='17' src='/assets/images/bank-id.svg' alt='Filter' />
                        <span className='veritical-align-text-top pl-1'>E-Signature Settings</span>
                    </span>
                </h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='bg-white shadow border-radius-15 mb-3'>
                        <h5 className='border-bottom py-3 px-3 fs-6 fw-500 text-primary-light'>E-SIGN FEATURE</h5>
                        <div className='px-3 py-3'>
                            <div className='mb-4'>
                                <Checkbox
                                    className='mb-3 fs-7'
                                    toggle
                                    label='Enable E-sign feature in Management Software'
                                    name="is_enabled_in_management_software"
                                    checked={watch("is_enabled_in_management_software")}
                                    onChange={handleCheckedChange}
                                />
                                {watch("is_enabled_in_management_software") && <div className="col-md-10 col-sm-12">
                                <div className="row">
                                    <div className="col-lg-2 col-md-4 col-sm-12">
                                        <div className="d-flex align-items-center my-sm-2">
                                            <input id='PersonalUserSoftware' type="checkbox" className="mr-1" checked={watch("management_software_for_person")} {...register("management_software_for_person")}  />
                                            <label htmlFor='PersonalUserSoftware'>Personal User</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12">
                                        <div className="d-flex align-items-center my-sm-2">
                                            <input id='BusinessUserSoftware' type="checkbox" className="mr-1" checked={watch("management_software_for_business")}  {...register("management_software_for_business")} />
                                            <label htmlFor='BusinessUserSoftware'>Business/Corporate user</label>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-red">{errors?.is_enabled_in_management_software ? "*Please select atleast one option " : "" }</p>
                                
                            </div>}
                            </div>


                            <div className='mb-4'>
                            <Checkbox
                                className="mb-3 fs-7"
                                toggle
                                label="Enable E-sign feature in Booking Portal"
                                name="is_enabled_in_booking_portal"
                                checked={watch("is_enabled_in_booking_portal")}
                                onChange={handleCheckedChange}
                            />
                           {watch("is_enabled_in_booking_portal") && <div className="col-md-10 col-sm-12">
                                <div className="row">
                                    <div className="col-lg-2 col-md-4 col-sm-12">
                                        <div className="d-flex align-items-center my-sm-2">
                                        <input id='PersonalUserBookingPortal' type="checkbox" className="mr-1" checked={watch("booking_portal_for_person")} {...register("booking_portal_for_person")} />
                                            <label htmlFor='PersonalUserBookingPortal'>Personal User</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12">
                                        <div className="d-flex align-items-center my-sm-2">
                                        <input id='BusinessUserBookingPortal' type="checkbox" className="mr-1" checked={watch("booking_portal_for_business")} {...register("booking_portal_for_business")} />
                                            <label htmlFor='BusinessUserBookingPortal'>Business/Corporate user</label>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-red">{errors?.is_enabled_in_booking_portal ? "*Please select atleast one option " : "" }</p>
                            </div>}
                        </div>
                        </div>
                    </div>
                    {/* <div className='bg-white shadow border-radius-15'>
                        <h5 className='border-bottom py-3 px-3 fs-6 fw-500 text-primary-light'>E-SIGN PERFORMANCE</h5>
                        <div className='px-3 py-3'>
                            <div className='mb-4'>
                                <span className="mr-3"> The E-Signature will be limited to performing more than once within </span>
                                <Input
                                    label="Hrs"
                                    size="mini"
                                    labelPosition="right"
                                    placeholder="hours"
                                    maxLength="2"
                                    min="1"
                                    name="esign_validity"
                                    value={watch("esign_validity")}
                                    onChange={handlValueChange}
                                />
                                <span className="ml-2">
                                 hours of the previous credit check
                                </span>
                                <div className='errorText'>
                                    {errors?.esign_validity && <label className='w-30 text-nowrap fw-500 mb-sm-2'>*{errors?.esign_validity?.message}</label>}
                                </div>
                            </div>
                            <div className="d-flex justify-content-start mb-3">
                                <p className="border-dashed-purple border-radius-5 bg-light-purple d-flex align-items-center p-1 fs-8"><svg className='min-width-1' id="warning-svgrepo-com_1_" data-name="warning-svgrepo-com (1)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 43 43">
                                    <path id="Path_15052" data-name="Path 15052" d="M20.717,2A18.717,18.717,0,1,0,39.433,20.716,18.717,18.717,0,0,0,20.717,2Zm0,34.759A16.043,16.043,0,1,1,36.759,20.717,16.043,16.043,0,0,1,20.717,36.759Z" transform="translate(0.674 0.674)" fill="none" />
                                    <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" width="43" height="43" fill="none" />
                                    <circle id="Ellipse_3" data-name="Ellipse 3" cx="17" cy="17" r="17" transform="translate(4 4)" fill="#ffe99b" />
                                    <g id="Group_7137" data-name="Group 7137" transform="translate(19.385 9.829)">
                                        <rect id="Rectangle_1690" data-name="Rectangle 1690" width="3" height="16" transform="translate(0.615 0.171)" fill="#ffc700" />
                                        <path id="Path_15053" data-name="Path 15053" d="M16.505,22a2.005,2.005,0,1,0,2.005,2.005A2.005,2.005,0,0,0,16.505,22Z" transform="translate(-14.5 -2.417)" fill="#ffc700" />
                                    </g>
                                </svg>
                                    <span className="ml-1 fw-500">E-Signature will not be performed for the particular tenant if he/she comes back to complete the move-in process</span></p>
                            </div>
                            <div className='mb-3'>
                                <span className="mr-2">E-Signature link will expires after</span>
                                <Input
                                    label="Days"
                                    size="mini"
                                    labelPosition="right"
                                    placeholder="Days"
                                    maxLength="3"
                                    min="1"
                                    name="esign_link_expiry"
                                    value={watch("esign_link_expiry")}
                                    onChange={handlValueChange}
                                />
                                <span className="ml-2">
                                    from move-In date
                                </span>
                                <div className='errorText-2'>
                                    {errors?.esign_link_expiry && <label className='w-30 text-nowrap fw-500 mb-sm-2'>*{errors?.esign_link_expiry?.message}</label>}
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className='text-center my-3'>
                        {/* <button className='ui button text-primary-light bg-transparent border-primary fw-400 px-5 mx-1'>Cancel</button> */}
                        <button className='ui button bg-primary-light text-white fw-400 px-5 mx-1' type='submit'   >Save</button>
                    </div>
                </form>
                <WelcomePopup />
            </div>
        </div>
    )
}

export default EsignatureSetting