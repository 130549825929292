import React,{useEffect, useState, useContext} from "react";
import { Tab, Menu, Image } from 'semantic-ui-react';
import GeneralTab from '../components/GeneralTab';
import CreditCheckTab from "../components/CreditCheckTab";
import EsignatureTab from "../components/EsignatureTab";

import { IntegrationService } from '../services/integrationService';
import { useForm } from "react-hook-form"
import { STATUS, EVENT_TYPE, TosterVariant } from "../constant/constant"
import { appContext } from '../context/appContext';

const TenantsProfile = ({onProfile, tenantCreditCheckDetailsProp,tenantEsignDetailsProp, creditcheck, tenantBankIdDetailsProp, esign, bankid }) => {
  const[tenantCreditCheckDetails,setTenantCreditCheckDetails] = useState({})
  const[tenantEsignDetails,setTenantEsignDetails] = useState({})
  
  const[tenantBankIdDetails,setTenantBankIdDetails] = useState({})

   /*context*/
   const { setSpinnerVisibility, showToaster } = useContext(appContext)

   /* service */
   const integrationService = new IntegrationService();

   const integerationAPI = async (payloadData) => {
       try {
           setSpinnerVisibility(true)
           const { data } = await integrationService.integration({
            ...payloadData
           })
           setSpinnerVisibility(false)
           return data
       } catch (error) {
           setSpinnerVisibility(false)
           console.log("error", error);
           return error
       }
   }
   /* Service End */

   /* get data from api */
const getTenantCreditCheckDetails=async()=>{
  const data = await integerationAPI({
    event_type: EVENT_TYPE.getTenantCredidCheckDetails,
    tenant_id: tenantEsignDetailsProp.tenant_id,
  })
  if(data.status === 200 && data?.body?.result.length!==0 ){
  setTenantCreditCheckDetails(data?.body?.result[0])
  }
}

const getTenantEsignDetails=async()=>{
  const data = await integerationAPI({
    event_type: EVENT_TYPE.getTenantEsignDetails,
    tenant_id: tenantCreditCheckDetailsProp.tenant_id,
  })
  if(data.status === 200 && data?.body?.result.length!==0 ){
  setTenantEsignDetails(data.body.result[0])
  }
}
/* get data end */

const propSetter=()=>{
  if(tenantCreditCheckDetailsProp){
    getTenantEsignDetails();
    setTenantCreditCheckDetails(tenantCreditCheckDetailsProp)
  }
  else if(tenantEsignDetailsProp){
    getTenantCreditCheckDetails();
    setTenantEsignDetails(tenantEsignDetailsProp)
  }
  else if(tenantBankIdDetailsProp){
    setTenantBankIdDetails(tenantBankIdDetailsProp)
  }
}
   
useEffect(()=>{
  propSetter()  
    // getTenantEsignDetails();
    // getTenantCreditCheckDetails();

},[])

  let panes = [
    {
      menuItem: (
        <Menu.Item className="border-0" key="General">
          <svg
            className="mr-1"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 30.15 30.34"
          >
            <path
              id="home-svgrepo-com"
              d="M30.167,13.844l-4.513-4.19V2.789h-5.2V4.827L15.258,0,.349,13.844a.52.52,0,1,0,.708.762L2.782,13V30.34H27.734V13l1.725,1.6a.52.52,0,1,0,.707-.762ZM21.5,3.829h3.119v4.86L21.5,5.793ZM12.139,29.3V18.351a.487.487,0,0,1,.487-.487H17.89a.487.487,0,0,1,.487.487V29.3H12.139Zm14.555,0H19.416V18.351a1.528,1.528,0,0,0-1.527-1.527H12.626A1.528,1.528,0,0,0,11.1,18.351V29.3H3.821V12.038L15.258,1.419l7.423,6.893,2.973,2.761h0l1.04.965Z"
              transform="translate(-0.183)"
              fill="#99999e"
            />
          </svg>
          General
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane className="border-0">
          <GeneralTab tenantCreditCheckDetails={Object.keys(tenantCreditCheckDetails).length ? tenantCreditCheckDetails : tenantBankIdDetails } />
        </Tab.Pane>
      ),
    }
  ]

  if(creditcheck){
    panes=[...panes ,{
      menuItem: (
        <Menu.Item className="border-0" key="General">
          <svg
            className="mr-1"
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="27"
            viewBox="0 0 38.8 25.5"
          >
            <g
              id="credit_checkk"
              data-name="credit checkk"
              transform="translate(0 -0.009)"
            >
              <path
                id="Path_15056"
                data-name="Path 15056"
                d="M0,347.192c.1-.733.193-1.467.3-2.2.122-.806.13-.8.914-.6.195.051.387.114.584.152.324.064.382.226.3.543a22.53,22.53,0,0,0-.652,2.825c-.15,1.194-.151,2.407-.213,3.613-.01.193.076.437-.241.451-.594.027-.68.008-.717-.353-.056-.538-.135-1.074-.207-1.61-.018-.134-.048-.268-.072-.4v-2.425Z"
                transform="translate(0 -326.482)"
                fill="#fff"
              />
              <path
                id="Path_15057"
                data-name="Path 15057"
                d="M275.782,25.288a3.134,3.134,0,0,1-2.728-4.618c.963-2.013,1.955-4.012,2.935-6.016L282.853.623c.039-.079.078-.159.114-.239a.514.514,0,0,1,.668-.343c.333.112.4.378.321.706q-1.062,4.587-2.115,9.175-1.459,6.339-2.917,12.678a3.167,3.167,0,0,1-3.142,2.688Zm.158-4.734a1.526,1.526,0,1,0,1.516,1.527A1.536,1.536,0,0,0,275.939,20.555Z"
                transform="translate(-258.614)"
                fill="#fff"
              />
              <path
                id="Path_15058"
                data-name="Path 15058"
                d="M525.509,141.958a1.078,1.078,0,0,1-.258.214c-1.949.79-3.9,1.569-5.852,2.36-.327.133-.4-.071-.473-.3a13.688,13.688,0,0,0-2.066-4.016c-.43-.574-.91-1.112-1.394-1.643-.2-.217-.244-.364-.042-.605q1.64-1.959,3.255-3.938c.2-.245.362-.235.6-.05a21.038,21.038,0,0,1,6.131,7.719c.032.068.056.139.1.257Z"
                transform="translate(-488.666 -126.92)"
                fill="#fff"
              />
              <path
                id="Path_15059"
                data-name="Path 15059"
                d="M591.47,338.148a14.282,14.282,0,0,0,.421-4.029c-.035-.691-.12-1.382-.215-2.068-.045-.325.033-.474.364-.566q3.024-.84,6.037-1.721c.337-.1.49-.036.6.321A23.009,23.009,0,0,1,599.7,337.7c-.009.276-.034.485-.406.484-2.526-.011-5.051-.005-7.577-.007a2.066,2.066,0,0,1-.242-.03Z"
                transform="translate(-560.908 -312.676)"
                fill="#fff"
              />
              <path
                id="Path_15060"
                data-name="Path 15060"
                d="M156.136,62.736c.061,1.11.058,1.012-.655,1.032a14.391,14.391,0,0,0-6.644,1.819c-.316.175-.48.137-.671-.161-.435-.68-.891-1.346-1.357-2-.186-.261-.175-.425.1-.621a18.631,18.631,0,0,1,8.63-3.406c.283-.037.385.074.4.348C156,60.85,156.083,61.958,156.136,62.736Z"
                transform="translate(-139.104 -56.311)"
                fill="#fff"
              />
              <path
                id="Path_15061"
                data-name="Path 15061"
                d="M26.243,157.188a2.088,2.088,0,0,1-.237.252,16.379,16.379,0,0,0-4.875,5.981.331.331,0,0,1-.484.2c-.386-.154-.77-.313-1.164-.444-.308-.1-.374-.257-.266-.567a17.094,17.094,0,0,1,2.361-4.627c.733-.989,1.582-1.892,2.379-2.833.21-.248.39-.253.607.014.477.586.978,1.154,1.467,1.732C26.1,156.975,26.159,157.071,26.243,157.188Z"
                transform="translate(-18.178 -146.944)"
                fill="#fff"
              />
              <path
                id="Path_15062"
                data-name="Path 15062"
                d="M474.112,80.55c.96.489,1.889.952,2.807,1.435.242.127.117.316.009.482q-.986,1.5-1.971,3c-.242.368-.488.734-.722,1.107-.148.237-.3.251-.524.089-.9-.638-.9-.631-.653-1.69q.481-2.059.955-4.118c.016-.07.043-.136.1-.3Z"
                transform="translate(-448.481 -76.38)"
                fill="#fff"
              />
              <path
                id="Path_15063"
                data-name="Path 15063"
                d="M361.1,57.525l-2.227,4.553c-.049.018-.073.036-.094.033-1.089-.181-1.1-.182-1.054-1.3.04-1.032.088-2.064.158-3.1.009-.13.172-.361.257-.358C359.1,57.393,360.063,57.462,361.1,57.525Z"
                transform="translate(-339.225 -54.387)"
                fill="#fff"
              />
            </g>
          </svg>
          Credit Check
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane className="border-0">
          <CreditCheckTab tenantCreditCheckDetails={tenantCreditCheckDetails} setTenantCreditCheckDetails={setTenantCreditCheckDetails}/>
        </Tab.Pane>
      ),
    }]
  }

  if(esign){
    panes=[...panes,
      {
        menuItem: (
          <Menu.Item className="border-0" key="General">
            <svg
              className="mr-1"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 33.544 33.555"
            >
              <g id="penn" transform="translate(0 -0.016)">
                <path
                  id="Path_15064"
                  data-name="Path 15064"
                  d="M0,12.01c.056-.22.1-.442.17-.658a3.345,3.345,0,0,1,3.3-2.358q11.288,0,22.575.007a.469.469,0,0,0,.545-.383c.536-1.683,1.108-3.354,1.644-5.036A1.732,1.732,0,0,1,29.625,2.27a.386.386,0,0,0,.231-.217c.116-.293.2-.6.3-.9a1.627,1.627,0,1,1,3.094,1.009,3.216,3.216,0,0,1-.1.312,1.039,1.039,0,0,0,.109,1.286,1.375,1.375,0,0,1,.133,1.4q-.663,2-1.319,4.007c-.033.1-.062.206-.1.322a3.339,3.339,0,0,1,1.465,2.1,4.653,4.653,0,0,1,.1.941q.008,8.732,0,17.464a3.356,3.356,0,0,1-2.851,3.531.4.4,0,0,0-.087.044H2.949a1.074,1.074,0,0,0-.151-.055A3.314,3.314,0,0,1,.093,30.929C.06,30.8.031,30.681,0,30.556V12.009ZM3.939,30.2H29.6V16.824c-.362.978-.737,1.946-1,2.944a7.233,7.233,0,0,1-1.73,3.1,1.95,1.95,0,0,1-1.84.881c-.066.189-.123.353-.179.517s-.105.356-.173.528a.556.556,0,1,1-1.056-.35c.074-.262.157-.523.254-.777.067-.174.069-.282-.116-.4a1.515,1.515,0,0,1-.707-1.229c-.041-.632-.1-1.263-.115-1.9a2.43,2.43,0,0,1,.115-.8c.716-2.209,1.446-4.413,2.171-6.618.039-.116.064-.238.095-.355H3.939V30.2Zm22.143-20.1H3.507A2.238,2.238,0,0,0,1.13,12.491q0,8.782,0,17.563a2.248,2.248,0,0,0,2.4,2.4H30.04a2.84,2.84,0,0,0,.457-.025,2.232,2.232,0,0,0,1.93-2.319q.007-8.831,0-17.662a2.727,2.727,0,0,0-.054-.553,2.121,2.121,0,0,0-.77-1.27c-.249.751-.5,1.461-.715,2.181a3.04,3.04,0,0,0-.138.862q-.012,8.16-.006,16.318c0,.949-.405,1.354-1.355,1.354H4.156c-.93,0-1.341-.41-1.341-1.335V12.571c0-.952.4-1.349,1.355-1.349H25.306a1.035,1.035,0,0,0,.261-.005.294.294,0,0,0,.187-.127c.117-.314.215-.636.33-.984ZM24.02,20.233l.035.006c.023.381.051.763.068,1.143.045.94.046.944.945,1.214.021.006.041.015.062.02a.565.565,0,0,0,.642-.2c.381-.464.773-.918,1.153-1.383a1.1,1.1,0,0,0,.185-.341q2.613-7.932,5.218-15.867c.142-.433.026-.651-.412-.8-.547-.184-1.1-.362-1.646-.541-.632-.2-.784-.128-.988.493q-2.573,7.808-5.14,15.617a4.776,4.776,0,0,0-.12.635Zm7.845-17.4c.113-.362.235-.7.321-1.045a.455.455,0,0,0-.3-.579.444.444,0,0,0-.6.2c-.161.348-.266.723-.4,1.1Z"
                  transform="translate(0 0)"
                  fill="#6b6b6b"
                />
                <path
                  id="Path_15065"
                  data-name="Path 15065"
                  d="M67.373,182.331c0-.416-.013-.806,0-1.2a2.227,2.227,0,1,1,2.363,2.323c-.4.022-.8,0-1.254,0v3.387a6.417,6.417,0,0,1,3.864,1.826c.077-.1.145-.182.207-.268.53-.735,1.053-1.474,1.59-2.2a1.108,1.108,0,0,1,1.876-.014,2.827,2.827,0,0,0,2.485,1.2c.36-.012.721-.008,1.081,0a.574.574,0,0,1,.624.571.561.561,0,0,1-.616.54,17.386,17.386,0,0,1-1.959-.065,3.579,3.579,0,0,1-2.326-1.342c-.069-.082-.139-.164-.227-.266-.566.79-1.12,1.559-1.671,2.33a1.148,1.148,0,0,1-1.97.2,5.178,5.178,0,0,0-2.958-1.37c0,.373.011.731,0,1.087a2.232,2.232,0,1,1-2.4-2.228c.411-.027.826,0,1.263,0V183.51c-.488-.131-.965-.241-1.428-.392a1.949,1.949,0,0,1-.588-.344.548.548,0,0,1-.1-.739.539.539,0,0,1,.754-.144,2.65,2.65,0,0,0,1.39.44Zm-.015,5.617c-.395,0-.754-.01-1.113,0a1.1,1.1,0,0,0-1.112,1.092,1.11,1.11,0,0,0,2.218.1c.032-.377.006-.76.006-1.2Zm1.142-5.61c.44,0,.844.024,1.245-.005a1.1,1.1,0,0,0,.972-1.128,1.135,1.135,0,0,0-1.067-1.087,1.1,1.1,0,0,0-1.142.918c-.04.419-.008.845-.008,1.3Z"
                  transform="translate(-58.406 -163.311)"
                  fill="#6b6b6b"
                />
                <path
                  id="Path_15066"
                  data-name="Path 15066"
                  d="M333.917,60.9a1.687,1.687,0,0,1-.04.192c-.243.733-.481,1.468-.734,2.2a.538.538,0,0,1-.689.384.529.529,0,0,1-.37-.7c.234-.758.486-1.512.75-2.261a.512.512,0,0,1,.627-.345A.555.555,0,0,1,333.917,60.9Z"
                  transform="translate(-302.981 -55.06)"
                  fill="#6b6b6b"
                />
              </g>
            </svg>
            E-Signature
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane className="border-0">
            <EsignatureTab  tenantEsignDetails={tenantEsignDetails} />
          </Tab.Pane>
        ),
      }]
  }

  return (
    <div className='bg-white shadow border-radius-15'>
      <h5 className='border-bottom py-3 px-3 fs-6 fw-500 text-primary-light'><span className='cursor-pointer' onClick={onProfile}><img className='mr-1' src='/assets/images/arroww.svg' alt='Back' />Back</span></h5>
      <div className='py-3 px-3'>
        <div className='d-flex align-items-center w-100'>
          <div>
            <img className='tenants-page-profile' src={bankid ? (tenantBankIdDetails?.photo_path || '/assets/images/dummy-profile-pic.jpg' ) : tenantCreditCheckDetails?.photo_path || '/assets/images/dummy-profile-pic.jpg'} alt='Tenant' />
          </div>
          <div className='ml-2'>
            <h2 className='fs-6 fw-500 mb-1'>{bankid ? tenantBankIdDetails?.user_name : tenantCreditCheckDetails?.user_name}</h2>
            <p className='d-flex align-items-center mb-1'><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 17.171 25.396">
              <g id="owner" transform="translate(0)">
                <path id="Path_14951" data-name="Path 14951" d="M2015.245-471.76c.335.08.676.14,1,.244a4.894,4.894,0,0,1,3.4,4.584,15.361,15.361,0,0,1-.1,2.544,4.868,4.868,0,0,1-4.767,4.014,4.845,4.845,0,0,1-4.815-3.834,7.9,7.9,0,0,1,.212-4.425,4.773,4.773,0,0,1,3.935-3.084,1.117,1.117,0,0,0,.139-.043Z" transform="translate(-2006.102 471.76)" fill="#a1a5b7" />
                <path id="Path_14952" data-name="Path 14952" d="M1947.166-213.055c.141-.564.268-1.065.393-1.567q1.12-4.53,2.237-9.061c.08-.324.236-.407.545-.276,1.487.629,2.981,1.256,4.466,1.9a1.382,1.382,0,0,1,.884,1.427c-.023,2.9-.009,5.8-.009,8.7,0,.083,0,.166,0,.248a.333.333,0,0,1-.363.352c-.231,0-.462,0-.694,0h-15.551c-.479,0-.554-.075-.554-.564v-8.877a1.439,1.439,0,0,1,1.033-1.514q2.16-.828,4.324-1.65c.394-.15.516-.085.619.321q1.311,5.222,2.624,10.445C1947.123-213.157,1947.131-213.143,1947.166-213.055Z" transform="translate(-1938.52 236.726)" fill="#e3e4ea" />
                <path id="Path_14953" data-name="Path 14953" d="M2078.4-231.343c.231,0,.462-.012.693,0a.86.86,0,0,1,.7,1.321c-.786,1.037-.553,1.257-.462,2.347a5.7,5.7,0,0,0,.095.761,1.46,1.46,0,0,1-.115.888c-.154.383-.291.769-.43,1.16a.508.508,0,0,1-.513.385.5.5,0,0,1-.513-.381c-.179-.5-.365-.991-.54-1.488a.729.729,0,0,1-.032-.29c.052-.625.1-1.251.178-1.874a1.558,1.558,0,0,0-.254-1.082c-.077-.117-.158-.231-.233-.35a.894.894,0,0,1,.709-1.4C2077.924-231.357,2078.165-231.343,2078.4-231.343Z" transform="translate(-2069.732 243.68)" fill="#e3e4ea" />
              </g>
            </svg><span className='ml-1'>{bankid ? (tenantBankIdDetails?.first_name ? tenantBankIdDetails?.first_name  : "" +" "+ tenantBankIdDetails?.last_name ? tenantBankIdDetails?.last_name : "" ): (tenantCreditCheckDetails?.first_name ? tenantCreditCheckDetails?.first_name  : "" +" "+ tenantCreditCheckDetails?.last_name ? tenantCreditCheckDetails?.last_name : "") }</span></p>
            <p className='d-flex align-items-center mb-1'><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 19.427 14.718">
              <g id="XMLID_348_" transform="translate(0 -40)">
                <path id="XMLID_350_" d="M10.236,69.342a.883.883,0,0,1-1.044,0L1.766,63.9,0,62.6v12.5a.883.883,0,0,0,.883.883H18.544a.883.883,0,0,0,.883-.883V62.6l-1.766,1.3Z" transform="translate(0 -21.271)" fill="#e3e4ea" />
                <path id="XMLID_351_" d="M28.453,46.264,36.994,40H19.911Z" transform="translate(-18.739 0)" fill="#a1a5b7" />
              </g>
            </svg><span className='ml-1'>{bankid ? (tenantBankIdDetails?.email ? tenantBankIdDetails?.email : "N/A") : (tenantCreditCheckDetails?.email ? tenantCreditCheckDetails?.email : "N/A")}</span></p>
            <p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 11.978 20.766">
              <g id="phone-svgrepo-com_2_" data-name="phone-svgrepo-com (2)" transform="translate(-116.037)">
                <g id="Group_7279" data-name="Group 7279" transform="translate(116.037)">
                  <circle id="Ellipse_248" data-name="Ellipse 248" cx="0.061" cy="0.061" r="0.061" transform="translate(4.888 0.823)" fill="#a1a5b7" />
                  <rect id="Rectangle_6027" data-name="Rectangle 6027" width="11.307" height="18.092" transform="translate(0 1.33)" fill="#e3e4ea" />
                  <circle id="Ellipse_249" data-name="Ellipse 249" cx="0.061" cy="0.061" r="0.061" transform="translate(5.067 0.823)" fill="#a1a5b7" />
                  <circle id="Ellipse_250" data-name="Ellipse 250" cx="0.061" cy="0.061" r="0.061" transform="translate(5.246 0.823)" fill="#a1a5b7" />
                  <circle id="Ellipse_251" data-name="Ellipse 251" cx="0.061" cy="0.061" r="0.061" transform="translate(5.424 0.823)" fill="#a1a5b7" />
                  <circle id="Ellipse_252" data-name="Ellipse 252" cx="0.061" cy="0.061" r="0.061" transform="translate(5.603 0.823)" fill="#a1a5b7" />
                  <circle id="Ellipse_253" data-name="Ellipse 253" cx="0.061" cy="0.061" r="0.061" transform="translate(5.782 0.823)" fill="#a1a5b7" />
                  <circle id="Ellipse_254" data-name="Ellipse 254" cx="0.061" cy="0.061" r="0.061" transform="translate(5.961 0.823)" fill="#a1a5b7" />
                  <circle id="Ellipse_255" data-name="Ellipse 255" cx="0.061" cy="0.061" r="0.061" transform="translate(6.14 0.823)" fill="#a1a5b7" />
                  <circle id="Ellipse_256" data-name="Ellipse 256" cx="0.061" cy="0.061" r="0.061" transform="translate(6.319 0.823)" fill="#a1a5b7" />
                  <circle id="Ellipse_257" data-name="Ellipse 257" cx="0.061" cy="0.061" r="0.061" transform="translate(6.498 0.823)" fill="#a1a5b7" />
                  <circle id="Ellipse_258" data-name="Ellipse 258" cx="0.061" cy="0.061" r="0.061" transform="translate(6.677 0.823)" fill="#a1a5b7" />
                  <circle id="Ellipse_259" data-name="Ellipse 259" cx="0.061" cy="0.061" r="0.061" transform="translate(6.856 0.823)" fill="#a1a5b7" />
                  <circle id="Ellipse_260" data-name="Ellipse 260" cx="0.061" cy="0.061" r="0.061" transform="translate(7.034 0.823)" fill="#a1a5b7" />
                  <circle id="Ellipse_261" data-name="Ellipse 261" cx="0.061" cy="0.061" r="0.061" transform="translate(7.214 0.823)" fill="#a1a5b7" />
                  <path id="Path_19742" data-name="Path 19742" d="M126.813,0h-9.2a1.347,1.347,0,0,0-1.347,1.347V19.419a1.347,1.347,0,0,0,1.347,1.347h9.2a1.347,1.347,0,0,0,1.347-1.347V1.347A1.346,1.346,0,0,0,126.813,0Zm-.942.8a.149.149,0,1,1-.149.149A.149.149,0,0,1,125.872.8ZM120.618.671h3.326a.212.212,0,1,1,0,.424h-3.326a.212.212,0,1,1,0-.424ZM119.261,19.88h-.327a.04.04,0,0,1,0-.08h.327a.04.04,0,0,1,0,.08Zm0-.16h-.327a.04.04,0,0,1,0-.079h.327a.04.04,0,1,1,0,.079Zm.276.1a.085.085,0,1,1-.17,0v-.281h-.539v.281a.085.085,0,1,1-.17,0v-.366a.085.085,0,0,1,.085-.085h.709a.085.085,0,0,1,.085.085v.366Zm3.741-.026a.337.337,0,0,1-.337.337h-1.473a.337.337,0,0,1-.337-.337V19.5a.337.337,0,0,1,.337-.337h1.473a.337.337,0,0,1,.337.337Zm2.2.128h-.267a.085.085,0,0,1,0-.17h.267a.1.1,0,1,0,0-.2h-.225a.046.046,0,0,1-.038.019s-.013,0-.019,0l-.139-.059a.046.046,0,0,1-.028-.042.045.045,0,0,1,.028-.042l.139-.059a.046.046,0,0,1,.05.01l.005.006h.227a.27.27,0,1,1,0,.54Zm1.786-1.18H117.048V2.077h10.214Z" transform="translate(-116.181)" fill="#a1a5b7" />
                </g>
              </g>
            </svg>
              <span className='ml-1'>{bankid ? (tenantCreditCheckDetails?.mobile ? tenantCreditCheckDetails?.mobile : "N/A") : (tenantCreditCheckDetails?.mobile ? tenantCreditCheckDetails?.mobile : "N/A")}</span></p>
          </div>
        </div>
        <div className='tenant-tabs mt-3'>
          <Tab panes={panes} />
        </div>
      </div>
    </div>
  )
}

export default TenantsProfile