import React from 'react'
import "./Toster.styles.css";
import { Message } from 'semantic-ui-react';
import { useContext } from 'react';
import { appContext } from '../../context/appContext';
import { TosterVariant } from '../../constant/constant';

const Toster = () => {
    const { toasterProps } = useContext(appContext)
    return (
        <div className={`toster ${toasterProps.visibility ? 'show' : 'hide'}`}>
            <Message compact color={toasterProps.type === TosterVariant.success ? "green" : "red"}>
                {/* <p>{toasterProps.type}</p> */}
                <p>{toasterProps.message}</p>
            </Message>
        </div>
    )
}

export default Toster
