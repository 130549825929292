import React, { useState,useContext } from "react";
import { Image, Modal } from "semantic-ui-react";
import { PHOTO_URl_BUSINESS_USER, PHOTO_URl_PERSONAL_USER } from "../constant/constant";
import { IntegrationService } from '../services/integrationService';
import { useForm } from "react-hook-form"
import { STATUS, EVENT_TYPE, TosterVariant } from "../constant/constant"
import { appContext } from '../context/appContext';
import moment from "moment"
import SpeedMeeter from "./speedMeeter";


const CreditCheckTab = ({ tenantCreditCheckDetails, setTenantCreditCheckDetails }) => {
  const updateStatusHandler = () => {
    setShowStatus(true)
    SetCreditCheckModal({ open: false })
  }
  const [showStatus, setShowStatus] = useState(false);
  const [creditStatus, SetCreditStatus] = useState(false);
  const [creditCheckModal, SetCreditCheckModal] = useState({
    open: false,
    dimmer: undefined,
  });
  const [creditCheckSvgShow,SetCreditCheckSvgShow]= useState(false) 
  const [failedStatus,setFailedStatus]=useState(false)
  const [failedStatusResponce,setFailedStatusResponce]=useState("")

  /*context*/
  const { setSpinnerVisibility, showToaster,userDetails } = useContext(appContext)

  /* service */
  const integrationService = new IntegrationService();

  const integerationAPI = async (payloadData) => {
    try {
      setSpinnerVisibility(true);
      SetCreditCheckSvgShow(true)
      const { data } = await integrationService.creditCheck({
        ...payloadData
      })
      SetCreditCheckSvgShow(false)
      setSpinnerVisibility(false)
      return data
    } catch (error) {
      setSpinnerVisibility(false)
      console.log("error", error);
      return error
    }
  }
  /* Service End */
  
  const proceedCreditCheck = async(e) => {
    e.preventDefault();
    SetCreditStatus(true);
    let additionalDetails={}
    if(tenantCreditCheckDetails?.business_user){
      additionalDetails.tenant_type="BUSINESS"
      additionalDetails.organization_number= tenantCreditCheckDetails?.ssn

    }
    else{
      additionalDetails.tenant_type="PERSON"
      additionalDetails.identity_number = tenantCreditCheckDetails?.ssn
    }
    const process_credit_check = await integerationAPI({
      event_type: EVENT_TYPE.creditCheckEnquiry,
      country_code: "NOR",
      integrated_with: "signicat",
      tenant_id: tenantCreditCheckDetails.tenant_id ,
      request_from:"MANAGEMENT_SOFTWARE",
      ...additionalDetails
    })
    if(process_credit_check?.body && (process_credit_check?.status===201 || process_credit_check?.status=== 200 ) ){
      setFailedStatus(true)
      setTenantCreditCheckDetails({...tenantCreditCheckDetails,credit_check_details:{...process_credit_check?.body,modified_on:new Date(),request_from:"MANAGEMENT_SOFTWARE"} })
    }
    else{
      setFailedStatusResponce(process_credit_check?.description)
    }

  };

  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="row py-1">
            <div className="col-lg-6 col-md-3 col-sm-6 mb-2">
              <p className="fs-7 fw-500">Credit Score</p>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-6 col-sm-6 mb-2">
              <p className="fs-7 d-flex">
                <span className="fs-7 fw-500 mr-3">:</span>
                 <p>{tenantCreditCheckDetails?.credit_check_details?.credit_score ? tenantCreditCheckDetails?.credit_check_details?.credit_score : 'N/A'}</p> 

                {/* {!showStatus && <p>{tenantCreditCheckDetails?.credit_check_details?.credit_score}</p>}
                {showStatus && <p>Verified <img src='assets/images/tick-circle-icon.svg' alt="tick-circle-icon" /></p>} */}
              </p>
            </div>
            <div className="col-lg-6 col-md-3 col-sm-6 mb-2">
              <p className="fs-7 fw-500">Credit score verified during </p>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-6 mb-2">
              <p className="fs-7 d-flex">
                <span className="fs-7 fw-500 mr-3">:</span>
                <p>{tenantCreditCheckDetails?.credit_check_details?.request_from  ? (tenantCreditCheckDetails?.credit_check_details?.request_from === "BOOKING_PORTAL" ? "Booking Portal" : tenantCreditCheckDetails?.credit_check_details?.request_from === "MANAGEMENT_SOFTWARE" ? "Management Software " : tenantCreditCheckDetails?.credit_check_details?.request_from  ) : "N/A"}</p>

                {/* {!showStatus && <p>NA</p>} */}
                {/* {showStatus && <p>Online Move-In</p>} */}
              </p>
            </div>
            <div className="col-lg-6 col-md-3 col-sm-6 mb-2">
              <p className="fs-7 fw-500">Credit score verified On </p>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-6 mb-2">
              <p className="fs-7 d-flex">
                <span className="fs-7 fw-500 mr-3">:</span>
              {tenantCreditCheckDetails?.credit_check_details?.modified_on ? <p>{moment(tenantCreditCheckDetails?.credit_check_details?.modified_on).format("DD-MM-YYYY hh:mm")}</p> : <p>N/A</p> }
                
                {/* {!showStatus && <p>{tenantCreditCheckDetails?.credit_check_details?.created_on}</p>} */}
                {/* {showStatus && <p>12-12-2022</p>} */}
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="CreditCheckTabimg text-center">
            {/* {tenantCreditCheckDetails?.business_user ==="Business User" ? <img src={PHOTO_URl_BUSINESS_USER[tenantCreditCheckDetails?.credit_check_details?.credit_score]} alt="No Status" /> : <img src={PHOTO_URl_PERSONAL_USER[tenantCreditCheckDetails?.credit_check_details?.credit_score]} alt="No Status" />} */}
           <SpeedMeeter value={tenantCreditCheckDetails?.credit_check_details?.credit_score} user={tenantCreditCheckDetails?.business_user ? "Business" : "Personal" }/>
            {/* {!showStatus && (tenantCreditCheckDetails?.credit_check_details ?
              (tenantCreditCheckDetails?.business_user ? <img src={PHOTO_URl_BUSINESS_USER[tenantCreditCheckDetails?.credit_check_details?.credit_score]} /> : <img src={PHOTO_URl_PERSONAL_USER(tenantCreditCheckDetails?.credit_check_details?.credit_score)} alt="No Status" />) :
              <img className="aaaStatus" src="/assets/images/NR.png" alt="AAA" />
            )} */}
            {/* {showStatus && (tenantCreditCheckDetails?.credit_check_details ?
              (tenantCreditCheckDetails?.business_user ? <img src={PHOTO_URl_BUSINESS_USER[tenantCreditCheckDetails?.credit_check_details?.credit_score]} /> : <img src={PHOTO_URl_PERSONAL_USER(tenantCreditCheckDetails?.credit_check_details?.credit_score)} alt="No Status" />) :
              <img className="aaaStatus" src="/assets/images/NR.png" alt="AAA" />
            )} */}
            {/* {!showStatus &&  */}
            <button
              onClick={() =>
                SetCreditCheckModal({ open: true, dimmer: "blurring" })
              }
              className="ui button bg-primary-light d-block mx-auto mt-2 fs-7 fw-400 text-white"
            >
              Initiate the credit check
            </button>
            {/* } */}
          </div>
        </div>
      </div>

      <Modal
        dimmer={creditCheckModal.dimmer}
        open={creditCheckModal.open}
        size="tiny"
        onClose={() => SetCreditCheckModal({ open: false })}
      >
        <Modal.Header className="header text-success-dark text-center fw-601 fs-5 border-0 pb-1">
          CREDIT CHECK
        </Modal.Header>
        <Modal.Content className="mh-400 overflow-y-auto text-center pt-2">
          <div className="d-flex justify-content-center mb-2">
            <SpeedMeeter value={tenantCreditCheckDetails?.credit_check_details?.credit_score} user={tenantCreditCheckDetails?.business_user ? "Business" : "Personal"} />
          {/* {!creditCheckSvgShow ? (!showStatus && (tenantCreditCheckDetails?.credit_check_details ?
              (tenantCreditCheckDetails?.business_user ? <img width={250} src={PHOTO_URl_BUSINESS_USER[tenantCreditCheckDetails?.credit_check_details?.credit_score]} /> : <img width={250} src={PHOTO_URl_PERSONAL_USER(tenantCreditCheckDetails?.credit_check_details?.credit_score)} alt="No Status" />) :
              <img width={250} className="aaaStatus" src="/assets/images/NR.png" alt="AAA" />
            )) : <img width={250} src="/assets/images/credit-check_meter.svg" /> } */}
          </div>
          {!creditStatus && (
            <>
              <div className="mb-3">
                Please tie your shoes while we check your credit score. Please
                do not perform any actions until we prompt
              </div>
              <div className="">
                <button
                  onClick={(e) => proceedCreditCheck(e)}
                  className="ui button bg-primary-light fs-7 fw-400 text-white px-5"
                >
                  Proceed
                </button>
              </div>
            </>
          )}
          {creditStatus && (
            <>
              {!creditCheckSvgShow && <div className="mb-3">
                {failedStatus ? "We have successfully checked the customers credit score": failedStatusResponce ? failedStatusResponce :  "Failed to check credit score"}
              </div>}
              <div>
                <button
                  onClick={updateStatusHandler}
                  className="ui button bg-primary-light fs-7 fw-400 text-white px-5"
                >
                  Continue
                </button>
              </div>
            </>
          )}
        </Modal.Content>
      </Modal>
    </>
  );
};

export default CreditCheckTab;
