import { createContext } from "react";

const appState = {
    spinnerVisibility: false,
    setSpinnerVisibility: (visibility) => { },
    toasterProps: { type: "success", message: "Success Message", visibility: false },
    showToaster: (type, message) => { },
    userDetails: {},
    setUserDetails: (user)=>{ },
    settingDetails: {},
    setSettingDetails: (details)=>{ },
    hideHeaders: false,
    setHideHeaders: (visibility)=>{ },
    checkedValue: [],
    setCheckedValue : (checked)=>{ }
}

export const appContext = createContext(appState);
