import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {takeLastFiveMonth} from "../constant/constant"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);




export function BarChart({dashboardData,isCreditCheck,isEsign}) {

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };
  let monthByMonth=takeLastFiveMonth()

  let dataSetInitiated=[]
  let dataSetSuccess=[]
  let dataSetFailed=[]

  for (let i=0;i<5;i++){
    dataSetInitiated.push((dashboardData["initated"]?.find((data)=>data.month===monthByMonth[i]))?.count)
    dataSetSuccess.push((dashboardData["success"]?.find((data)=>data.month===monthByMonth[i]))?.count)
    dataSetFailed.push((dashboardData["failed"]?.find((data)=>data.month===monthByMonth[i]))?.count)
  }
  const data = {
    labels: takeLastFiveMonth(),

    datasets: [
      {
        label: 'Success',
        data: dataSetSuccess,
        backgroundColor: '#2396C1',
      },
      {
        label: 'Failed',
        data: dataSetFailed,
        backgroundColor: '#FE738B',
      },
      {
        label: isCreditCheck ? 'Skipped events' :isEsign ? 'Expire events' : 'Initiated events',
        data: dataSetInitiated,
        backgroundColor: '#888B8A',
      },
    ],
  };
  
  return <Bar options={options} data={data} />;
}
