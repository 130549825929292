import React from 'react';
import { useParams } from 'react-router-dom';
import { Loader, Segment, Modal } from 'semantic-ui-react';
import { userManager } from './Auth';

const Login = () => {

    userManager.signinRedirect();

    return (
        <div className='loader-screan'>
            <Modal basic open={true} dimmer={'inverted'} centered >
                <Modal.Content>
                <Loader active>Loading...</Loader>
                </Modal.Content>
            </Modal>
            {/* <Segment>
                <Loader active>Loading...</Loader>
            </Segment> */}
        </div>
    );
}

export default Login;
