import React, { useContext, useEffect, useState } from 'react'
import { Modal, Dropdown } from 'semantic-ui-react'
import { IntegrationService } from '../services/integrationService';
import { set, useForm } from "react-hook-form"
import { STATUS, EVENT_TYPE, TosterVariant, onKeyDown } from "../constant/constant"
import { appContext } from '../context/appContext';


export default function Integration({setBankIdEnable,setCreditCheckEnable,setEsignEnable,setshowCreditCheckdashboard,setshowEsigndashboard,setshowBankIddashboard,creditCheckEnable,bankIdEnable,esignEnable,onEsign,onBankId,onCreditCheck}) {
  const [integrationformShow, SetintegrationformShow] = useState(false);
  const [SignicatTableShow, SetSignicatTableShow] = useState(false);
  const [configurationConfigured, SetConfigurationConfigured] = useState(false)
  const [deactivateModal, SetDeactivateModal] = useState({
    open: false,
    dimmer: undefined,
    size: undefined
  })
  const [dropdownValue, setDropDownValue] = useState("")
  const [editMode, setEditMode] = useState(false)
  const [digitalSolutionData, setDigitalSolutiondata] = useState()
  const [data,setData]=useState("")
  const [deactivtionErrorAlert,setDeactivationErrorAlert]=useState(false)
  const productsOption = [
    {
      key: 2,
      text: "Credit Check",
      value: "Credit Check",
      name: "enable_credit_check"
    },
    {
      key: 3,
      text: "E-Signature",
      value: "E-Signature",
      name: "enable_esign"
    },
    {
      key: 4,
      text: "Bankid Authentication",
      value: "Bankid Authentication",
      name: "enable_bank_id_verification"
    }
  ];
  const { register, setValue, handleSubmit, watch, reset, formState: { errors } } = useForm({
    defaultValues: {
      third_party_client_id: "",
      third_party_client_secret: "",
      third_party_account_id: "",
      third_party_domain: "",
    }
  })
  const [deactivationData, setDeactivationData] = useState("")

  /*context*/
  const { setSpinnerVisibility, showToaster, userDetails,setHideHeaders } = useContext(appContext)


  /* Services */
  const integrationService = new IntegrationService();

  const integerationAPI = async (payloadData) => {
    try {
      setSpinnerVisibility(true)
      const { data } = await integrationService.integration({
        ...payloadData
      })
      setSpinnerVisibility(false)
      return data
    } catch (error) {
      setSpinnerVisibility(false)
      console.log("error", error);
      return error
    }
  }
  /* Service End */



  const enableDigitalSolution = async (enable) => {
    const EDSResponse = await integerationAPI({
      event_type: EVENT_TYPE.enableDigitalSolutionsApplication,
      enable: enable

    })
    return EDSResponse;
  }

  const getTenantDetailsAndStore = async()=>{
    const getTenantDetailsAndStoreResponce = await integerationAPI({
      event_type:EVENT_TYPE.getTenantDetailsAndStore,
      access_token:userDetails?.access_token,
      page_number:"1",
      page_count:"250"
    })
    if(getTenantDetailsAndStoreResponce?.status===200){
      const totalCount=Math.floor((Number(getTenantDetailsAndStoreResponce?.body?.total_count))/250)
            for(let i=1;i<=totalCount;i+=1){
              await integerationAPI({
                event_type:EVENT_TYPE.getTenantDetailsAndStore,
                access_token:userDetails?.access_token,
                page_count:"250",
                page_number:`${i+1}`
             })
          }

          return "success"
    }
    await enableDigitalSolution(false)
    return "failed"
  }

  /* onsubmit handler */
  const onSubmit = async (data) => {
    try {
      let createClientIntigrationPayload = {
        event_type: EVENT_TYPE.createClientIntegration,
        integrated_with: "signicat",
        country_code: "NOR",
      }
      let enableClientFetures = {
        event_type: EVENT_TYPE.enableClientFetures,
        country_code: "NOR",
        integrated_with: "signicat",
      }
      if (dropdownValue === "Credit Check") {
        enableClientFetures = {
          ...enableClientFetures,
          enable_credit_check: true
        }
        createClientIntigrationPayload = {
          ...createClientIntigrationPayload,
          third_party_client_id_for_creditcheck: data?.third_party_client_id,
          third_party_client_secret_for_creditcheck: data?.third_party_client_secret
        }
      }
      else if (dropdownValue === "E-Signature") {
        enableClientFetures = {
          ...enableClientFetures,
          enable_esign: true
        }
        createClientIntigrationPayload = {
          ...createClientIntigrationPayload,
          third_party_client_id_for_esign: data?.third_party_client_id,
          third_party_client_secret_for_esign: data?.third_party_client_secret
        }
      }
      else if (dropdownValue === "Bankid Authentication") {
        enableClientFetures = {
          ...enableClientFetures,
          enable_bank_id_verification: false
        }
        createClientIntigrationPayload = {
          ...createClientIntigrationPayload,
          third_party_client_id: data?.third_party_client_id,
          third_party_client_secret: data?.third_party_client_secret,
          third_party_account_id: data?.third_party_account_id,
          third_party_domain: data?.third_party_domain
        }
      }
      let success = false
      if (!configurationConfigured) {
        const data = await enableDigitalSolution(true)
        if (data?.status === STATUS.success) {
        const data= await getTenantDetailsAndStore()
        success=(data==="success")
        }
        else {
          showToaster(TosterVariant.failed, "Signicat integration failed")
        }  
      }
      if (configurationConfigured || success) {
        const EDSresponse = await integerationAPI({
          ...createClientIntigrationPayload,
        })
        if (EDSresponse?.status === STATUS.success ) {
          const CCIresponse = await integerationAPI({
            ...enableClientFetures
          })
          if (CCIresponse?.status === STATUS.success || dropdownValue === "Bankid Authentication") {
            if(dropdownValue==="Credit Check"){
              setCreditCheckEnable(true)
              onCreditCheck(true)
            }
            if(dropdownValue==="Bankid Authentication"){
              setBankIdEnable(true)
              if (creditCheckEnable) {
                onCreditCheck(true)
              } else  {
                onBankId(true)
              }
            }
            if(dropdownValue==="E-Signature"){
              setEsignEnable(true)
              if (creditCheckEnable) {
                onCreditCheck(true)
              } else  {
                onEsign(true)
              }
            }
            SetConfigurationConfigured(true);
            SetintegrationformShow(false)
            localStorage.setItem('integration', true)
            showToaster(TosterVariant.success, "Great! you've successfully configured with Signicat.")
          }
          else {
            showToaster(TosterVariant.failed, "Signicat integration failed")
          }
        }
        else {
          showToaster(TosterVariant.failed, "Signicat integration failed")
        }
        
      }


    } catch (error) {
      console.log(error);
      showToaster(TosterVariant.failed, "Signicat integration failed")
    }
  }
  /* submit end */

  /* update data  api calls */
  const handleEdit = (e, data) => {
    e.preventDefault();
    if (data === "BankID Authentication") {
      setValue("third_party_client_id", digitalSolutionData?.third_party_client_id);
      setValue("third_party_client_secret", digitalSolutionData?.third_party_client_secret);
      setValue("third_party_domain", digitalSolutionData.third_party_domain)
      setValue("third_party_account_id", digitalSolutionData.third_party_account_id)
      setDropDownValue("Bankid Authentication")
    }
    else if (data === "Credit Check") {
      setValue("third_party_client_id", digitalSolutionData?.third_party_client_id_for_creditcheck);
      setValue("third_party_client_secret", digitalSolutionData?.third_party_client_secret_for_creditcheck);
      setDropDownValue("Credit Check")
    }
    else if (data === "E-Signature") {
      setValue("third_party_client_id", digitalSolutionData?.third_party_client_id_for_esign);
      setValue("third_party_client_secret", digitalSolutionData?.third_party_client_secret_for_esign);
      setDropDownValue("E-Signature")
    }
    SetSignicatTableShow(false);
    setEditMode(true);

  }

  const DeactivateSignicatHandler = async () => {
    try {
      if(!data.length || (data.trim()).length < 10){
        setDeactivationErrorAlert(true)
        return
      }
      let deactivate = {
        enable_bank_id_verification: (digitalSolutionData?.third_party_client_id?.trim()?.length !==0 && digitalSolutionData?.third_party_client_secret?.trim()?.length !== 0),
        enable_credit_check: digitalSolutionData?.enable_credit_check,
        enable_esign: digitalSolutionData?.enable_esign
      }
      SetDeactivateModal({ open: false })

      const ecsPayload = {
        event_type: EVENT_TYPE.enableClientFetures,
        country_code: "NOR",
        integrated_with: "signicat",
      }
      let dynamicData = {}
      if (deactivationData === "enable_bank_id_verification") {
        dynamicData = { enable_bank_id_verification: false }
        deactivate = { ...deactivate, enable_bank_id_verification: false }
        setshowBankIddashboard(false);
        if (creditCheckEnable) {
          onCreditCheck()
        } else if (esignEnable) {
          onEsign()
        }

      }
      else if (deactivationData === "enable_esign") {
        deactivate = { ...deactivate, enable_esign: false }
        dynamicData = { enable_esign: false }
        setshowEsigndashboard(false)
        if (creditCheckEnable) {
          onCreditCheck()
        } else if (bankIdEnable) {
          onBankId()
        }
      }
      else if (deactivationData === "enable_credit_check") {
        deactivate = { ...deactivate, enable_credit_check: false }
        dynamicData = { enable_credit_check: false }
        setshowCreditCheckdashboard(false)
        if (bankIdEnable) {
          onBankId()
        } else if (esignEnable) {
          onEsign()
        }
      }

      const ECFresponse = await integerationAPI({ ...ecsPayload, ...dynamicData })
      if (ECFresponse.status === STATUS.success) {
        if (!deactivate?.enable_esign && !deactivate?.enable_bank_id_verification && !deactivate?.enable_credit_check) {

          await enableDigitalSolution(false)
          SetConfigurationConfigured(false);
          localStorage.clear()

        }
        if (deactivationData === "enable_bank_id_verification") {
          await integerationAPI({
            event_type: EVENT_TYPE.createClientIntegration,
            integrated_with: "signicat",
            country_code: "NOR",
            third_party_client_id: ' ',
            third_party_client_secret: ' ',
            third_party_account_id: ' ',
            third_party_domain: ' '
          })
          setBankIdEnable(false)
        }
        if (deactivationData === "enable_credit_check") {
          setCreditCheckEnable(false)
          
        }
        if (deactivationData === "enable_esign") {
          setEsignEnable(false)
        }

        SetintegrationformShow(false)
        showToaster(TosterVariant.success, "The Signicat configuration is deactivated.")
      }

    } catch (error) {
      console.log("err", error);
      showToaster(TosterVariant.failed, "Signicat integration failed")
    }

  }
  /* update api call ends */

  /*Get data apis  */

  const getClientIntegration = async () => {
    try {
      const payload = {
        event_type: EVENT_TYPE.getClientIntegration,
        country_code: "NOR",
        integrated_with: "signicat"
      }
      const data = await integerationAPI(payload)
      if (data?.status === STATUS.success) {
        setDigitalSolutiondata(data?.body)
        if(!(data?.body?.enable_esign ) && !(data?.body?.enable_credit_check)  && !((data?.body?.third_party_client_id?.trim()?.length !== 0 && data?.body?.third_party_client_secret?.trim()?.length !== 0)) && configurationConfigured){
          await enableDigitalSolution(false)
          SetConfigurationConfigured(false);
          localStorage.clear()
        }
      }
    } catch (error) {
      showToaster(TosterVariant.failed, "fetch ClientIntegration details failed")
      console.log(error);
    }
  }

  const getDigitalSolutionStatus = async () => {
    try {
      const payload = {
        event_type: EVENT_TYPE.getDigitalSolutionApplicationStatus,
      }
      const getStatus = await integerationAPI(payload)
      if (getStatus.status === STATUS.success) {
        if (getStatus?.body?.is_credit_check_app_enabled) {
          SetConfigurationConfigured(true);
          localStorage.setItem('integration', true)
        }
        else{
          SetConfigurationConfigured(false);
          localStorage.removeItem('integration')
        }
      }
    } catch (error) {
      showToaster(TosterVariant.failed, "Fetch Digital Solution Status failed")
    }
  }
  /* get data api end */

  /* for configuration the data */
  const cancelConfiguration = (e) => {
    e.preventDefault();
    if (configurationConfigured) {
      SetSignicatTableShow(true);
    } else {
      SetintegrationformShow(false);
    }
  }


  const configurationHandler = (e) => {
    e.preventDefault()
    if (!configurationConfigured) {
      SetintegrationformShow(true)
      SetSignicatTableShow(false)
    } else {
      SetintegrationformShow(true)
      SetSignicatTableShow(true)
      getClientIntegration()
    }
    setDropDownValue('')
    setEditMode(false)
    reset()
  }

  const newConfigurationHandler = (e) => {
    e.preventDefault()
    setEditMode(false);
    reset()
    SetSignicatTableShow(false);
    SetintegrationformShow(true)
  }

  const handleDropdown = (e, data) => {
    console.log(e,data);
    setDropDownValue(data.value)
  }

  /* end */


  useEffect(() => {
    getDigitalSolutionStatus();
  }, [])



  return (
    <div className='py-2'>
      <div className='mx-2'>
        <h2 className='text-white fs-6 mb-4 fw-300 d-flex align-items-center'>Integration <span className='fs-8 PageSub-title px-1 ml-1'><img height='17' width='17' src='/assets/images/Integration.svg' alt='Filter' /><span className='veritical-align-text-top pl-1'>Configuration</span></span></h2>
        {!integrationformShow &&
          <div className='bg-white border-radius-10 py-5 px-2 shadow'>
            <div className='row px-6 px-sm-1'>
              <div className='col-md-4 col-sm-12 px-2 my-2'>
                <div className='card cursor-pointer shadow-sm border-light-gray p-2' onClick={(e) => configurationHandler(e)}>
                  <div className='text-center'>
                    <img height='60' src='/assets/images/Signicat-logo.svg' alt='Signicat' className='mt-3' />
                    <p className='my-2 fw-600'>Signicat</p>
                    <div className={`fw-500 ${configurationConfigured ? 'text-green bg-light-green' : 'bg-skyblue-light text-skyblue'} py-1`}>{configurationConfigured ? (<span>Configured <img src='assets/images/tick-circle-icon.svg' alt="tick-circle-icon" /></span>) : (<span>Configure</span>)}</div>
                  </div>
                </div>
              </div>
              <div className='col-md-4 col-sm-12 px-2 my-2'>
                <div className='card shadow-sm border-light-gray p-2'>
                  <div className='text-center'>
                    <img height='60' src='/assets/images/credit-safe.svg' alt="credit-safe" className='mt-3 p-2 w-100' />
                    <p className='my-2 fw-600'>Signicat</p>
                    <div className='text-green bg-light-green py-1 fw-500'>Coming Soon</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {(integrationformShow && !SignicatTableShow)
          &&
          <div className='bg-white border-radius-10 py-3  shadow'>
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={onKeyDown}>
              <div className='border-bottom d-flex justify-content-between pb-2 px-3'>
                <h5 className='fs-6 fw-600 text-primary-light'>SIGNICAT CONFIGURATION</h5>
                <button onClick={() => SetintegrationformShow(false)} className='border-0 bg-transparent cursor-pointer text-primary-light fs-6 fw-600 text-nowrap' ><img src='/assets/images/arroww.svg' alt='Arrow' className='mr-1' width='25' />BACK</button>
              </div>
              <div className='ui form p-3'>
              <div className='row align-items-center'>
                  <div className='col-lg-2 col-md-2 col-sm-12 my-2 my-sm-0'>
                    <label className='text-nowrap fw-500 mb-sm-2'>Products</label>
                  </div>
                  <div className='col-lg-5 col-md-5 col-sm-12 my-2'>
                    <div className='position-relative'>
                    <Dropdown
                        placeholder="-Select Product-" mini clearable fluid floating selection
                        options={editMode ? productsOption?.filter((data) => data.value === dropdownValue) : configurationConfigured ? productsOption?.filter((data) => {
                          if(data.name!=='enable_bank_id_verification'){
                            return !digitalSolutionData[data.name]
                          }else{
                            return digitalSolutionData?.third_party_client_id?.trim()?.length === 0 && digitalSolutionData?.third_party_client_secret?.trim()?.length === 0                          }
                         }) : productsOption}
                        name={"location"}
                        value={dropdownValue}
                        disabled={editMode}
                        forceSelection={false} 
                        selectOnBlur={false} 
                        onChange={handleDropdown} />
                    </div>
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className='col-lg-2 col-md-2 col-sm-12 my-2 my-sm-0'>
                    <label className='text-nowrap fw-500 mb-sm-2'>Access Token</label>
                  </div>
                  <div className='col-lg-5 col-md-5 col-sm-12 my-2'>
                    <div className='position-relative'>
                      <input type="text" name='third_party_client_id' value={watch("third_party_client_id")} {...register('third_party_client_id', { required: "Access Token is required" })} />
                      <div className='error'>
                        {errors.third_party_client_id && <label className='w-30 text-nowrap fw-500 mb-sm-2'>*{errors?.third_party_client_id?.message}</label>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className='col-lg-2 col-md-2 col-sm-12 my-2 my-sm-0'>
                    <label className='text-nowrap fw-500 mb-sm-2'>Secret Key</label>
                  </div>
                  <div className='col-lg-5 col-md-5 col-sm-12 my-2'>
                    <div className='position-relative'>
                    <input type="text" name='third_party_client_secret' value={watch("third_party_client_secret")} {...register('third_party_client_secret', { required: "Secret key is required" })} />
                      <div className='error'>
                        {errors.third_party_client_secret && <label className='w-30 text-nowrap fw-500 mb-sm-2'>*{errors.third_party_client_secret.message}</label>}
                      </div>
                    </div>
                  </div>
                </div>
                {dropdownValue === "Bankid Authentication" && <>
                <div className='row align-items-center'>
                  <div className='col-lg-2 col-md-2 col-sm-12 my-2 my-sm-0'>
                    <label className='text-nowrap fw-500 mb-sm-2'>Account Id</label>
                  </div>
                  <div className='col-lg-5 col-md-5 col-sm-12 my-2'>
                    <div className='position-relative'>
                    <input type="text" name='third_party_account_id' value={watch("third_party_account_id")} {...register('third_party_account_id', { required: "Account id is required" })} />
                        <div className='error'>
                          {errors.third_party_account_id && <label className='w-30 text-nowrap fw-500 mb-sm-2'>*{errors?.third_party_account_id?.message}</label>}
                        </div>
                    </div>
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className='col-lg-2 col-md-2 col-sm-12 my-2 my-sm-0'>
                    <label className='text-nowrap fw-500 mb-sm-2'>Domain Url</label>
                  </div>
                  <div className='col-lg-5 col-md-5 col-sm-12 my-2'>
                    <div className='position-relative'>
                    <input type="text" name='third_party_domain' value={watch("third_party_domain")} {...register('third_party_domain', { required: "Domain Url is required" })} />
                        <div className='error'>
                          {errors.third_party_domain && <label className='w-30 text-nowrap fw-500 mb-sm-2'>*{errors?.third_party_domain?.message}</label>}
                        </div>
                    </div>
                  </div>
                </div>
                </>}
              </div>
              <div className='text-center'>
               {!editMode && <button type='reset'  onClick={()=>{ reset()}} className='ui button bg-gray-2 text-white fw-500 px-4 mr-2'>Cancel</button>}
                <button disabled={dropdownValue===''}  className='ui button bg-primary-light text-white fw-500 px-5' type='submit' >Save</button>
              </div>
            </form>
          </div>
        }
        {(integrationformShow && SignicatTableShow)
          && <div className='bg-white border-radius-10 py-3  shadow'>
            <div className='border-bottom d-flex justify-content-between pb-2 px-3'>
              <h5 className='fs-6 fw-600 text-primary-light'>SIGNICAT</h5>
              <button onClick={() => SetintegrationformShow(false)} className='border-0 bg-transparent cursor-pointer text-primary-light fs-6 fw-600 ' ><img src='/assets/images/arroww.svg' className='mr-1' alt='arrow' width='25' />BACK</button>
            </div>
            <div className='row justify-content-center my-5'>
              <div className='col-md-12 col-sm-12 px-2'>
                <div className='overflow-y-auto'>
                  <table className='ui unstackable table text-center border-light-gray shadow-sm' id='SignicatINtegrationTable'>
                    <thead>
                      <tr>
                        <th className='border-bottom'>INTEGRATION</th>
                        <th className='border-bottom'>SERVICES</th>
                        <th className='border-bottom'>ACCESS TOKEN</th>
                        <th className='border-bottom'>SECRET KEY</th>
                        <th className='border-bottom'>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {( ((digitalSolutionData?.third_party_client_id?.trim()?.length !== 0 && digitalSolutionData?.third_party_client_secret?.trim()?.length !== 0))) && <tr>
                        <td>
                          <img height='50' src='/assets/images/Signicat-logo.svg' alt='Signicat' className='' />
                        </td>
                        <td className='veritical-align-middle'>
                          <div className='d-flex align-items-center justify-content-center'>
                            <img width='15' height='15' src='/assets/images/bank-id-2.svg' alt='Bank Id' />
                            <p className='ml-1'>BankID Authentication</p>
                          </div>
                        </td>
                        <td className='veritical-align-middle text-break'>
                          {digitalSolutionData?.third_party_client_id}
                        </td>
                        <td className='veritical-align-middle text-break'>
                          {digitalSolutionData?.third_party_client_secret}
                        </td>
                        <td className='veritical-align-middle'>
                          <div className='flexcolumn justify-content-center'>
                          <button className='cursor-pointer border-0 bg-transparent p-0 mx-1' onClick={(e) => handleEdit(e, "BankID Authentication")}>
                            <img width='15' height='15' src='/assets/images/edit.svg' alt='Edit' />
                          </button>
                          <button className='cursor-pointer border-0 bg-transparent p-0 mx-1' onClick={() => {setData(''); setDeactivationErrorAlert(false); setDeactivationData("enable_bank_id_verification"); SetDeactivateModal({ open: true, size: 'small', dimmer: 'blurring' }) }}><img width='15' height='15' src='/assets/images/deactivate.svg' alt='Deactivate' /></button>
                          </div>
                        </td>
                      </tr>}
                      {digitalSolutionData?.enable_credit_check && <tr>
                        <td>
                          <img height='50' src='/assets/images/Signicat-logo.svg' alt='Signicat' className='' />
                        </td>
                        <td className='veritical-align-middle'>
                          <div className='d-flex align-items-center justify-content-center'>
                            <img width='15' height='15' src='/assets/images/credit-check.svg' alt='Bank Id' />
                            <p className='ml-1'>Credit Check</p>
                          </div>
                        </td>
                        <td className='veritical-align-middle text-break'>
                          {digitalSolutionData?.third_party_client_id_for_creditcheck}
                        </td>
                        <td className='veritical-align-middle text-break'>
                          {digitalSolutionData?.third_party_client_secret_for_creditcheck}
                        </td>
                        <td className='veritical-align-middle'>
                          <div className='flexcolumn justify-content-center'>
                          <button className='cursor-pointer border-0 bg-transparent p-0 mx-1' onClick={(e) => handleEdit(e, "Credit Check")}>
                            <img width='15' height='15' src='/assets/images/edit.svg' alt='Edit' />
                          </button>
                          <button className='cursor-pointer border-0 bg-transparent p-0 mx-1' onClick={() => { setData('') ;setDeactivationErrorAlert(false); setDeactivationData("enable_credit_check"); SetDeactivateModal({ open: true, size: 'small', dimmer: 'blurring' }) }}><img width='15' height='15' src='/assets/images/deactivate.svg' alt='Deactivate' /></button>
                          </div>
                        </td>
                      </tr>}
                      {digitalSolutionData?.enable_esign && <tr>
                        <td>
                          <img height='50' src='/assets/images/Signicat-logo.svg' alt='Signicat' className='' />
                        </td>
                        <td className='veritical-align-middle'>
                          <div className='d-flex align-items-center justify-content-center'>
                            <img width='15' height='15' src='/assets/images/pen.svg' alt='Bank Id' />
                            <p className='ml-1'>E-Signature</p>
                          </div>
                        </td>
                        <td className='veritical-align-middle text-break'>
                          {digitalSolutionData?.third_party_client_id_for_esign}
                        </td>
                        <td className='veritical-align-middle text-break'>
                          {digitalSolutionData?.third_party_client_secret_for_esign}
                        </td>
                        <td className='veritical-align-middle'>
                          <div className='flexcolumn justify-content-center'>
                          <button className='cursor-pointer border-0 bg-transparent p-0 mx-1' 
                          onClick={(e) => handleEdit(e, "E-Signature")}
                          >
                            <img width='15' height='15' src='/assets/images/edit.svg' alt='Edit' />
                          </button>
                          <button className='cursor-pointer border-0 bg-transparent p-0 mx-1'
                           onClick={() => { setDeactivationData("enable_esign");
                           setData('') ;
                           setDeactivationErrorAlert(false);
                           SetDeactivateModal({ open: true, size: 'small', dimmer: 'blurring' }) }}
                           >
                            <img width='15' height='15' src='/assets/images/deactivate.svg' alt='Deactivate' />
                            </button>
                            </div>
                        </td>
                      </tr>}
                      {!(((digitalSolutionData?.third_party_client_id?.trim()?.length !== 0 && digitalSolutionData?.third_party_client_secret?.trim()?.length !== 0))) && !digitalSolutionData?.enable_credit_check && !digitalSolutionData?.enable_esign && 
                       <tr>
                       <td align='center' colSpan={5}>
                           No Records
                       </td>                                    
                   </tr> 

                      }
                    </tbody>
                  </table>
                </div>
                {(!digitalSolutionData?.enable_credit_check ||
                  !digitalSolutionData?.enable_esign ||
                  !(((digitalSolutionData?.third_party_client_id?.trim()?.length !== 0 && digitalSolutionData?.third_party_client_secret?.trim()?.length !== 0)))) &&
                  <div className='text-center mt-3'>
                    <button className='ui button bg-primary-light text-white fw-400' onClick={(e) => newConfigurationHandler(e)}>Add Configuration</button>
                  </div>}
              </div>
            </div>
          </div>
        }
        <Modal
          dimmer={deactivateModal.dimmer}
          open={deactivateModal.open}
          size={deactivateModal.size}
          onClose={() => SetDeactivateModal({ open: false })}
        >
          <Modal.Header className='text-center bg-primary text-red'><i aria-hidden="true" class="warning sign icon "></i> Deactivation Alert</Modal.Header>
          <Modal.Content>
            <div>
              {/* <div className='row deactivate-modal mt-1 mb-3'>
                <div className='col-lg-4 col-md-4 col-sm-12'>
                  <div className='d-flex align-items-center my-sm-2'>
                    <input type='checkbox' name='enable_credit_check' checked={watch("enable_credit_check")} disabled={!watch("enable_credit_check")} onChange={(e) => { setValue(e.target.name, e.target.checked) }} />
                    <img className='integeration-img' alt='Credit Check' src='/assets/images/credit-check.svg' />
                    <label className='fs-7'>Credit Check</label>
                  </div>
                </div>
                <div className='col-lg-4 col-md-4 col-sm-12'>
                  <div className='d-flex align-items-center my-sm-2'>
                    <input type='checkbox' name='enable_esign' checked={watch("enable_esign")} disabled={!watch("enable_esign")} onChange={(e) => { setValue(e.target.name, e.target.checked) }} />
                    <img className='integeration-img' alt='Pen' src='/assets/images/pen.svg' />
                    <label className='fs-7'>E-Signature</label>
                  </div>
                </div>
                <div className='col-lg-4 col-md-4 col-sm-12'>
                  <div className='d-flex align-items-center my-sm-2'>
                    <input type='checkbox' name='enable_bank_id_verification' checked={watch("enable_bank_id_verification")} disabled={!watch("enable_bank_id_verification")} onChange={(e) => { setValue(e.target.name, e.target.checked) }} />
                    <img className='integeration-img' alt='Bank Id' src='/assets/images/bank-id-2.svg' />
                    <label className='fs-7'>BankID Authentication</label>
                  </div>
                </div>
              </div> */}
              <div className='field'>
                <label className='d-block'>Reason for deactivation<i className='text-red'>*</i></label>
                <textarea rows={7} className="w-100 border p-1" onChange={(e) => {deactivtionErrorAlert && (e?.target?.value)?.trim().length > 10 && setDeactivationErrorAlert(false) ;setData(e.target.value)}} ></textarea>
                <div className='error-deactivation'>
                          {deactivtionErrorAlert && <label className='w-30 text-nowrap fw-500 mb-sm-2'>*{'Ensure this value has at most 10 characters'}</label>}
                        </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions className='text-center'>
            <button className='ui button bg-gray-2 text-dark fw-400' onClick={() => {setDeactivationErrorAlert(false) ;setDeactivationData(""); SetDeactivateModal({ open: false }) }}>CANCEL</button>
            <button className='ui button bg-primary text-white fw-400' onClick={()=>DeactivateSignicatHandler()} >CONFIRM</button>
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  )
}
