import React, { useEffect } from 'react';
import { Loader, Segment, Modal } from 'semantic-ui-react';
import { userManager } from './Auth';
import CryptoJS from "crypto-js"

const Callback = () => {
    const sessionSetter = async () => {
        const user = await userManager.signinCallback();
        
        var encrypted = CryptoJS.AES.encrypt(JSON.stringify(user), "33d044268d5467b57bba473dd23bd66fac99825a977041e6b68358abe2882bf0");
        sessionStorage.clear()
        sessionStorage.setItem('oidc:sso', encrypted);
        // sessionStorage.setItem('isLogged', true);
        // sessionStorage.setItem("access_token", user.access_token);
        // sessionStorage.setItem('isLogged', true);
        // sessionStorage.setItem('user_login', false)
        window.location.href="/"
    }

    useEffect(() => {
        sessionSetter()
    }, []);

    return (
        <div className='loader-screan'>
            <Modal basic open={true} dimmer={'inverted'} centered >
                <Modal.Content>
                <Loader active>Preparing Files</Loader>
                </Modal.Content>
            </Modal>
            {/* <Segment>
                <Loader active>Preparing Files</Loader>
            </Segment> */}
        </div>
    );
}

export default Callback;
