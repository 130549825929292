import React, { useState, useEffect, useContext, useRef } from 'react'
import { Dropdown, Pagination, Input, Icon, Popup } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import WelcomePopup from '../components/WelcomePopup';
import { IntegrationService } from '../services/integrationService';
import { STATUS, EVENT_TYPE, ROW_OPTION, TosterVariant, tenantLocationOption, tenantTypeOption, tenantStatusOption } from "../constant/constant"
import { appContext } from '../context/appContext';
import moment from 'moment';

export default function BankIdReports() {
  const [filterData, setFilterData] = useState({})
  const [tenantBankIdDetails, settenantBankIdDetails] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("");
  const [bankIdFilter, setBankIdFilter] = useState({ location: "", tenant_status: "", tenant_type: "", bankId_status: "", building_name: "", start_date: "", end_date: "" })
  const [locations, setLocations] = useState([])
  const [buildings, setBuildings] = useState([])
  const [buildingsDropdownValue, setBuildingsDropdownValue] = useState('')
  const initialCount = useRef(0);
  const reportsData = [
    {
      id: '1',
      location: 'Davis',
      buildingName: 'Berlim',
      unitNumber: '12',
      tenantName: 'Karthick ram',
      tenantType: 'Personal User',
      email: 'Karthick@gmail.com',
      mobileNumber: '98657634354',
      authenticationMethod: 'BankID',
    },
    {
      id: '2',
      location: 'Woodland',
      buildingName: 'Alpha',
      unitNumber: '24',
      tenantName: 'Barath',
      tenantType: 'Personal User',
      email: 'Karthick@gmail.com',
      mobileNumber: '98657634354',
      authenticationMethod: 'BankID'
    }
  ]
  const tenantLocationOption = [
    {
      key: 1,
      text: 'All',
      value: 'All'
    },
    {
      key: 2,
      text: 'Missouri',
      value: 'Missouri'
    }
  ]
  const tenantBuildingOption = [
    {
      key: 1,
      text: 'All',
      value: 'All'
    },
    {
      key: 2,
      text: 'Building1',
      value: 'Building1',
    },
  ]

  const authenticationStatusOption = [
    {
      key: 1,
      text: 'All',
      value: 'All'
    },
    {
      key: 2,
      text: 'Success',
      value: 'SUCCESS',
    },
    {
      key: 3,
      text: 'Failed',
      value: 'FAILED',
    },
  ]

  const { setSpinnerVisibility, showToaster } = useContext(appContext)

  const integrationService = new IntegrationService();

  const integerationAPI = async (payloadData) => {
    try {
      // setSpinnerVisibility(true)
      const { data } = await integrationService.integration({
        event_type: EVENT_TYPE.getTenantBankIdDetails,
        rows_per_page: rowsPerPage,
        page: activePage,
        search: search,
        ...filterData
      })
      // setSpinnerVisibility(false)
      if (data?.status === STATUS.success && data.body) {
        settenantBankIdDetails(data?.body?.result)
        setTotalPages(data?.body?.pageCount)
        data.body.result.length === 0 && setActivePage(1)

      }

    } catch (error) {
      // setSpinnerVisibility(false)
      console.log("error", error);
      return error
    }
  }
  const getLocation = async () => {
    try {
      // setSpinnerVisibility(true)
      const { data } = await integrationService.integration({
        event_type: EVENT_TYPE.getLocation,
      })
      // setSpinnerVisibility(false)
      if (data?.status === STATUS.success && data.body) {
        let locationData = data?.body?.length > 1 ? [{
          key: 0,
          value: "All",
          text: "All"
        }] : [];
        data?.body?.map((location, index) => {
          if (location && location.name) {

            locationData.push({
              key: index + 1,
              value: location?.name,
              text: location?.name
            })
          }

        })
        setLocations(locationData)

      }
    } catch (error) {
      // setSpinnerVisibility(false)
      console.log("error", error);
    }
  }

  const getBuildings = async () => {
    try {
      // setSpinnerVisibility(true)
      let buildingsPayload = {
        event_type: EVENT_TYPE.getBuilding,
      }

      if (bankIdFilter?.location) {
        buildingsPayload = { ...buildingsPayload, location: bankIdFilter?.location }
      }
      const { data } = await integrationService.integration({ ...buildingsPayload })
      // setSpinnerVisibility(false)
      if (data?.status === STATUS.success && data.body) {
        let buildingData = data?.body?.length > 1 ? [{
          key: 0,
          value: "All",
          text: "All"
        }] : [];
        data?.body?.filter((building, index) => {
          if (building && building?.name) {
            buildingData.push({
              key: index + 1,
              value: building?.name,
              text: building?.name
            })
          }

        })
        setBuildings(buildingData)

      }
    } catch (error) {
      // setSpinnerVisibility(false)
      console.log("error", error);
    }
  }

  const handlePaginationChange = (e, { activePage }) => { setActivePage(activePage) }
  const handleTotalRows = (e) => { setRowsPerPage(e.target.value) }
  const handleSearch = (e) => {
    if (e.target.value.length) {
      setSearch(e.target.value);
      setActivePage(1);
    }
    else setSearch("");
  }

  const handleFilter = (e, data) => {
    if (data?.name === "building_name") {
      setBuildingsDropdownValue(data?.value)
    }
    data.value === "All" ? setBankIdFilter({ ...bankIdFilter, [data.name]: "" }) : setBankIdFilter({ ...bankIdFilter, [data.name]: data.value })
    // setEsignFilter({ ...esignFilter, [data.name]: data.value })
  }
  const handleDateRange = (e, { value }) => {
    e.preventDefault()
    if (value) {
      if (value.length > 1) {
        setBankIdFilter({ ...bankIdFilter, ["start_date"]: moment.utc(value[0]).format('YYYY-MM-DD HH:mm:ss'), ["end_date"]: moment.utc(new Date(moment(value[1]).format('YYYY-MM-DD 23:59:59')).toISOString()).format('YYYY-MM-DD HH:mm:ss') })
      }
      else {
        setBankIdFilter({ ...bankIdFilter, ["start_date"]: moment.utc(value[0]).format('YYYY-MM-DD HH:mm:ss'), ["end_date"]: moment.utc(new Date(moment(new Date()).format('YYYY-MM-DD 23:59:59')).toISOString()).format('YYYY-MM-DD HH:mm:ss') })
      }
    }
    else {
      setBankIdFilter({ ...bankIdFilter, ["start_date"]: "", ["end_date"]: "" })
    }
  }

  const handleFilterButtonTrigger = (e) => {
    e.preventDefault();
    setActivePage(1);
    setFilterData(bankIdFilter);
  }
  const initialCallWithloder = async () => {
    setSpinnerVisibility(true)
    await integerationAPI();
    setSpinnerVisibility(false)
  }
  useEffect(() => {
    if (initialCount.current) {
      integerationAPI()
    }
  }, [search])

  useEffect(() => {
    initialCallWithloder();
    initialCount.current = initialCount.current + 1
  }, [activePage, rowsPerPage, filterData])

  useEffect(() => {
    getLocation();
  }, [])

  useEffect(() => {
    getBuildings();
    setBankIdFilter({ ...bankIdFilter, ["building_name"]: "" })
    if (buildings?.find((data) => data?.name === buildingsDropdownValue) === undefined) {
      setBuildingsDropdownValue('')
    }

  }, [bankIdFilter?.location])


  return (
    <div className='py-2'>
      <div className='mx-2'>
        <h2 className='text-white fs-6 mb-4 fw-300 d-flex align-items-center'>
          Reports
          <span className='fs-8 PageSub-title px-1 ml-1'>
            <img height='17' width='17' src='/assets/images/bank-id.svg' alt='Filter' />
            <span className='veritical-align-text-top pl-1'>BankID Authentication</span>
          </span>
        </h2>
        <div className='tenants-reports-div'>
          <div className='bg-white shadow border-radius-15 mb-3'>
            <h5 className='border-bottom py-3 px-3 fs-6 fw-500 text-primary-light'>BANKID AUTHENTICATION REPORT</h5>
            <div className='px-1 py-3'>
              <form class="ui form">
                <div className='row'>
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2 mb-2'>
                    <div class="field">
                      <label className='fw-500 mb-1'>Select Location</label>
                      <Dropdown placeholder='Select Location' clearable fluid floating selection forceSelection={false} selectOnBlur={false} options={locations} name={"location"} onChange={handleFilter} />
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field">
                      <label className='fw-500 mb-1'>Select Building</label>
                      <Dropdown placeholder='Select Building' clearable fluid floating selection forceSelection={false} value={bankIdFilter?.building_name} selectOnBlur={false} options={buildings} name={"building_name"} onChange={handleFilter} />
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field">
                      <label className='fw-500 mb-1'>Tenant Type</label>
                      <Dropdown placeholder='Tenant Type' clearable fluid floating selection options={tenantTypeOption} name={"tenant_type"}
                        onChange={handleFilter} />

                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field">
                      <label className='fw-500 mb-1'>Tenant Status</label>
                      <Dropdown placeholder='Tenant Status' clearable fluid floating selection options={tenantStatusOption} forceSelection={false} selectOnBlur={false} name={"tenant_status"}
                        onChange={handleFilter} />

                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field">
                      <label className='fw-500 mb-1'>Authentication Status</label>
                      <Dropdown placeholder='Authentication Status' clearable fluid floating selection forceSelection={false} selectOnBlur={false} options={authenticationStatusOption} name={"bankId_status"} onChange={handleFilter} />
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field datePicker">
                      <label className='fw-500 mb-1'>Choose date</label>
                      <SemanticDatepicker placeholder='Select From Date' type="range" className='w-100' onChange={handleDateRange} />
                    </div>
                  </div>
                  {/* <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field datePicker">
                      <label className='fw-500 mb-1'>To Date</label>
                      <SemanticDatepicker placeholder='Select To Date' className='w-100' />
                    </div>
                  </div> */}
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2'>
                    <label className='fs-7 fw-400 mb-1 d-block invisible'>Get Report</label>
                    <button class="ui button bg-primary text-white fs-7 fw-400" type="submit" onClick={handleFilterButtonTrigger}>Get Report</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className='reports-table-div'>
            <div className='row pt-4 pb-2'>
              <div className='col-lg-6 col-md-6 col-sm-12 text-left text-sm-center mb-1'>
                <label className='show-entry py-1 px-1 border-radius-5 d-inline-block'>
                  Show
                  <select className='border-0 cursor-pointer outline-0' onChange={handleTotalRows}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  entries
                </label>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 text-sm-center text-right mb-1'>
                <div>
                  <div>
                    <Input className='w-75' iconPosition='left' icon='search' placeholder='Search...' onChange={handleSearch} />
                  </div>
                </div>
              </div>
            </div>
            <div className='reportsTable-content bg-white shadow-sm border-radius-10 overflow-x-auto w-100'>
              <div className='reportsTable'>
                <table className='w-100 table-layout-fixed'>
                  <thead>
                    <tr>
                      <th>TENANT NAME & TYPE</th>
                      <th>LOCATION</th>
                      <th>BUILDING NAME</th>
                      <th>UNIT#</th>

                      <th>EMAIL</th>
                      <th>MOBILE NUMBER</th>
                      <th>AUTHENTICATION METHOD</th>
                    </tr>
                  </thead>
                </table>
                <div className='ScrollBody overflow-y-auto'>
                  <table className='w-100 table-layout-fixed'>
                    <tbody>
                      {tenantBankIdDetails?.length ? tenantBankIdDetails.map((reportItem) => {
                        return (
                          <tr>
                            <td ><div className="ml-2 text-left">
                              <Popup wide inverted size='tiny'
                                trigger={<span><p className="fs-6 fw-500 text-break">{(reportItem.first_name ? reportItem.first_name : '') + " " + (reportItem.last_name ? reportItem.last_name : "")}</p><p className='text-break'>{reportItem.email}</p>{/* <p>{reportItem.mobile}</p> */}<p>{reportItem.is_active === (true) ? "ACTIVE" : "INACTIVE"}</p><a className='text-decoration-underline text-primary' href="/">{reportItem.tenantType}</a></span>}
                                content={<span><p className="fs-6 fw-500 text-break">{(reportItem.first_name ? reportItem.first_name : '') + " " + (reportItem.last_name ? reportItem.last_name : "")}</p><p className='text-break'>{reportItem.email}</p>{/* <p>{reportItem.mobile}</p> */}<p>{reportItem.is_active === (true) ? "ACTIVE" : "INACTIVE"}</p><a className='text-decoration-underline text-primary' href="/">{reportItem.tenantType}</a></span>}
                                hideOnScroll
                              />
                              {/* <p className="fs-6 fw-500 text-break">{(reportItem.first_name ? reportItem.first_name : '') + " " + (reportItem.last_name ? reportItem.last_name : "")}</p>
                              <p className='text-break'>{reportItem.email}</p>
                              <p>{reportItem.mobile}</p>
                              <p>{reportItem.is_active === (true) ? "ACTIVE" : "INACTIVE"}</p>
                              <a className='text-decoration-underline text-primary' href="/">{reportItem.tenantType}</a> */}
                            </div></td>
                            <td className='text-center text-break'><p>{reportItem.location}</p></td>
                            <td className='text-center text-break'><p>{reportItem.building_name}</p></td>

                            <td className='text-center'><p>{reportItem.unit_number}</p></td>

                            {/* <td className='text-center'><p className='text-break'>{reportItem.tenantName}</p><p className='text-primary'>{reportItem.tenantType}</p></td> */}
                            <td className='text-center'><p className='text-break'>{reportItem.email ? reportItem.email : 'N/A'}</p></td>
                            <td className='text-center'><p>{reportItem.mobile}</p></td>

                            <td className='text-center'><p>N/A</p></td>
                          </tr>
                        )
                      }) :
                        <tr>
                          <td align='center' colSpan={7}>
                            No Records
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="pagination-div mt-2 mb-3 text-center">
              <Pagination
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: (totalPages > 10)
                }}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                  disabled: (activePage === 1)
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                  disabled: (!totalPages || activePage === totalPages)
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true, disabled: (activePage === 1) }}
                nextItem={{
                  content: <Icon name="angle right" />,
                  icon: true,
                  disabled: (!totalPages || activePage === totalPages)
                }}
                onPageChange={handlePaginationChange}
                activePage={activePage}
                totalPages={totalPages}
                disabled={totalPages === 1}
              />
            </div>
          </div>
        </div>
        <WelcomePopup />
      </div>
    </div>
  )
}
