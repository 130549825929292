import React, { useState, useEffect, useContext, useRef } from 'react'
import { Dropdown, Pagination, Input, Icon, Popup } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import WelcomePopup from '../components/WelcomePopup';
import { IntegrationService } from '../services/integrationService';
import { STATUS, EVENT_TYPE, ROW_OPTION, TosterVariant, tenantLocationOption, tenantTypeOption, tenantStatusOption } from "../constant/constant"
import { appContext } from '../context/appContext';
import moment from 'moment';

export default function CreditCheckReport() {
  const [filterData, setFilterData] = useState({})
  const [tenantCreditCheckDetails, setTenantCreditCheckDetails] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("");
  const [creditCheckFilter, setCreditCheckFilter] = useState({ location: "", tenant_status: "", tenant_type: "", credit_check_status: "", credit_score_range: "", start_date: "", end_date: "", building_name: "" })
  const [locations, setLocations] = useState([])
  const [buildings, setBuildings] = useState([])
  const [buildingsDropdownValue, setBuildingsDropdownValue] = useState('')
  const initialCount = useRef(0);
  const reportsData = [
    {
      id: '1',
      location: 'Davis',
      buildingName: 'Berlim',
      unitNumber: '12',
      tenantName: 'Karthick ram',
      email: 'Karthick@gmail.com',
      mobileNumber: '98657634354',
      tenantType: 'Personal User',
      tenantStatus: 'Active',
      creditScore: 'AAA',
      creditScoreStatus: 'Verified',
      creditScoreDate: '12/10/2022'
    },
    {
      id: '2',
      location: 'Woodland',
      buildingName: 'Alpha',
      unitNumber: '24',
      tenantName: 'Barath',
      email: 'Karthick@gmail.com',
      mobileNumber: '98657634354',
      tenantType: 'Business User',
      tenantStatus: 'Moveout',
      creditScore: 'A',
      creditScoreStatus: 'Not Verified',
      creditScoreDate: '12/10/2022'
    },
    {
      id: '3',
      location: 'Berkeley',
      buildingName: 'Beta',
      unitNumber: '34',
      tenantName: 'Barath',
      email: 'Karthick@gmail.com',
      mobileNumber: '98657634354',
      tenantType: 'Personal User',
      tenantStatus: 'Scheduled Moveout',
      creditScore: 'AN',
      creditScoreStatus: 'Not Verified',
      creditScoreDate: '12/10/2022'
    },
  ]

  const tenantBuildingOption = [
    {
      key: 1,
      text: 'All',
      value: 'All'
    },
    {
      key: 2,
      text: 'Building1',
      value: 'Building1',
    },
  ]


  const creditcheckVerfiedStatusOption = [
    {
      key: 1,
      text: 'All',
      value: 'All'
    },
    {
      key: 2,
      text: "Success",
      value: "SUCCESS",
    },
    // {
    //   key: 3,
    //   text: "Failed",
    //   value: "Failed",
    // },
    {
      key: 4,
      text: "Skipped",
      value: "SKIPPED",
    },
  ];
  const creditScorePersonalOption = [
    {
      id: 1,
      key: 1,
      text: "All",
      value: "All",
    },
    {
      id: 2,
      key: 2,
      text: "Excellent (71-100)",
      value: "71-100",
    },
    {
      id: 3,
      key: 3,
      text: "Good (51-70)",
      value: "51-70",
    },
    {
      id: 4,
      key: 4,
      text: "Average (30-50)",
      value: "30-50",
    },
    {
      id: 5,
      key: 5,
      text: "Poor (21-29)",
      value: "21-29",
    },
    {
      id: 6,
      key: 6,
      text: "Critical (1-20)",
      value: "1-20",
    },
  ];
  const creditScoreBusinessOption = [
    {
      id: 1,
      key: 1,
      text: "All",
      value: "All",
    },
    {
      id: 2,
      key: 2,
      text: "Good (AAA)",
      value: "AAA",
    },
    {
      id: 3,
      key: 3,
      text: "Satisfactory (AA)",
      value: "AA",
    },
    {
      id: 4,
      key: 4,
      text: "Acceptable (A)",
      value: "A",
    },
    {
      id: 5,
      key: 5,
      text: "Neutral (AN)",
      value: "AN",
    },
    // {
    //   id: 6,
    //   key: 6,
    //   text: "Not rateables(NR)",
    //   value: " NR",
    // },
    // {
    //   id: 7,
    //   key: 7,
    //   text: "Unsatisfactory(B)",
    //   value: "B",
    // },
    {
      id: 8,
      key: 8,
      text: "Weak (C)",
      value: "C",
    },
  ];

  /*context*/
  const { setSpinnerVisibility, showToaster } = useContext(appContext)

  /* service */
  const integrationService = new IntegrationService();

  const integerationAPI = async (payloadData) => {
    try {
      // setSpinnerVisibility(true)
      const { data } = await integrationService.integration({
        event_type: EVENT_TYPE.getTenantCredidCheckDetails,
        rows_per_page: rowsPerPage,
        page: activePage,
        search: search,
        ...filterData
      })
      // setSpinnerVisibility(false)
      if (data?.status === STATUS.success && data.body) {
        setTenantCreditCheckDetails(data?.body?.result)
        setTotalPages(data?.body?.pageCount)
        data.body.result.length === 0 && setActivePage(1)
      }
    } catch (error) {
      // setSpinnerVisibility(false)
      console.log("error", error);
      return error
    }
  }

  /* get location api */
  const getLocation = async () => {
    try {
      // setSpinnerVisibility(true)
      const { data } = await integrationService.integration({
        event_type: EVENT_TYPE.getLocation,
      })
      // setSpinnerVisibility(false)
      if (data?.status === STATUS.success && data.body) {
        let locationData = data?.body?.length > 1 ? [{
          key: 0,
          value: "All",
          text: "All"
        }] : [];
        data?.body?.map((location, index) => {
          if (location && location.name) {

            locationData.push({
              key: index + 1,
              value: location?.name,
              text: location?.name
            })
          }

        })
        // setLocations(locationData)

      }
    } catch (error) {
      setSpinnerVisibility(false)
      console.log("error", error);
    }
  }

  /* buildings api */

  /* get location api */
  const getBuildings = async () => {
    try {
      // setSpinnerVisibility(true)
      let buildingsPayload = {
        event_type: EVENT_TYPE.getBuilding,
      }

      if (creditCheckFilter?.location) {
        buildingsPayload = { ...buildingsPayload, location: creditCheckFilter?.location }
      }
      const { data } = await integrationService.integration({ ...buildingsPayload })
      // setSpinnerVisibility(false)
      if (data?.status === STATUS.success && data.body) {
        let buildingData = data?.body?.length > 1 ? [{
          key: 0,
          value: "All",
          text: "All"
        }] : [];
        data?.body?.filter((building, index) => {
          if (building && building?.name) {
            buildingData.push({
              key: index + 1,
              value: building?.name,
              text: building?.name
            })
          }

        })
        setBuildings(buildingData)

      }
    } catch (error) {
      // setSpinnerVisibility(false)
      console.log("error", error);
    }
  }

  /* Service End */


  const handlePaginationChange = (e, { activePage }) => { setActivePage(activePage) }
  const handleTotalRows = (e) => { setRowsPerPage(e.target.value) }
  const handleSearch = (e) => {
    if (e.target.value.length) {
      setSearch(e.target.value);
      setActivePage(1);
    }
    else setSearch("");
  }
  const handleFilter = (e, data) => {
    if (data?.name === "building_name") {
      setBuildingsDropdownValue(data?.value)
    }
    // setCreditCheckFilter({ ...creditCheckFilter, [data.name]: data.value })

    let filterValue = data.name === "tenant_type" ? { "credit_score_range": "" } : {}
    data.value === "All" ? (data?.name === "tenant_type" ? setCreditCheckFilter({ ...creditCheckFilter, ...filterValue, [data.name]: "All" }) : setCreditCheckFilter({ ...creditCheckFilter, ...filterValue, [data.name]: "" })) : setCreditCheckFilter({ ...creditCheckFilter, ...filterValue, [data.name]: data.value })
  }

  const handleDateRange = (e, { value }) => {
    e.preventDefault()
    if (value) {
      if (value.length > 1) {
        setCreditCheckFilter({ ...creditCheckFilter, ["start_date"]: moment.utc(value[0]).format('YYYY-MM-DD HH:mm:ss'), ["end_date"]: moment.utc(new Date(moment(value[1]).format('YYYY-MM-DD 23:59:59')).toISOString()).format('YYYY-MM-DD HH:mm:ss') })
      }
      else {
        setCreditCheckFilter({ ...creditCheckFilter, ["start_date"]: moment.utc(value[0]).format('YYYY-MM-DD HH:mm:ss'), ["end_date"]: moment.utc(new Date(moment(new Date()).format('YYYY-MM-DD 23:59:59')).toISOString()).format('YYYY-MM-DD HH:mm:ss') })
      }
    }
    else {
      setCreditCheckFilter({ ...creditCheckFilter, ["start_date"]: "", ["end_date"]: "" })
    }
  }
  const handleFilterButtonTrigger = (e) => {
    e.preventDefault();
    setActivePage(1);
    setFilterData(creditCheckFilter);
  }

  const initialCallWithloder = async () => {
    setSpinnerVisibility(true)
    await integerationAPI();
    setSpinnerVisibility(false)
  }

  useEffect(() => {
    if (initialCount.current) {
      integerationAPI()
    }
  }, [search])

  useEffect(() => {
    initialCallWithloder();
    initialCount.current = initialCount.current + 1
  }, [activePage, rowsPerPage, filterData])

  useEffect(() => {
    getLocation();
  }, [])

  useEffect(() => {
    getBuildings();
    setCreditCheckFilter({ ...creditCheckFilter, ["building_name"]: "" })
    if (buildings?.find((data) => data?.name === buildingsDropdownValue) === undefined) {
      setBuildingsDropdownValue('')
    }

  }, [creditCheckFilter?.location])
  return (
    <div className='py-2'>
      <div className='mx-2'>
        <h2 className='text-white fs-6 mb-4 fw-300 d-flex align-items-center'>
          Reports
          <span className='fs-8 PageSub-title px-1 ml-1'>
            <img height='17' width='17' src='/assets/images/bank-id.svg' alt='Filter' />
            <span className='veritical-align-text-top pl-1'>Credit Check Report</span>
          </span>
        </h2>
        <div className='tenants-reports-div'>
          <div className='bg-white shadow border-radius-15 mb-3'>
            <h5 className='border-bottom py-3 px-3 fs-6 fw-500 text-primary-light'>Credit Check Report</h5>
            <div className='px-1 py-3'>
              <form class="ui form">
                <div className='row'>
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2 mb-2'>
                    <div class="field">
                      <label className='fw-500 mb-1'>Select Location</label>
                      <Dropdown placeholder='Select Location' forceSelection={false} selectOnBlur={false} clearable fluid floating selection options={locations} name={"location"}
                        onChange={handleFilter} />
                      {/* <p className='text-red'>{creditCheckFilter?.location==="" ? "*please select location" : ""}</p> */}
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field">
                      <label className='fw-500 mb-1'>Select Building</label>
                      <Dropdown placeholder='Select Building' value={creditCheckFilter?.building_name} forceSelection={false} selectOnBlur={false} clearable fluid floating selection options={buildings} name={"building_name"}
                        onChange={handleFilter} />

                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field">
                      <label className='fw-500 mb-1'>Tenant Type</label>
                      <Dropdown placeholder='Tenant Type' forceSelection={false} selectOnBlur={false} clearable fluid floating selection options={tenantTypeOption} name={"tenant_type"}
                        onChange={handleFilter} />

                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field">
                      <label className='fw-500 mb-1'>Tenant Status</label>
                      <Dropdown placeholder='Tenant Status' forceSelection={false} selectOnBlur={false} clearable fluid floating selection options={tenantStatusOption} name={"tenant_status"}
                        onChange={handleFilter} />

                    </div>
                  </div>
                  {/* <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field">
                      <label className='fw-500 mb-1'>Credit Check verified status</label>
                      <Dropdown placeholder='Credit Check verified status' clearable fluid floating selection options={creditcheckVerfiedStatusOption}  name={"credit_check_status"}
                        disabled={creditCheckFilter?.location === ""}  onChange={handleFilter} />
                        <p className='text-red'>{creditCheckFilter?.location==="" ? "*please select location" : ""}</p>
                    </div>
                  </div> */}
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field">
                      <label className='fw-500 mb-1'>Credit Score</label>{console.log(creditCheckFilter)}
                      <Dropdown placeholder='Credit Score' forceSelection={false} selectOnBlur={false} clearable fluid floating selection
                        disabled={creditCheckFilter?.tenant_type === ''}
                        options={creditCheckFilter?.tenant_type === 'BUSINESS_USER' ? creditScoreBusinessOption : creditScorePersonalOption} name={"credit_score_range"}
                        onChange={handleFilter} />

                    </div>
                  </div>
                  {/* <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field datePicker">
                      <label className='fw-500 mb-1'>From Date</label>
                      <SemanticDatepicker className="datePicker" placeholder='Select From Date' name="start_date"
                          onChange={handleFilter} />
                          <SemanticDatepicker className="datePicker" datePickerOnly={true} clearable={true} locale="en-US" type="range" placeholder='Choose Date' />
                    </div>
                  </div> */}
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field datePicker">
                      <label className='fw-500 mb-1'>Choose Date</label>
                      <SemanticDatepicker autocomplete="off" placeholder='Choose Date' type="range" name="end_date" className='w-100'
                        onChange={handleDateRange} />
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2'>
                    <label className='fw-500 mb-1 d-block invisible'>Apply</label>
                    <button class="ui button bg-primary text-white fs-7 fw-400" onClick={handleFilterButtonTrigger} >Get Report</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className='reports-table-div'>
            <div className='row pt-4 pb-2'>
              <div className='col-lg-6 col-md-6 col-sm-12 text-left text-sm-center mb-1'>
                <label className='show-entry py-1 px-1 border-radius-5 d-inline-block'>
                  Show
                  <select className='border-0 cursor-pointer outline-0' onChange={handleTotalRows}>
                    {ROW_OPTION.map((option) => {
                      return <option value={option}>{option}</option>
                    })}
                  </select>
                  entries
                </label>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 text-sm-center text-right mb-1'>
                <div>
                  <div>
                    <Input className='w-75' iconPosition='left' icon='search' placeholder='Search...' onChange={handleSearch} />
                  </div>
                </div>
              </div>
            </div>
            <div className='reportsTable-content bg-white shadow-sm border-radius-10 overflow-x-auto w-100'>
              <div className='reportsTable'>
                <table className='w-100 table-layout-fixed'>
                  <thead>
                    <tr>
                      <th className='text-left'>Tenant Details</th>
                      <th>Location</th>
                      <th>Building Name</th>
                      <th>Unit#</th>
                      <th>Tenant Status</th>
                      <th>Credit Score</th>
                      <th>Credit Score Status</th>
                      <th>Verified Date</th>
                    </tr>
                  </thead>
                </table>
                <div className='ScrollBody overflow-y-auto'>
                  <table className='w-100 table-layout-fixed'>
                    <tbody>
                      {tenantCreditCheckDetails?.length ? tenantCreditCheckDetails.map((reportItem) => {
                        return (
                          <tr>
                            <td className='text-left'>
                              <Popup wide inverted size='tiny'
                                trigger={<span><p className="fs-6 fw-500 text-break">{(reportItem?.first_name ? reportItem?.first_name : '') + ' ' + (reportItem?.last_name ? reportItem?.last_name : '')}</p><p className='text-break'>{reportItem?.email}</p><p>{reportItem?.mobile}</p><a className='text-decoration-underline text-primary' href="/">{reportItem?.tenantType}</a></span>}
                                content={<span><p className="fs-6 fw-500 text-break">{(reportItem?.first_name ? reportItem?.first_name : '') + ' ' + (reportItem?.last_name ? reportItem?.last_name : '')}</p><p className='text-break'>{reportItem?.email}</p><p>{reportItem?.mobile}</p><a className='text-decoration-underline text-primary' href="/">{reportItem?.tenantType}</a></span>}
                                hideOnScroll
                              />
                              {/* <p className="fs-6 fw-500 text-break">
                                {(reportItem?.first_name ? reportItem?.first_name : '') + ' ' + (reportItem?.last_name ? reportItem?.last_name : '')}
                              </p>
                              <p className='text-break'>{reportItem?.email}</p>
                              <p>{reportItem?.mobile}</p><a className='text-decoration-underline text-primary' href="/">{reportItem?.tenantType}</a> */}
                            </td>
                            <td className='text-center'><p className='text-break'>{reportItem?.location}</p></td>
                            <td className='text-center'><p className='text-break'>{reportItem?.building_name}</p></td>
                            {/* <td className='text-center'><a className='text-decoration-underline text-primary' href="/">{reportItem?.unit_number}</a></td> */}
                            <td className='text-center'>{reportItem?.unit_number}</td>
                            <td className='text-center'><p>{reportItem?.is_active ? 'Active' : 'InActive'}</p></td>
                            <td className='text-center'><p>{reportItem?.credit_check_details?.credit_score ? reportItem?.credit_check_details?.credit_score : 'N/A'}</p></td>
                            <td className='text-center'><p>{reportItem?.credit_check_details ? "Verified" : "N/A"}</p></td>
                            <td className='text-center'><p>{reportItem?.credit_check_details ? moment(reportItem?.credit_check_details?.modified_on).format("DD-MM-YYYY hh:mm") : 'N/A'}</p></td>
                          </tr>
                        )
                      }) :
                        <tr>
                          <td align='center' colSpan={8}>
                            No Records
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="pagination-div mt-2 mb-3 text-center">
              <Pagination
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: (totalPages > 10)
                }}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                  disabled: (activePage === 1)
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                  disabled: (!totalPages || activePage === totalPages)
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true, disabled: (activePage === 1) }}
                nextItem={{
                  content: <Icon name="angle right" />,
                  icon: true,
                  disabled: (!totalPages || activePage === totalPages)
                }}
                onPageChange={handlePaginationChange}
                activePage={activePage}
                totalPages={totalPages}
                disabled={totalPages === 1}
              />
            </div>
          </div>
        </div>
        {/* <button onClick={ () => Tab(1)}>Tab</button> */}
        <WelcomePopup />
      </div>
    </div>
  )
}
