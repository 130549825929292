import React, {useContext,useEffect,useState} from 'react';
import WelcomePopup from '../components/WelcomePopup';
import CreditCheckDashboard from "./CreditCheckDashboard";
import EsignDashboard from "./EsignDashboard";
import BankIdDashboard from "./BankIdDashboard";
import { IntegrationService } from '../services/integrationService';
import { STATUS, EVENT_TYPE, TosterVariant, onKeyDown } from "../constant/constant"
import { appContext } from '../context/appContext';

export default function Dashboard(props) {
  const [dashboardData,setDashboardData] = useState([])
  
  
  /*context*/
const { setSpinnerVisibility, showToaster, userDetails } = useContext(appContext)

 /* service */
 const integrationService = new IntegrationService();

 const integerationAPI = async (payloadData) => {
     try {
         setSpinnerVisibility(true)
         const { data } = await integrationService.integration({
             event_type: EVENT_TYPE.getClientFeatureMetrics
         })
         setSpinnerVisibility(false)
         setDashboardData(data?.body)
     } catch (error) {
         setSpinnerVisibility(false)
         showToaster(TosterVariant.failed, "")
         console.log("error", error);
        //  return error
     }
 }
 /* Service End */

 useEffect(()=>{
  integerationAPI()
 },[])
 
  return (
    <>
      {props.creditcheck && <CreditCheckDashboard dashboardData={dashboardData}/>}
      {props.esign && <EsignDashboard dashboardData={dashboardData}/>}
      {props.bankid && <BankIdDashboard dashboardData={dashboardData}/> }
      <WelcomePopup />
    </>
  )
}
