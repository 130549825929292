import React, { useState, useEffect, useContext, useRef } from 'react';
import { Dropdown, Pagination, Icon, Input, Popup } from 'semantic-ui-react';
import WelcomePopup from '../components/WelcomePopup';
import TenantsProfile from './TenantsProfile';
import { IntegrationService } from '../services/integrationService';
import { STATUS, EVENT_TYPE, TosterVariant, ROW_OPTION, tenantLocationOption, tenantTypeOption, tenantStatusOption } from "../constant/constant"
import { appContext } from '../context/appContext';

export default function BankIdTenants(props) {
  const [filterData, setFilterData] = useState({})
  const [tenantBankIdDetails, setTenantBankIdDetails] = useState([])
  /* pagination and filter */
  const rowOptions = [10, 25, 50]
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("")

  const [bankIdFilter, setBankIdFilter] = useState({ location: "", tenant_status: "", tenant_type: "", bankId_status: "", })

  const [tenantDetails, setTenantDetails] = useState({})

  const [locations, setLocations] = useState([])
  const initialCount = useRef(0);
  const tenantsData = [
    {
      tenantsChecked: 'chk',
      profileImage: '/assets/images/post-tenant-img.png',
      tenantName: 'Alexendria Aliex',
      tenantEmail: 'Alex@gmail.com',
      tenantNumber: '+91 79294548574',
      authenticationMethod: 'BankID',
      authenticationStatus: 'Success'
    },
    {
      tenantsChecked: 'chk1',
      profileImage: '/assets/images/post-tenant-img.png',
      tenantName: 'Alexendria Aliex',
      tenantEmail: 'Alex@gmail.com',
      tenantNumber: '+91 79294548574',
      authenticationMethod: 'BankID On Mobile',
      authenticationStatus: 'Failed'
    }
  ]
  const [showprofile, setShowProfile] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checked, setChecked] = useState({
    chk: false,
    chk1: false,
  });
  const selectAll = (value) => {
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    })
  }

  const handlePaginationChange = (e, { activePage }) => { setActivePage(activePage) }
  const handleTotalRows = (e) => { setRowsPerPage(e.target.value) }
  const handleSearch = (e) => {
    if (e.target.value.length) {
      setSearch(e.target.value);
      setActivePage(1);
    }
    else setSearch("");
  }
  // const toggleCheck = (inputName) => {
  //   setChecked((prevState) => {
  //     const newState = { ...prevState };
  //     newState[inputName] = !prevState[inputName];
  //     return newState;
  //   });
  // };
  // useEffect(() => {
  //   let allChecked = true;
  //   for (const inputName in checked) {
  //     if (checked[inputName] === false) {
  //       allChecked = false;
  //     }
  //   }
  //   if (allChecked) {
  //     setCheckedAll(true);
  //   } else {
  //     setCheckedAll(false);
  //   }
  // }, [checked]);
  const tenantLocationOption = [
    {
      key: 1,
      text: 'All',
      value: 'All'
    },
    {
      key: 2,
      text: 'Missouri',
      value: 'Missouri'
    }
  ]
  const tenantStatusOption = [
    {
      key: 1,
      text: 'Active',
      value: 'ACTIVE'
    },
    {
      key: 2,
      text: 'Inactive',
      value: 'INACTIVE',
    },
  ]
  const authenticationMethodOption = [
    {
      key: 1,
      text: 'All',
      value: 'All'
    },
    {
      key: 2,
      text: 'Norwegian BankID',
      value: 'Norwegian BankID',
    },
    {
      key: 3,
      text: 'BankID On Mobile',
      value: 'BankID On Mobile',
    },
  ]
  const authenticationStatusOption = [
    {
      key: 1,
      text: 'All',
      value: 'All'
    },
    {
      key: 2,
      text: 'Success',
      value: 'SUCCESS',
    },
    {
      key: 3,
      text: 'Failed',
      value: 'FAILED',
    },
  ]

  const { setSpinnerVisibility, showToaster, setCheckedValue, checkedValue } = useContext(appContext)

  const integrationService = new IntegrationService();

  const integerationAPI = async (payloadData) => {
    try {

      const { data } = await integrationService.integration({
        event_type: EVENT_TYPE.getTenantBankIdDetails,
        rows_per_page: rowsPerPage,
        page: activePage,
        search: search,
        ...filterData
      })
      if (data?.status === STATUS.success && data.body) {
        setTenantBankIdDetails(data.body.result)
        data.body.result.length === 0 && setActivePage(1)
        setTotalPages(data.body.pageCount)
      }
    } catch (error) {
      console.log("error", error);
      return error
    }
  }

  /* get locations api */
  const getLocation = async () => {
    try {
      // setSpinnerVisibility(true)
      const { data } = await integrationService.integration({
        event_type: EVENT_TYPE.getLocation,
      })
      // setSpinnerVisibility(false)
      if (data?.status === STATUS.success && data.body) {
        let locationData = data?.body?.length > 1 ? [{
          key: 0,
          value: "All",
          text: "All"
        }] : [];
        data?.body?.map((location, index) => {
          if (location && location.name) {
            locationData.push({
              key: index + 1,
              value: location?.name,
              text: location?.name
            })
          }

        })
        setLocations(locationData)

      }
    } catch (error) {
      // setSpinnerVisibility(false)
      console.log("error", error);
    }
  }
  const handleFilter = (e, data) => {
    data.value === "All" ? setBankIdFilter({ ...bankIdFilter, [data.name]: "" }) : setBankIdFilter({ ...bankIdFilter, [data.name]: data.value })
    // setEsignFilter({ ...esignFilter, [data.name]: data.value })
  }

  const handleFilterButtonTrigger = (e) => {
    e.preventDefault();
    setFilterData(bankIdFilter);
  }

  const initialCallWithloder = async () => {
    setSpinnerVisibility(true)
    await integerationAPI();
    setSpinnerVisibility(false)
  }

  useEffect(() => {
    if (initialCount.current) {
      integerationAPI()
    }
  }, [search])

  useEffect(() => {
    initialCallWithloder();
    initialCount.current = initialCount.current + 1
  }, [activePage, rowsPerPage, filterData])


  useEffect(() => {
    getLocation()
    setCheckedValue([])
    return () => {
      setCheckedValue([])
    }
  }, [])


  return (
    <div className='py-2'>
      <div className='mx-2'>
        <h2 className='text-white fs-6 mb-4 fw-300 d-flex align-items-center'>
          Tenants
          <span className='fs-8 PageSub-title px-1 ml-1'>
            <img height='17' width='17' src='/assets/images/tenant-filter.svg' alt='Filter' />
            <span className='veritical-align-text-top pl-1'>Tenant Filter</span>
          </span>
        </h2>
        {!showprofile && (
          <div className='tenants-filter-div'>
            <div className='bg-white shadow border-radius-15 mb-3'>
              <h5 className='border-bottom py-3 px-3 fs-6 fw-500 text-primary-light'>BANKID FILTER</h5>
              <div className='px-1 py-3'>
                <form class="ui form">
                  <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2 mb-2'>
                      <div class="field">
                        <label className='fw-500 mb-1'>Select Location</label>
                        <Dropdown placeholder='Select Location' clearable fluid floating selection forceSelection={false} selectOnBlur={false} options={locations} name='location' onChange={handleFilter} />
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                      <div class="field">
                        <label className='fw-500 mb-1'>Tenant Status</label>
                        <Dropdown placeholder='Tenant Status' clearable fluid floating selection forceSelection={false} selectOnBlur={false} options={tenantStatusOption} name='tenant_status' onChange={handleFilter} />
                      </div>
                    </div>
                    {/* <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                      <div class="field">
                        <label className='fw-500 mb-1'>Authentication Method</label>
                        <Dropdown placeholder='Authentication Method' clearable fluid floating selection options={authenticationMethodOption} name='tenant_type' onChange={handleFilter}  />
                      </div>
                    </div> */}
                    <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                      <div class="field">
                        <label className='fw-500 mb-1'>Authentication Status</label>
                        <Dropdown placeholder='Authentication Status' clearable fluid floating selection forceSelection={false} selectOnBlur={false} options={authenticationStatusOption} name='bankId_status' onChange={handleFilter} />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 px-2">
                      <button
                        class="ui button bg-primary text-white px-4 fs-7 fw-400"
                        onClick={handleFilterButtonTrigger}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className='tenants-table-div'>
              <div className='row pt-4 pb-2'>
                <div className='col-lg-6 col-md-6 col-sm-12 text-left text-sm-center mb-1'>
                  <label className='show-entry py-1 px-1 border-radius-5 d-inline-block'>
                    Show
                    <select className='border-0 cursor-pointer outline-0' onChange={handleTotalRows}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="50">100</option>
                    </select>
                    entries
                  </label>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 text-sm-center text-right mb-1'>
                  <div>
                    <div>
                      <Input className='w-75' iconPosition='left' icon='search' placeholder='Search...' onChange={handleSearch} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='tenantsTable-content bg-white shadow-sm border-radius-10 overflow-x-auto w-100'>
                <div className='tenantsTable'>
                  <table className='w-100 table-layout-fixed'>
                    <thead>
                      <tr>
                        {/*  'DONT DELETE' ===> there is no functinality added to the checkbox leter we can uncomment this*/}

                        {/* <th className='text-center'>
                          <input type="checkbox" onChange={(event) => selectAll(event.target.checked)} checked={checkedAll} />
                        </th> */}
                        <th className='text-left'>CUSTOMERS</th>
                        <th>AUTHENTICATION METHOD</th>
                        <th>AUTHENTICATION STATUS</th>
                      </tr>
                    </thead>
                  </table>
                  <div className='ScrollBody overflow-y-auto'>
                    <table className='w-100 table-layout-fixed'>
                      <tbody>
                        {tenantBankIdDetails?.length ? tenantBankIdDetails.map((tenantsItem) => {
                          return (
                            <tr>
                              {/*  'DONT DELETE' ===> there is no functinality added to the checkbox leter we can uncomment this*/}

                              {/* <td className='text-center'><input type="checkbox" onChange={() => toggleCheck(tenantsItem.tenantsChecked)}
                                checked={checked[tenantsItem.tenantsChecked]} />
                              </td> */}
                              <td className='text-center'>
                                <div className="d-flex justify-content-left align-items-start cursor-pointer" onClick={() => { setTenantDetails(tenantsItem); setShowProfile(true) }}>
                                  <div className="tenants-profile">
                                    <img
                                      src={tenantsItem?.photo_path || '/assets/images/dummy-profile-pic.jpg'}
                                      alt="Profile"
                                      rounded
                                    />
                                  </div>
                                  <div className='ml-2 text-left'>
                                    <Popup wide inverted size='tiny'
                                      trigger={<span><p className='fs-6 fw-500 cursor-pointer text-break' onClick={() => setShowProfile(true)}>{tenantsItem.first_name}</p><p className='text-break'>{tenantsItem.email}</p><p className='text-break'>{tenantsItem.mobile}</p></span>}
                                      content={<span><p className='fs-6 fw-500 cursor-pointer text-break' onClick={() => setShowProfile(true)}>{tenantsItem.first_name}</p><p className='text-break'>{tenantsItem.email}</p><p className='text-break'>{tenantsItem.mobile}</p></span>}
                                      hideOnScroll
                                    />
                                    {/* <p className='fs-6 fw-500 cursor-pointer text-break' onClick={() => setShowProfile(true)}>{tenantsItem.first_name}</p>
                                    <p className='text-break'>{tenantsItem.email}</p>
                                    <p className='text-break'>{tenantsItem.mobile}</p> */}
                                  </div>
                                </div>
                              </td>
                              <td className='text-center'><p>N/A</p></td>
                              <td className='text-center'><label>{tenantsItem?.bank_id_verification_details?.status ? tenantsItem?.bank_id_verification_details?.status : 'N/A'}</label></td>
                            </tr>
                          )
                        }) :
                          <tr>
                            <td align='center' colSpan={4}>
                              No Records
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="pagination-div mt-2 mb-3 text-center">
                <Pagination
                  ellipsisItem={{
                    content: <Icon name="ellipsis horizontal" />,
                    icon: (totalPages > 10)
                  }}
                  firstItem={{
                    content: <Icon name="angle double left" />,
                    icon: true,
                    disabled: (activePage === 1)
                  }}
                  lastItem={{
                    content: <Icon name="angle double right" />,
                    icon: true,
                    disabled: (!totalPages || activePage === totalPages)
                  }}
                  prevItem={{ content: <Icon name="angle left" />, icon: true, disabled: (activePage === 1) }}
                  nextItem={{
                    content: <Icon name="angle right" />,
                    icon: true,
                    disabled: (!totalPages || activePage === totalPages)
                  }}
                  onPageChange={handlePaginationChange}
                  activePage={activePage}
                  totalPages={totalPages}
                  disabled={totalPages === 1}
                />
              </div>
            </div>
          </div>
        )}
        {showprofile && (
          <TenantsProfile onProfile={() => setShowProfile(false)} tenantBankIdDetailsProp={tenantDetails} bankid={props?.bankid} />
        )}
        <WelcomePopup />
      </div>
    </div>
  )
}
