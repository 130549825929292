import React, { useEffect, useState } from 'react'
import ReactSpeedometer from 'react-d3-speedometer'

const SpeedMeeter = ({ value, user }) => {
    console.log(" value, user", value, user);
    const [meeterValue, setMeeterValue] = useState({
        property: "No rating",
        text: "",
        dummyValue:0
    })
    const personalUserTemplate = [
        {
            text: "1-20",
            position: "INSIDE",
            color: "gray"
        },

        {
            text: "21-29",
            position: "INSIDE",
            color: "gray"
        },

        {
            text: "30-50",
            position: "INSIDE",
            color: "gray"
        },
        {
            text: "51-70",
            position: "INSIDE",
            color: "gray"
        },
        {
            text: "71-100",
            position: "INSIDE",
            color: "gray"
        }]
    // const businessUserTemplate = [
    //     {
    //         text: "C",
    //         position: "INSIDE",
    //         color: "gray"
    //     },

    //     {
    //         text: "A",
    //         position: "INSIDE",
    //         color: "gray"
    //     },

    //     {
    //         text: "AA",
    //         position: "INSIDE",
    //         color: "gray"
    //     },
    //     {
    //         text: "AN",
    //         position: "INSIDE",
    //         color: "gray"
    //     },
    //     {
    //         text: "B",
    //         position: "INSIDE",
    //         color: "gray"
    //     }]

    const businessUserTemplate = [
        {
            text: "C",
            position: "INSIDE",
            color: "gray"
        },
        {
            text: "B",
            position: "INSIDE",
            color: "gray"
        },
        {
            text: "NR",
            position: "INSIDE",
            color: "gray"
        },
        {
            text: "AN",
            position: "INSIDE",
            color: "gray"
        },
        {
            text: "A",
            position: "INSIDE",
            color: "gray"
        },
        {
            text: "AA",
            position: "INSIDE",
            color: "gray"
        },
        {
            text: "AAA",
            position: "INSIDE",
            color: "gray"
        } 
        
    ]


    // const varity = {
    //     "0-20": {
    //         property: "Critical",
    //         text: "Very high risk",
    //         dummyValue:10
    //     },
    //     "21-29": {
    //         property: "Poor",
    //         text: "High risk",
    //         dummyValue:30
    //     },
    //     "30-50": {
    //         property: "Average",
    //         text: "Moderate risk",
    //         dummyValue:50
    //     },
    //     "51-70": {
    //         property: "Good",
    //         text: "Low risk",
    //         dummyValue:70
    //     },
    //     "71-100": {
    //         property: "Excellent",
    //         text: "Very low risk",
    //         dummyValue:90
    //     },
    //     "A": {
    //         property: "Creditworthy",
    //         text: "Average credit risk",
    //         dummyValue:30
    //     },
    //     "AA": {
    //         property: "Good creditworthiness",
    //         text: "Low credit risk",
    //         dummyValue:50
    //     },
    //     "AN": {
    //         property: "New company with positive background",
    //         text: "Low credit limit recommended",
    //         dummyValue:70
    //     },
    //     "B": {
    //         property: "Credit against curities",
    //         text: "Credit risk greater than average",
    //         dummyValue:90
    //     },
    //     "C": {
    //         property: "Credit Rejected",
    //         text: "High credit risk",
    //         dummyValue:10
    //     },
    //     "NR": {
    //         property: "No rating",
    //         text: "Information missing or inconsistent",
    //         dummyValue:0
        
    //     }
    // }


    const varity = {
        "0-20": {
            property: "Critical",
            text: "Very high risk",
            dummyValue:10
        },
        "21-29": {
            property: "Poor",
            text: "High risk",
            dummyValue:30
        },
        "30-50": {
            property: "Average",
            text: "Moderate risk",
            dummyValue:50
        },
        "51-70": {
            property: "Good",
            text: "Low risk",
            dummyValue:70
        },
        "71-100": {
            property: "Excellent",
            text: "Very low risk",
            dummyValue:90
        },
        "C": {
            property: "Weak",
            text: "High credit risk",
            dummyValue:5
        
        },
        "B": {
            property: "Unsatisfactory",
            text: "High credit risk",
            dummyValue:15
        
        },
        "NR": {
            property: "Not Rateable",
            text: "High credit risk",
            dummyValue:25
        },
        "AN": {
            property: "Creditworthy",
            text: "Average credit risk",
            dummyValue:35
        },
        "A": {
            property: "Good creditworthiness",
            text: "Low credit risk",
            dummyValue:45
        },
        "AA": {
            property: "New company with positive background",
            text: "Low credit limit recommended",
            dummyValue:55
        },
        "AAA": {
            property: "Credit against curities",
            text: "Credit risk greater than average",
            dummyValue:65
        },
       
       
        
        
        
        
    }

    const meeterRatingFunction = (data) => {
        if (user === "Personal") {
            // setUserValue(personalUserTemplate)
            let num = Number(data)
            if (num >= 0 && num <= 20) setMeeterValue(varity["0-20"])
            else if (num >= 21 && num <= 29) setMeeterValue(varity["21-29"])
            else if (num >= 30 && num <= 50) setMeeterValue(varity["30-50"])
            else if (num >= 51 && num <= 70) setMeeterValue(varity["51-70"])
            else if (num >= 71 && num <= 100) setMeeterValue(varity["71-100"])
        }
        else if(user === "Business") {
            // setUserValue(businessUserTemplate)
            let dataValue=data?.toUpperCase()
            if (dataValue === "AAA") setMeeterValue(varity["AAA"])
            else if (dataValue === "AA") setMeeterValue(varity["AA"])
            else if (dataValue === "A") setMeeterValue(varity["A"])
            else if (dataValue === "AN") setMeeterValue(varity["AN"])
            else if (dataValue === "NR") setMeeterValue(varity["NR"])
            else if (dataValue === "B") setMeeterValue(varity["B"])
            else if (dataValue === "C") setMeeterValue(varity["C"])
        }
        else{
            setMeeterValue({
                property: "No rating",
                text: "",
                dummyValue:0
            })
        }
    }
console.log(user,value);
    useEffect(() => {
        if (value) {
            meeterRatingFunction(value);
            // setUserValue(personalUserTemplate)

        }
        else{
            setMeeterValue({
                property: "No rating",
                text: "",
                dummyValue:0
            })
        }
    },[user,value])
    return (
        <div className='block'>
            <ReactSpeedometer
                // minValue={0}
                maxValue={user==="Personal" ? 100 : user==="Business" ? 70 : [] }
                segments={user==="Personal" ? 5 : user==="Business" ? 7 : 5 }
                width={320}
                height={200}
                ringWidth={25}
                value={meeterValue?.dummyValue ? meeterValue?.dummyValue : 0 }
                currentValueText={meeterValue?.property}
                forceRender={true}
                // customSegmentStops={[]}
                customSegmentLabels={user==="Personal" ? personalUserTemplate : user==="Business" ? businessUserTemplate : [] }
                // CustomSegmentLabel={[{text:"hai",fontSize:"25px",color:"yellow",position:"OUTSIDE"},{text:"ha",fontSize:"10px",color:"yellow"},{text:"h",fontSize:"10px",color:"yellow"},{text:"i",fontSize:"10px",color:"yellow"},{text:"a",fontSize:"10px",color:"yellow"}]}
                // value={25}
                needleTransitionDuration={500}
            />
            <p className='speedometer-text'>{meeterValue?.text}</p>
        </div>
    )
}

export default SpeedMeeter
