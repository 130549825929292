import { UserManager } from 'oidc-client';

const settings = {
    authority: process.env.REACT_APP_AUTHORITY,
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URI,
    post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    response_type: 'code',
    scope: 'openid email roles tenant sixstorage_admin_api_scope'
};

export const userManager = new UserManager(settings);