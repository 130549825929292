import React from 'react';
import CreditCheckTenants from './CreditCheckTenants';
import EsignautreTenants from './EsignatureTenants';
import BankIdTenants from './BankIdTenants';
import WelcomePopup from '../components/WelcomePopup';

export default function Tenants(props) {
  return (
    <>
      {props.creditcheck && <CreditCheckTenants creditcheck={props.creditcheck} />}
      {props.esign && <EsignautreTenants esign={props.esign}/>}
      {props.bankid && <BankIdTenants bankid={props.bankid}/>}
      <WelcomePopup />
    </>
  )
}
