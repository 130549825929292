import React from "react";
import moment from "moment"

const GeneralTab = ({tenantCreditCheckDetails}) => {
    return(
        <div className="row py-1">
            <div className="col-lg-3 col-md-3 col-sm-6 mb-2">
                <p className="fs-7 fw-500">Tenant ID</p>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-6 col-sm-6 mb-2">
                <p className="fs-7 d-flex"><span className="fs-7 fw-500 mr-3">:</span>{tenantCreditCheckDetails?.tenant_id}</p>
            </div>
            {/* <div className="col-lg-3 col-md-3 col-sm-6 mb-2">
                <p className="fs-7 fw-500">Address</p>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-6 mb-2">
                <p className="fs-7 d-flex"><span className="fs-7 fw-500 mr-3">:</span></p>
            </div> */}
            <div className="col-lg-3 col-md-3 col-sm-6 mb-2">
                <p className="fs-7 fw-500">Date of birth</p>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-6 mb-2">
                <p className="fs-7 d-flex"><span className="fs-7 fw-500 mr-3">:</span>{tenantCreditCheckDetails?.birth_date ? moment(tenantCreditCheckDetails?.birth_date).format("DD-MM-YYYY") : 'N/A'}</p>
            </div>
           { !tenantCreditCheckDetails?.business_user && <><div className="col-lg-3 col-md-3 col-sm-6 mb-2">
                <p className="fs-7 fw-500">Social Security Number</p>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-6 mb-2">
                <p className="fs-7 d-flex"><span className="fs-7 fw-500 mr-3">:</span>{tenantCreditCheckDetails.ssn ? tenantCreditCheckDetails.ssn : "N/A"}</p>
            </div></>}
           {tenantCreditCheckDetails?.business_user &&  <> <div className="col-lg-3 col-md-3 col-sm-6 mb-2">
                <p className="fs-7 fw-500">Company Registration Number</p>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-6 mb-2">
                <p className="fs-7 d-flex"><span className="fs-7 fw-500 mr-3">:</span>{tenantCreditCheckDetails.ssn ? tenantCreditCheckDetails.ssn : "N/A"}</p>
            </div></>}
            <div className="col-lg-3 col-md-3 col-sm-6 mb-2">
                <p className="fs-7 fw-500">Authentication Method</p>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-6 mb-2">
                <p className="fs-7 d-flex"><span className="fs-7 fw-500 mr-3">:</span>Norwegian BankID</p>
            </div>
        </div>
    )
}

export default GeneralTab