import axios from "axios"
import { userManager } from "../auth/Auth";

export class httpService {
    httpClient = axios.create({ baseURL: process.env.REACT_APP_BASEURL })

    constructor() {
        this.httpClient.interceptors.request.use((request) => {
            if (!request.data.client_id) {
                request.data = {
                    client_id: sessionStorage.getItem("client_id"),
                    initiated_by:sessionStorage.getItem("client_name"),
                    ...request.data
                }
                if (!request.headers?.Authorization) {
                    request.headers = {
                      authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
                      ...request.headers,
                    };
                  }
            
            }
            return request
        })
        this.httpClient.interceptors.response.use((response)=>{
            return response
        },async(error) => {
            if(error?.response?.status===401){
                this.getTocken()
            }
            console.log(error);
        })
    }

     getTocken = async () => {
        sessionStorage.clear();
        localStorage.clear();
        await userManager.signinRedirect();
    }
}