import React, {useState} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


const DoughnutChart = ({dashboardData,isEsign,isCreditCheck}) => {

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  const data = {
    labels: ['Success', 'Failed', isCreditCheck ? 'Skipped events' : isEsign ? 'Expire events' : 'Initiated events'],
    datasets: [
      {
        label: '# of Votes',
        data: [dashboardData?.success, dashboardData?.failed, dashboardData?.initiated],
        backgroundColor: [
          '#2396C1',
          '#FE738B',
          '#888B8A',
        ],
        borderColor: [
          '#2396C1',
          '#FE738B',
          '#888B8A',
        ],
        borderWidth: 1,
      },
    ],
  };
    return <Doughnut data={data} options={options} />
}

export default DoughnutChart
