import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal } from "semantic-ui-react";
import { EVENT_TYPE, STATUS } from '../constant/constant';
import { appContext } from '../context/appContext';
import { IntegrationService } from '../services/integrationService';

const WelcomePopup = (props) => {
  const [welcomePopup, SetwelcomePopup] = useState({
    open: false ,
    dimmer: undefined,
  })
  const navigate = useNavigate();
  const ValidateDigitalSolution = (e) => {
    e.preventDefault();
    navigate('/integration')
  }
  const { setSpinnerVisibility } = useContext(appContext)
    /* Services */
    const integrationService = new IntegrationService();

    const integerationAPI = async (payloadData) => {
      try {
        // setSpinnerVisibility(true)
        const { data } = await integrationService.integration({
          ...payloadData
        })
        // setSpinnerVisibility(false)
        return data
      } catch (error) {
        // setSpinnerVisibility(false)
        console.log("error", error);
        return error
      }
    }
    /* Service End */

  const getDigitalSolutionStatus = async () => {
    try {
      const payload = {
        event_type: EVENT_TYPE.getDigitalSolutionApplicationStatus,
      }
      const getStatus = await integerationAPI(payload)
      if (getStatus.status === STATUS.success) {
        if (getStatus?.body?.is_credit_check_app_enabled) {
          localStorage.setItem('integration', true)
        }
        else{
          localStorage.removeItem('integration')
        }
      }
    } catch (error) {
      console.log(error);
    }
    SetwelcomePopup({
      open: localStorage.getItem('integration') ? false : true,
      dimmer: undefined,
    })
  }

  useEffect(()=>{
    getDigitalSolutionStatus();
  },[])

  return (
    <>
      <Modal
        dimmer={welcomePopup.dimmer}
        open={welcomePopup.open}
        onClose={() => SetwelcomePopup({ open: true })}
        size='small'
      >
        <Modal.Content className='text-center p-2'>
          <div className=' px-5 px-sm-1'>
            <img className="w-100 px-3 px-sm-0" src="assets/images/welcomeModalImage.gif" alt='Welcome Popup' />
          </div>
          <h6 className="text-primary-light fs-5 fw-600">Welcome to 6Verifier</h6>
          <button className="ui button bg-primary-light fs-7 fw-400 mt-3 text-white" onClick={ValidateDigitalSolution}>Continue</button>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default WelcomePopup