import React, { useState, useEffect, useContext, useRef } from 'react'
import { Dropdown, Pagination, Input, Icon, Popup } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import WelcomePopup from '../components/WelcomePopup';
import { IntegrationService } from '../services/integrationService';
import { STATUS, EVENT_TYPE, ROW_OPTION, TosterVariant, tenantLocationOption, tenantTypeOption, tenantStatusOption } from "../constant/constant"
import { appContext } from '../context/appContext';
import moment from 'moment';

export default function EsignatureReport() {
  const [filterData, setFilterData] = useState({})
  const [tenantEsignDetails, settenantEsignDetails] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("");
  const [esignFilter, setEsignFilter] = useState({ location: "", tenant_status: "", tenant_type: "", esign_status: "", building_name: "", start_date: "", end_date: "" })
  const [locations, setLocations] = useState([])
  const [buildings, setBuildings] = useState([])
  const [buildingsDropdownValue, setBuildingsDropdownValue] = useState('')
  const initialCount = useRef(0);
  const reportsData = [
    {
      id: '1',
      location: 'Davis',
      buildingName: 'Berlim',
      unitNumber: '12',
      tenantName: 'Karthick ram',
      tenantType: 'Personal User',
      email: 'Karthick@gmail.com',
      mobileNumber: '98657634354',
      esignStatus: 'Success',
      esignedDate: '12/10/2022',
    },
    {
      id: '2',
      location: 'Woodland',
      buildingName: 'Alpha',
      unitNumber: '24',
      tenantName: 'Barath',
      tenantType: 'Personal User',
      email: 'Karthick@gmail.com',
      mobileNumber: '98657634354',
      esignStatus: 'Failed',
      esignedDate: '12/10/2022',
    }
  ]

  const tenantBuildingOption = [
    {
      key: 1,
      text: 'All',
      value: 'All'
    },
    {
      key: 2,
      text: 'Building1',
      value: 'Building1',
    },
  ]

  const esignatureStatusOption = [
    {
      key: 1,
      text: "All",
      value: "All",
    },
    {
      key: 2,
      text: "Signed",
      value: "SIGNED",
    },
    {
      key: 3,
      text: "Initiated",
      value: "INITIATED",
    },
    // {
    //   key: 4,
    //   text: "Failed",
    //   value: "FAILED",
    // },
    // {
    //   key: 5,
    //   text: "Expired",
    //   value: "EXPIRED",
    // },
  ]


  /*context*/
  const { setSpinnerVisibility, showToaster } = useContext(appContext)

  /* service */
  const integrationService = new IntegrationService();

  const integerationAPI = async (payloadData) => {
    try {
      // setSpinnerVisibility(true)
      const { data } = await integrationService.integration({
        event_type: EVENT_TYPE.getTenantEsignDetails,
        rows_per_page: rowsPerPage,
        page: activePage,
        search: search,
        ...filterData
      })
      // setSpinnerVisibility(false)
      if (data?.status === STATUS.success && data.body) {
        settenantEsignDetails(data?.body?.result)
        setTotalPages(data?.body?.pageCount)
        data.body.result.length === 0 && setActivePage(1)

      }

    } catch (error) {
      // setSpinnerVisibility(false)
      console.log("error", error);
      return error
    }
  }
  /* get location api */
  const getLocation = async () => {
    try {
      // setSpinnerVisibility(true)
      const { data } = await integrationService.integration({
        event_type: EVENT_TYPE.getLocation,
      })
      // setSpinnerVisibility(false)
      if (data?.status === STATUS.success && data.body) {
        let locationData = data?.body?.length > 1 ? [{
          key: 0,
          value: "All",
          text: "All"
        }] : [];
        data?.body?.map((location, index) => {
          if (location && location.name) {

            locationData.push({
              key: index + 1,
              value: location?.name,
              text: location?.name
            })
          }

        })
        setLocations(locationData)

      }
    } catch (error) {
      // setSpinnerVisibility(false)
      console.log("error", error);
    }
  }

  /* buildings api */

  /* get location api */
  const getBuildings = async () => {
    try {
      // setSpinnerVisibility(true)
      let buildingsPayload = {
        event_type: EVENT_TYPE.getBuilding,
      }

      if (esignFilter?.location) {
        buildingsPayload = { ...buildingsPayload, location: esignFilter?.location }
      }
      const { data } = await integrationService.integration({ ...buildingsPayload })
      // setSpinnerVisibility(false)
      if (data?.status === STATUS.success && data.body) {
        let buildingData = data?.body?.length > 1 ? [{
          key: 0,
          value: "All",
          text: "All"
        }] : [];
        data?.body?.filter((building, index) => {
          if (building && building?.name) {
            buildingData.push({
              key: index + 1,
              value: building?.name,
              text: building?.name
            })
          }

        })
        setBuildings(buildingData)

      }
    } catch (error) {
      // setSpinnerVisibility(false)
      console.log("error", error);
    }
  }
  /* Service End */

  const handlePaginationChange = (e, { activePage }) => { setActivePage(activePage) }
  const handleTotalRows = (e) => { setRowsPerPage(e.target.value) }
  const handleSearch = (e) => {
    if (e.target.value.length) {
      setSearch(e.target.value);
      setActivePage(1);
    }
    else setSearch("");
  }
  const handleFilter = (e, data) => {
    if (data?.name === "building_name") {
      setBuildingsDropdownValue(data?.value)
    }
    data.value === "All" ? setEsignFilter({ ...esignFilter, [data.name]: "" }) : setEsignFilter({ ...esignFilter, [data.name]: data.value })
    // setEsignFilter({ ...esignFilter, [data.name]: data.value })
  }

  const handleDateRange = (e, { value }) => {
    e.preventDefault()
    if (value) {
      if (value.length > 1) {
        setEsignFilter({ ...esignFilter, ["start_date"]: moment.utc(value[0]).format('YYYY-MM-DD HH:mm:ss'), ["end_date"]: moment.utc(new Date(moment(value[1]).format('YYYY-MM-DD 23:59:59')).toISOString()).format('YYYY-MM-DD HH:mm:ss') })
      }
      else {
        setEsignFilter({ ...esignFilter, ["start_date"]: moment.utc(value[0]).format('YYYY-MM-DD HH:mm:ss'), ["end_date"]: moment.utc(new Date(moment(new Date()).format('YYYY-MM-DD 23:59:59')).toISOString()).format('YYYY-MM-DD HH:mm:ss') })
      }
    }
    else {
      setEsignFilter({ ...esignFilter, ["start_date"]: "", ["end_date"]: "" })
    }
  }

  const handleFilterButtonTrigger = (e) => {
    e.preventDefault();
    setActivePage(1);
    setFilterData(esignFilter);
  }
  const initialCallWithloder = async () => {
    setSpinnerVisibility(true)
    await integerationAPI();
    setSpinnerVisibility(false)
  }

  useEffect(() => {
    if (initialCount.current) {
      integerationAPI()
    }
  }, [search])

  useEffect(() => {
    initialCallWithloder();
    initialCount.current = initialCount.current + 1
  }, [activePage, rowsPerPage, filterData])

  useEffect(() => {
    getLocation();
  }, [])

  useEffect(() => {
    getBuildings();
    setEsignFilter({ ...esignFilter, ["building_name"]: "" })
    if (buildings?.find((data) => data?.name === buildingsDropdownValue) === undefined) {
      setBuildingsDropdownValue('')
    }

  }, [esignFilter?.location])




  return (
    <div className='py-2'>
      <div className='mx-2'>
        <h2 className='text-white fs-6 mb-4 fw-300 d-flex align-items-center'>
          Reports
          <span className='fs-8 PageSub-title px-1 ml-1'>
            <img height='17' width='17' src='/assets/images/bank-id.svg' alt='Filter' />
            <span className='veritical-align-text-top pl-1'>E-Signature Report</span>
          </span>
        </h2>
        <div className='tenants-reports-div'>
          <div className='bg-white shadow border-radius-15 mb-3'>
            <h5 className='border-bottom py-3 px-3 fs-6 fw-500 text-primary-light'>E-SIGN VERIFICATION REPORT</h5>
            <div className='px-1 py-3'>
              <form class="ui form" >
                <div className='row'>
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2 mb-2'>
                    <div class="field">
                      <label className='fw-500 mb-1'>Select Location</label>
                      <Dropdown placeholder='Select Location' clearable fluid floating selection options={locations} forceSelection={false} selectOnBlur={false} name={"location"}
                        onChange={handleFilter} />

                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field">
                      <label className='fw-500 mb-1'>Select Building</label>
                      <Dropdown placeholder='Select Building' clearable fluid floating selection options={buildings} value={esignFilter?.building_name} forceSelection={false} selectOnBlur={false} name={"building_name"}
                        onChange={handleFilter} />

                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field">
                      <label className='fw-500 mb-1'>Tenant Type</label>
                      <Dropdown placeholder='Tenant Type' clearable fluid floating selection options={tenantTypeOption} name={"tenant_type"}
                        onChange={handleFilter} />

                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field">
                      <label className='fw-500 mb-1'>Tenant Status</label>
                      <Dropdown placeholder='Tenant Status' clearable fluid floating selection options={tenantStatusOption} forceSelection={false} selectOnBlur={false} name={"tenant_status"}
                        onChange={handleFilter} />

                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field">
                      <label className='fw-500 mb-1'>E-Signature Status</label>
                      <Dropdown placeholder='E-Signature Status' clearable fluid floating selection options={esignatureStatusOption} forceSelection={false} selectOnBlur={false} name={"esign_status"}
                        onChange={handleFilter} />

                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field datePicker">
                      <label className='fw-500 mb-1'>Choose Date</label>
                      <SemanticDatepicker placeholder='Choose Date' type="range" className='w-100' name={"start_date"} onChange={handleDateRange} />
                    </div>
                  </div>
                  {/* <div className='col-lg-3 col-md-6 col-sm-12 px-2 mb-2'>
                    <div class="field datePicker">
                      <label className='fw-500 mb-1'>To Date</label>
                      <SemanticDatepicker placeholder='Select To Date' className='w-100' name={"end_date"} onChange={handleFilter}/>
                    </div>
                  </div> */}
                  <div className='col-lg-3 col-md-6 col-sm-12 px-2'>
                    <label className='fs-7 fw-400 mb-1 d-block invisible'>Get Report</label>
                    <button class="ui button bg-primary text-white fs-7 fw-400" onClick={handleFilterButtonTrigger} >Get Report</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className='reports-table-div'>
            <div className='row pt-4 pb-2'>
              <div className='col-lg-6 col-md-6 col-sm-12 text-left text-sm-center mb-1'>
                <label className='show-entry py-1 px-1 border-radius-5 d-inline-block'>
                  Show
                  <select className='border-0 cursor-pointer outline-0' onChange={handleTotalRows}>
                    {ROW_OPTION.map((option) => {
                      return <option value={option}>{option}</option>
                    })}
                  </select>
                  entries
                </label>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 text-sm-center text-right mb-1'>
                <div>
                  <div>
                    <Input className='w-75' iconPosition='left' icon='search' placeholder='Search...' onChange={handleSearch} />
                  </div>
                </div>
              </div>
            </div>
            <div className='reportsTable-content bg-white shadow-sm border-radius-10 overflow-x-auto w-100'>
              <div className='reportsTable'>
                <table className='w-100 table-layout-fixed'>
                  <thead>
                    <tr>
                      <th className='text-left'>Tenant Details</th>
                      <th>Location</th>
                      <th>Building Name</th>
                      <th>Unit#</th>
                      <th>E-Sign Status</th>
                      <th>E-Signed Date</th>
                    </tr>
                  </thead>
                </table>
                <div className='ScrollBody overflow-y-auto'>
                  <table className='w-100 table-layout-fixed'>
                    <tbody>
                      {tenantEsignDetails?.length ? tenantEsignDetails?.map((reportItem) => {
                        return (
                          <tr>
                            <td ><div className="ml-2 text-left">
                              <Popup wide inverted size='tiny'
                                trigger={<span><p className="fs-6 fw-500 text-break">{(reportItem.first_name ? reportItem.first_name : '') + " " + (reportItem.last_name ? reportItem.last_name : "")}</p><p className='text-break'>{reportItem.email}</p><p>{reportItem.mobile}</p><a className='text-decoration-underline text-primary' href="/">{reportItem.tenantType}</a></span>}
                                content={<span><p className="fs-6 fw-500 text-break">{(reportItem.first_name ? reportItem.first_name : '') + " " + (reportItem.last_name ? reportItem.last_name : "")}</p><p className='text-break'>{reportItem.email}</p><p>{reportItem.mobile}</p><a className='text-decoration-underline text-primary' href="/">{reportItem.tenantType}</a></span>}
                                hideOnScroll
                              />
                              {/* <p className="fs-6 fw-500 text-break">{(reportItem.first_name ? reportItem.first_name : '') + " " + (reportItem.last_name ? reportItem.last_name : "")}</p>
                              <p className='text-break'>{reportItem.email}</p>
                              <p>{reportItem.mobile}</p>
                              <a className='text-decoration-underline text-primary' href="/">{reportItem.tenantType}</a> */}
                            </div></td>
                            <td className='text-center text-break'><p>{reportItem.location}</p></td>
                            <td className='text-center text-break'><p>{reportItem.building_name}</p></td>
                            <td className='text-center'>{reportItem.unit_number}</td>
                            <td className={`text-center `}><p>{reportItem?.esign_details?.esign_status ? reportItem?.esign_details?.esign_status : "N/A"}</p></td>
                            <td className='text-center'><p>{reportItem?.esign_details?.modified_on ? moment(reportItem?.esign_details?.modified_on).format("DD-MM-YYYY hh:mm") : "N/A"}</p></td>
                          </tr>
                        )
                      }) :
                        <tr>
                          <td align='center' colSpan={6}>
                            No Records
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="pagination-div mt-2 mb-3 text-center">
              <Pagination
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: (totalPages > 10)
                }}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                  disabled: (activePage === 1)
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                  disabled: (!totalPages || activePage === totalPages)
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true, disabled: (activePage === 1) }}
                nextItem={{
                  content: <Icon name="angle right" />,
                  icon: true,
                  disabled: (!totalPages || activePage === totalPages)
                }}
                onPageChange={handlePaginationChange}
                activePage={activePage}
                totalPages={totalPages}
                disabled={totalPages === 1}
              />
            </div>
          </div>
        </div>
        <WelcomePopup />
      </div>
    </div>
  )
}
