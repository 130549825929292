import React, { useEffect, useState } from 'react'
import WelcomePopup from '../components/WelcomePopup'
import { Dropdown, Image, Header } from 'semantic-ui-react';
import DoughnutChart from '../components/DoughnutChart';
import { BarChart } from '../components/BarChart';
import { sortUnitOptions, CardOptions, trigger } from "../constant/constant"

const CreditCheckDashboard = ({ dashboardData }) => {
    // const [dateHandle, setDateHandle] = useState()
    const [dateHandleCreditCheckSuccess, setDateHandleCreditCheckSuccess] = useState('all')
    const [dateHandleCreditCheckFailed, setDateHandleCreditCheckFailed] = useState('all')
    const [dateHandleCreditCheckSkipped, setDateHandleCreditCheckSkipped] = useState('all')
    const [dateHandleCreditCheckBarChart, setDateHandleCreditCheckBarChart] = useState('all')
    // const [date_filter, set_date_filter] = useState("All")
    let barChartData = {}
    let success = []
    let failed = []
    let initated = []
    let doughtnutChartData = {}

    if (dashboardData?.length  && dateHandleCreditCheckBarChart) {
        doughtnutChartData = {
            initiated: ((Object?.values(dashboardData?.find((data) => Object?.keys(data)[0] === dateHandleCreditCheckBarChart))[0])?.find((data) => data?.feature_event === "CREDITCHECK_SKIPPED")?.count )?? 0,
            success: ((Object?.values(dashboardData?.find((data) => Object?.keys(data)[0] === dateHandleCreditCheckBarChart))[0])?.find((data) => data?.feature_event === "CREDITCHECK_SUCCESS")?.count) ?? 0,
            failed: ((Object?.values(dashboardData?.find((data) => Object?.keys(data)[0] === dateHandleCreditCheckBarChart))[0])?.find((data) => data?.feature_event === "CREDITCHECK_FAILED")?.count) ?? 0
        }
    }

    const WidgetData = [
        {
            icon: '/assets/images/successful.svg',
            title: 'Credit Check Successful',
            amount: dateHandleCreditCheckSuccess && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === dateHandleCreditCheckSuccess))[0])?.find((data) => data?.feature_event === "CREDITCHECK_SUCCESS")?.count || 0 : 0,
            personalUserCount: dateHandleCreditCheckSuccess && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === `personal_user_${dateHandleCreditCheckSuccess}`))[0])?.find((data) => data?.feature_event === "CREDITCHECK_SUCCESS")?.count || 0 : 0,
            businessUserCount: dateHandleCreditCheckSuccess && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === `business_user_${dateHandleCreditCheckSuccess}`))[0])?.find((data) => data?.feature_event === "CREDITCHECK_SUCCESS")?.count || 0 : 0,
        },
        {
            icon: '/assets/images/failed.svg',
            title: 'Credit Check Failed',
            amount: dateHandleCreditCheckFailed && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === dateHandleCreditCheckFailed))[0])?.find((data) => data?.feature_event === "CREDITCHECK_FAILED")?.count || 0 : 0,
            day: 'Today',
            personalUserCount: dateHandleCreditCheckFailed && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === `personal_user_${dateHandleCreditCheckFailed}`))[0])?.find((data) => data?.feature_event === "CREDITCHECK_FAILED")?.count || 0 : 0,
            businessUserCount: dateHandleCreditCheckFailed && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === `business_user_${dateHandleCreditCheckFailed}`))[0])?.find((data) => data?.feature_event === "CREDITCHECK_FAILED")?.count || 0 : 0,
        },
        {
            icon: '/assets/images/locked-out.svg',
            title: 'Credit Check Skipped',
            amount: dateHandleCreditCheckSkipped && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === dateHandleCreditCheckSkipped))[0])?.find((data) => data?.feature_event === "CREDITCHECK_SKIPPED")?.count || 0 : 0,

            day: 'All',
            personalUserCount: dateHandleCreditCheckSkipped && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === `personal_user_${dateHandleCreditCheckSkipped}`))[0])?.find((data) => data?.feature_event === "CREDITCHECK_SKIPPED")?.count || 0 : 0,
            businessUserCount: dateHandleCreditCheckSkipped && dashboardData?.length ? (Object.values(dashboardData?.find((data) => Object?.keys(data)[0] === `business_user_${dateHandleCreditCheckSkipped}`))[0])?.find((data) => data?.feature_event === "CREDITCHECK_SKIPPED")?.count || 0 : 0,
        },
    ]


    dashboardData?.map((data) => {
        if (Object.keys(data)[0] === "last_five_months") {
            data[Object.keys(data)[0]].map((data1) => {
                if (data1.feature_event.includes("CREDITCHECK")) {
                    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                    ];

                    let month = monthNames[data1["Year-Month"].split("-")[1] - 1]
                    if (data1.feature_event.includes("SUCCESS")) {
                        success.push({ count: data1.Count, month: month })
                    }
                    else if (data1.feature_event.includes("FAILED")) {
                        failed.push({ count: data1.Count, month: month })
                    }
                    else if (data1.feature_event.includes("SKIPPED")) {
                        initated.push({ count: data1.Count, month: month })
                    }


                }
            })
            barChartData = { "initated": initated, "success": success, "failed": failed }
        }
    })


    const handleDropdownChange = (e, data, index) => {
        // setDateHandle(sortUnitOptions.find((option) => option.value === data.value).name)
        // set_date_filter(data.value)
        if(Number(index)===0){
            setDateHandleCreditCheckSuccess(sortUnitOptions.find((option) => option.value === data.value).name)  
        }
        if(Number(index)===1){
            setDateHandleCreditCheckFailed(sortUnitOptions.find((option) => option.value === data.value).name)  
        }
        if(Number(index)===2){
            setDateHandleCreditCheckSkipped(sortUnitOptions.find((option) => option.value === data.value).name)  
        }
        if(Number(index)===3){
            setDateHandleCreditCheckBarChart(sortUnitOptions.find((option) => option.value === data.value).name)  
        }
    }


    // useEffect(() => {
    //     setDateHandle("all")
    // }, [])


    return (
        <div className='py-2'>
            <div className='mx-2'>
                <h2 className='text-white fs-6 mb-4 fw-300 d-flex align-items-center'><span className='fs-8'><img height='17' width='17' src='/assets/images/dashboard.svg' alt='Filter' /><span className='veritical-align-text-top pl-1 fs-7'>Welcome To Dashboard</span></span></h2>
                <div className='widgets'>
                    <div className='row'>
                        {WidgetData?.map((data,index) => (
                            <div className='col-lg-4 col-md-4 col-sm-12 pr-1 pr-sm-0 px-sm-1 mb-2'>
                                <div style={{ borderRadius: '2px' }} className='bg-white shadow-sm py-2 mh-100 px-2'>
                                    <div className='row align-items-center'>
                                        <div className='col-lg-2 col-md-2 col-sm-2'>
                                            <div>
                                                <img className='w-75 h-75' src={data.icon} alt='Icon' />
                                            </div>
                                        </div>
                                        <div className='col-lg-10 col-md-10 col-sm-10'>
                                            <div className='d-flex align-items-center justify-content-between mb-1'>
                                                <p className='fs-7 text-gray fw-500 text-truncate'>{data.title}</p>
                                                <div className='widget-dropdown text-right'>
                                                    <Dropdown downward floating forceSelection={false}  selectOnBlur={false} options={CardOptions} trigger={trigger} icon="null" onChange={(e,data1)=>handleDropdownChange(e,data1,index)} />
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-start justify-content-between'>
                                                <div>
                                                    <h5 className='fs-4 fw-600 mb-1'>{data.amount}</h5>
                                                    <p className='font-8 text-dark'><span className='fs-9em'>Personal Users - {data.personalUserCount}</span><span className='fs-9em ml-2'>Business Users - {data.businessUserCount}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='row my-2'>
                    <div className='col-lg-6 col-md-6 col-sm-12 pr-1 pr-sm-0 px-sm-1 mb-2'>
                        <div className='bar-chart-div mh-100 bg-white shadow-sm border-radius-5'>
                            <h5 className='border-bottom py-3 px-3 fs-6 fw-500 text-dark'>Credit Check Status</h5>
                            <div className='p-5 p-sm-1'>
                                <BarChart dashboardData={barChartData} isCreditCheck={true}/>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 pr-1 pr-sm-0 px-sm-1 mb-2'>
                        <div className='doughnut-chart-div mh-100 bg-white shadow-sm border-radius-5'>
                            <div className='border-bottom py-3 px-3 d-flex align-items-center justify-content-between'>
                                <h5 className='fs-6 fw-500 text-dark'>Credit Check Status</h5>
                                <div className='sort-div text-right'>
                                    <Header as='h4'>
                                        <Header.Content>
                                            <Dropdown
                                                floating
                                                inline
                                                options={sortUnitOptions}
                                                // defaultValue={sortUnitOptions[0].value}
                                                forceSelection={false}  
                                                selectOnBlur={false}
                                                onChange={(e,data1)=>{handleDropdownChange(e,data1,3)}}
                                            />
                                        </Header.Content>
                                    </Header>
                                </div>
                            </div>
                            <div className='p-5 p-sm-1'>
                                <DoughnutChart dashboardData={doughtnutChartData} isCreditCheck={true} />
                            </div>
                        </div>
                    </div>

                </div>
                <WelcomePopup />
            </div>
        </div>
    )
}

export default CreditCheckDashboard