import React, { useState, useEffect, useContext, useRef } from "react";
import { Dropdown, Pagination, Input, Icon, Popup } from "semantic-ui-react";
import WelcomePopup from "../components/WelcomePopup";
import TenantsProfile from "./TenantsProfile";

import { IntegrationService } from '../services/integrationService';
import { STATUS, EVENT_TYPE, TosterVariant, ROW_OPTION, tenantLocationOption, tenantTypeOption, tenantStatusOption } from "../constant/constant"
import { appContext } from '../context/appContext';



export default function CreditCheckTenants(props) {
  const [filterData, setFilterData] = useState({})
  const [tenantCreditCheckDetails, setTenantCreditCheckDetails] = useState([])
  /* pagination and filter */
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("");
  const [creditCheckFilter, setCreditCheckFilter] = useState({ location: "", tenant_status: "", tenant_type: "", credit_check_status: "", credit_score_range: "" })
  const [showprofile, setShowProfile] = useState(false);
  const [tenantDetails, setTenantDetails] = useState({})
  // const [checkedAll, setCheckedAll] = useState(false);
  // const [checked, setChecked] = useState({
  //   chk: false,
  //   chk1: false,
  // });
  const [locations, setLocations] = useState([])
  const initialCount = useRef(0);

  const creditcheckVerfiedStatusOption = [
    {
      key: 1,
      text: "All",
      value: "All",
    },
    {
      key: 2,
      text: "Success",
      value: "SUCCESS",
    },
    // {
    //   key: 3,
    //   text: "Failed",
    //   value: "Failed",
    // },
    {
      key: 4,
      text: "Skipped",
      value: "SKIPPED",
    },
  ];
  const creditScorePersonalOption = [
    {
      id: 1,
      key: 1,
      text: "All",
      value: "All",
    },
    {
      id: 2,
      key: 2,
      text: "Excellent (71-100)",
      value: "71-100",
    },
    {
      id: 3,
      key: 3,
      text: "Good (51-70)",
      value: "51-70",
    },
    {
      id: 4,
      key: 4,
      text: "Average (30-50)",
      value: "30-50",
    },
    {
      id: 5,
      key: 5,
      text: "Poor (21-29)",
      value: "21-29",
    },
    {
      id: 6,
      key: 6,
      text: "Critical (1-20)",
      value: "1-20",
    },
  ];
  const creditScoreBusinessOption = [
    {
      id: 1,
      key: 1,
      text: "All",
      value: "All",
    },
    {
      id: 2,
      key: 2,
      text: "Good (AAA)",
      value: "AAA",
    },
    {
      id: 3,
      key: 3,
      text: "Satisfactory (AA)",
      value: "AA",
    },
    {
      id: 4,
      key: 4,
      text: "Acceptable (A)",
      value: "A",
    },
    {
      id: 5,
      key: 5,
      text: "Neutral (AN)",
      value: "AN",
    },
    {
      id: 6,
      key: 6,
      text: "Not rateables(NR)",
      value: " NR",
    },
    {
      id: 7,
      key: 7,
      text: "Unsatisfactory(B)",
      value: "B",
    },
    {
      id: 8,
      key: 8,
      text: "Weak (C)",
      value: "C",
    },
  ];

  const tenantsData = [
    {
      tenantsChecked: "chk",
      profileImage: "/assets/images/post-tenant-img.png",
      tenantName: "Alexendria Aliex",
      tenantEmail: "Alex@gmail.com",
      tenantNumber: "+91 79294548574",
      tenantType: "Business User",
      authenticationStatus: "Success",
      creditScore: "AAA",
    },
    {
      tenantsChecked: "chk1",
      profileImage: "/assets/images/post-tenant-img.png",
      tenantName: "Alexendria Aliex",
      tenantEmail: "Alex@gmail.com",
      tenantNumber: "+91 79294548574",
      tenantType: "Business User",
      authenticationStatus: "Failed",
      creditScore: "AA",
    },
  ];

  /*context*/
  const { setSpinnerVisibility, showToaster, setCheckedValue, checkedValue } = useContext(appContext)

  /* service */
  const integrationService = new IntegrationService();

  const integerationAPI = async (payloadData) => {
    try {
      const { data } = await integrationService.integration({
        event_type: EVENT_TYPE.getTenantCredidCheckDetails,
        rows_per_page: rowsPerPage,
        page: activePage,
        search: search,
        ...filterData
      })
      if (data?.status === STATUS.success && data.body) {
        setTenantCreditCheckDetails(data.body.result)
        setTotalPages(data.body.pageCount)
        data.body.result.length === 0 && setActivePage(1)

      }
    } catch (error) {
      console.log("error", error);
      return error
    }
  }
  const getLocation = async () => {
    try {
      // setSpinnerVisibility(true)
      const { data } = await integrationService.integration({
        event_type: EVENT_TYPE.getLocation,
      })
      // setSpinnerVisibility(false)
      if (data?.status === STATUS.success && data.body) {
        let locationData = data?.body?.length > 1 ? [{
          key: 0,
          value: "All",
          text: "All"
        }] : [];
        data?.body?.map((location, index) => {
          if (location && location.name) {

            locationData.push({
              key: index + 1,
              value: location?.name,
              text: location?.name
            })
          }

        })
        setLocations(locationData)

      }
    } catch (error) {
      // setSpinnerVisibility(false)
      console.log("error", error);
    }
  }
  /* Service End */

  // const selectAll = (value) => {
  //   setCheckedAll(value);
  //   setChecked((prevState) => {
  //     const newState = { ...prevState };
  //     for (const inputName in newState) {
  //       newState[inputName] = value;
  //     }
  //     return newState;
  //   });
  // };
  // const toggleCheck = (inputName) => {
  //   setChecked((prevState) => {
  //     const newState = { ...prevState };
  //     newState[inputName] = !prevState[inputName];
  //     return newState;
  //   });
  // };
  const handleCheckbox = (e, value) => {
    if (e.target.name === "currentPageCheck") {
      if (e.target.checked) {
        setCheckedValue(tenantCreditCheckDetails)
      }
      else {
        setCheckedValue([])
      }
    }
    else if (e.target.name === "checkedItem") {
      if (e.target.checked) {
        setCheckedValue((prev) => ([...prev, value]))
      }
      else {
        setCheckedValue((prev) => prev.filter((data) => JSON.stringify(data) !== JSON.stringify(value)))
      }
    }

  }

  const handlePaginationChange = (e, { activePage }) => { setActivePage(activePage) }
  const handleTotalRows = (e) => { setRowsPerPage(e.target.value) }
  const handleSearch = (e) => {
    if (e.target.value.length) {
      setSearch(e.target.value);
      setActivePage(1);
    }
    else setSearch("");
  }
  const handleFilter = (e, data) => {
    let filterValue = data.name === "tenant_type" ? { "credit_score_range": "" } : {}
    data.value === "All" ? (data?.name === "tenant_type" ? setCreditCheckFilter({ ...creditCheckFilter, ...filterValue, [data.name]: "All" }) : setCreditCheckFilter({ ...creditCheckFilter, ...filterValue, [data.name]: "" })) : setCreditCheckFilter({ ...creditCheckFilter, ...filterValue, [data.name]: data.value })

  }

  const handleFilterButtonTrigger = (e) => {
    e.preventDefault();
    setActivePage(1);
    setFilterData(creditCheckFilter);
  }


  // useEffect(() => {
  //   let allChecked = true;
  //   for (const inputName in checked) {
  //     if (checked[inputName] === false) {
  //       allChecked = false;
  //     }
  //   }
  //   if (allChecked) {
  //     setCheckedAll(true);
  //   } else {
  //     setCheckedAll(false);
  //   }
  // }, [checked]);

  const initialCallWithloder = async () => {
    setSpinnerVisibility(true)
    await integerationAPI();
    setSpinnerVisibility(false)
  }

  useEffect(() => {
    if (initialCount.current) {
      integerationAPI()
    }
  }, [search])

  useEffect(() => {
    initialCallWithloder();
    initialCount.current = initialCount.current + 1
  }, [activePage, rowsPerPage, filterData])

  useEffect(() => {
    getLocation();
    setCheckedValue([])
    return () => {
      setCheckedValue([])
    }
  }, [])

  return (
    <div className="py-2">
      <div className="mx-2">
        <h2 className="text-white fs-6 mb-4 fw-300 d-flex align-items-center">
          Tenants
          <span className="fs-8 PageSub-title px-1 ml-1">
            <img
              height="17"
              width="17"
              src="/assets/images/creditt-checkk.svg"
              alt="Filter"
            />
            <span className="veritical-align-text-top pl-1">
              Credit Check Filter
            </span>
          </span>
        </h2>
        {!showprofile && (
          <div className="tenants-filter-div">
            <div className="bg-white shadow border-radius-15 mb-3">
              <h5 className="border-bottom py-3 px-3 fs-6 fw-500 text-primary-light">
                CREDIT CHECK FILTER
              </h5>
              <div className="px-1 py-3">
                <form class="ui form">
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12 px-2 mb-2 mb-2">
                      <div class="field">
                        <label className="fw-500 mb-1">Select Location</label>
                        <Dropdown
                          placeholder="Select Location"
                          clearable
                          fluid
                          floating
                          selection
                          options={locations}
                          name={"location"}
                          forceSelection={false}
                          selectOnBlur={false}
                          onChange={handleFilter}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 px-2 mb-2">
                      <div class="field">
                        <label className="fw-500 mb-1">Tenant Status</label>
                        <Dropdown
                          placeholder="Tenant Status"
                          clearable
                          fluid
                          floating
                          selection
                          options={tenantStatusOption}
                          name={"tenant_status"}
                          forceSelection={false}
                          selectOnBlur={false}
                          onChange={handleFilter}
                        />

                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 px-2 mb-2">
                      <div class="field">
                        <label className="fw-500 mb-1">Tenant Type</label>
                        <Dropdown
                          placeholder="Tenant Type"
                          clearable
                          fluid
                          floating
                          selection
                          options={tenantTypeOption}
                          forceSelection={false}
                          selectOnBlur={false}
                          name={"tenant_type"}
                          onChange={handleFilter}
                        />

                      </div>
                    </div>

                    {/* <div className="col-lg-3 col-md-6 col-sm-12 px-2 mb-2">
                      <div class="field">
                        <label className="fw-500 mb-1">
                          Credit Check Status
                        </label>
                        <Dropdown
                          placeholder="Credit Check verified status"
                          clearable
                          fluid
                          floating
                          selection
                          disabled={creditCheckFilter?.tenant_status=== "INACTIVE" || creditCheckFilter?.location === ""}
                          options={creditcheckVerfiedStatusOption}
                          name={"credit_check_status"}
                          onChange={handleFilter}
                        />
                        <p className='text-red'>{creditCheckFilter?.location==="" ? "*please select location" : ""}</p>
                      </div>
                    </div> */}
                    <div className="col-lg-3 col-md-6 col-sm-12 px-2 mb-2">
                      <div class="field">
                        <label className="fw-500 mb-1">Credit Score</label>
                        <Dropdown
                          placeholder="Credit Score"
                          clearable
                          fluid
                          floating
                          selection
                          forceSelection={false}
                          selectOnBlur={false}
                          disabled={creditCheckFilter?.tenant_type === ''}
                          options={creditCheckFilter?.tenant_type === 'BUSINESS_USER' ? creditScoreBusinessOption : creditScorePersonalOption}
                          name={"credit_score_range"}
                          onChange={handleFilter}
                        />

                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 px-2">
                      <label className="fw-500 mb-1 d-block invisible">
                        Apply
                      </label>
                      <button
                        class="ui button bg-primary text-white px-4 fs-7 fw-400"
                        onClick={handleFilterButtonTrigger}

                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="tenants-table-div">
              <div className="row pt-4 pb-2">
                <div className="col-lg-6 col-md-6 col-sm-12 text-left text-sm-center mb-1">
                  <label className="show-entry py-1 px-1 border-radius-5 d-inline-block">
                    Show
                    <select className="border-0 cursor-pointer outline-0" onChange={handleTotalRows}>
                      {ROW_OPTION.map((option) => {
                        return <option value={option}>{option}</option>
                      })}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-sm-center text-right mb-1">
                  <div>
                    <div>
                      <Input
                        className="w-75"
                        iconPosition="left"
                        icon="search"
                        placeholder="Search..."
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tenantsTable-content bg-white shadow-sm border-radius-10 overflow-x-auto w-150">
                <div className="tenantsTable">
                  <table className='w-100 table-layout-fixed'>
                    <thead>
                      <tr>
                        {/*  'DONT DELETE' ===> there is no functinality added to the checkbox leter we can uncomment this*/}

                        {/* <th className="text-center w-10">
                          <input
                            type="checkbox"
                            // onChange={(event) =>
                            //   selectAll(event.target.checked)
                            // }
                            name="currentPageCheck"
                            onChange={(e)=>handleCheckbox(e)}
                            checked={tenantCreditCheckDetails?.length && tenantCreditCheckDetails?.every((data)=>checkedValue?.some((data1)=>JSON.stringify(data1)===JSON.stringify(data)))}
                          />
                        </th> */}
                        <th className="text-left" >CUSTOMERS</th>
                        <th className="text-center">UNIT INFORMATION</th>
                        <th className="text-center">TENANT TYPE</th>
                        <th className="text-left">CREDIT CHECK STATUS</th>
                        <th className="text-center">CREDIT SCORE</th>

                      </tr>
                    </thead>
                  </table>
                  <div className="ScrollBody overflow-y-auto">
                    <table className="w-100 table-layout-fixed" textAlign='left'>
                      <tbody>
                        {tenantCreditCheckDetails?.length ? tenantCreditCheckDetails?.map((tenant_details, index) => {
                          return (
                            <tr className={`rowField ${index}`}>
                              {/*  'DONT DELETE' ===> there is no functinality added to the checkbox leter we can uncomment this*/}

                              {/* <td className="text-center w-10">
                                <input
                                  type="checkbox"
                                  name="checkedItem"
                                  // onChange={() =>
                                  //   toggleCheck(tenant_details?.tenantsChecked)
                                  // }
                                  onChange={(e) => handleCheckbox(e,tenant_details)}
                                  checked={checkedValue?.some((data)=>JSON?.stringify(data)===JSON?.stringify(tenant_details))}
                                />
                              </td> */}
                              <td className="text-left">
                                <div className="d-flex justify-content-left align-items-start cursor-pointer" onClick={() => { setTenantDetails(tenant_details); setShowProfile(true) }}>
                                  <div className="tenants-profile">
                                    <img
                                      src={tenant_details?.photo_path || '/assets/images/dummy-profile-pic.jpg'}
                                      alt="Profile"
                                      rounded
                                    />
                                  </div>
                                  <div className="ml-2 text-left min-width-0">
                                    <Popup wide inverted size='tiny'
                                      trigger={<span><p className="fs-6 fw-500 text-break">{(tenant_details?.first_name ? tenant_details?.first_name : '') + ' ' + (tenant_details?.last_name ? tenant_details?.last_name : '')}</p><p className="text-break">{tenant_details?.email}</p><p>{tenant_details?.mobile}</p></span>}
                                      content={<span><p className="fs-6 fw-500 text-break">{(tenant_details?.first_name ? tenant_details?.first_name : '') + ' ' + (tenant_details?.last_name ? tenant_details?.last_name : '')}</p><p className="text-break">{tenant_details?.email}</p><p>{tenant_details?.mobile}</p></span>}
                                      hideOnScroll
                                    />
                                    {/* <p
                                      className="fs-6 fw-500 text-break"
                                    >
                                      {(tenant_details?.first_name ? tenant_details?.first_name : '') + ' ' + (tenant_details?.last_name ? tenant_details?.last_name : '')}
                                    </p>
                                    <p className="text-break">{tenant_details?.email}</p>
                                    <p>{tenant_details?.mobile}</p> */}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="ml-2 fs-7 text-center">
                                  <p className="text-break"
                                  >
                                    {tenant_details?.unit_number}
                                  </p>
                                  <p className="text-break">{tenant_details?.building_name}</p>
                                  <p className="text-break">{tenant_details?.location}</p>
                                </div>
                              </td>

                              <td className=" w-16 text-center">
                                <p>{tenant_details.business_user ? "Business User" : "Personal User"}</p>
                              </td>
                              <td className="text-center">
                                {tenant_details?.credit_check_details ? (tenant_details?.credit_check_details?.is_creditcheck_skipped ? "Skipped" : "Verified") : "N/A"}
                                {/* <label
                                  className={`${tenant_details?.authenticationStatus ===
                                    "Success"
                                    ? "label-success"
                                    : "label-danger"
                                    }`}
                                >
                                  {tenant_details?.authenticationStatus}
                                </label> */}
                              </td>
                              <td className="text-center">
                                <p>{tenant_details?.credit_check_details?.credit_score ?? "N/A"}</p>
                              </td>
                            </tr>
                          );
                        }) :
                          <tr>
                            <td align='center' colSpan={6}>
                              No Records
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="pagination-div mt-2 mb-3 text-center">
                <Pagination
                  ellipsisItem={{
                    content: <Icon name="ellipsis horizontal" />,
                    icon: (totalPages > 10)
                  }}
                  firstItem={{
                    content: <Icon name="angle double left" />,
                    icon: true,
                    disabled: (activePage === 1)
                  }}
                  lastItem={{
                    content: <Icon name="angle double right" />,
                    icon: true,
                    disabled: (!totalPages || activePage === totalPages)
                  }}
                  prevItem={{ content: <Icon name="angle left" />, icon: true, disabled: (activePage === 1) }}
                  nextItem={{
                    content: <Icon name="angle right" />,
                    icon: true,
                    disabled: (!totalPages || activePage === totalPages)
                  }}
                  onPageChange={handlePaginationChange}
                  activePage={activePage}
                  totalPages={totalPages}
                  disabled={totalPages === 1}
                />
              </div>
            </div>
          </div>
        )}
        {showprofile && (
          <TenantsProfile onProfile={() => setShowProfile(false)} tenantCreditCheckDetailsProp={tenantDetails} creditcheck={props?.creditcheck} />
        )}
        <WelcomePopup />
      </div>
    </div>
  );
}
