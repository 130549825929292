import React, {useState, useContext, useEffect } from "react";
import { Checkbox, Dropdown, Input } from "semantic-ui-react";
import WelcomePopup from "../components/WelcomePopup";
import { useForm } from "react-hook-form"
import { IntegrationService } from "../services/integrationService";
import { STATUS, EVENT_TYPE, TosterVariant } from "../constant/constant"
import { appContext } from "../context/appContext";

export default function SettingsCreditCheck() {

    const { setValue, register, handleSubmit, formState: { errors }, trigger, watch, setError, clearErrors } = useForm({
        defaultValues: {
            credit_check_validity: "0",
            credit_check_for_existing_clients: false,
            is_enabled_in_booking_portal: false,
            is_enabled_in_management_software: false,
            booking_portal_for_person: false,
            booking_portal_for_business: false,
            management_software_for_person: false,
            management_software_for_business: false
        }
    }
    )
    // const [agreement1, setAgreement1] = useState(true);
    // const [agreement2, setAgreement2] = useState(true);
    // const [agreement3, setAgreement3] = useState(true);
    // const [agreement4, setAgreement4] = useState(true);
    

    /*context*/
    const { setSpinnerVisibility, showToaster } = useContext(appContext)

    const [openDropdown, SetOpenDropdown] = useState(false)
    const [credit_check_limit_for_business_user_arr,set_credit_check_limit_for_business_user_arr]=useState([])
    const [credit_check_limit_for_personal_user_arr,set_credit_check_limit_for_personal_user_arr]=useState([])
    const [credit_check_limit_for_personal_user,set_credit_check_limit_for_personal_user]=useState("100-100")
    const [credit_check_limit_for_business_user_rating,set_credit_check_limit_for_business_user_rating]=useState(101)
    const [credit_check_limit_for_business_user,set_credit_check_limit_for_business_user]=useState("AAA-AAA")

    const creditScorePersonalOption = [
        {
            key: 1,
            text: "Bisnode",
            value: "Bisnode",
        },
    ];
    const creditScoreRating = [
        {
            key: 1,
            title: "Excellent (71-100)",
            value: "Excellent",
            additionalValue:"71-100",
        },
        {
            key: 2,
            title: "Good (51-70)",
            value: "Good",
            additionalValue:"51-100",
        },
        {
            key: 3,
            title: "Average (30-50)",
            value: "Average",
            additionalValue:"30-100",
        },
        {
            key: 4,
            title: "Poor (21-29)",
            value: "Poor",
            additionalValue:"21-100",
        },
        {
            key: 5,
            title: "Critical (1-20)",
            value: "Critical",
            additionalValue:"1-100",
        },
    ];
    const creditScoreBusinessOption = [
        {
            id: 1,
            key: 1,
            title: "Good (AAA)",
            value: "Good",
            additionalValue:"AAA-AAA",
            rating:100,
        },
        {
            id: 2,
            key: 2,
            title: "Satisfactory (AA)",
            value: "Satisfactory",
            additionalValue:"AA-AAA",
            rating:80,
        },
        {
            id: 3,
            key: 3,
            title: "Acceptable (A)",
            value: "Acceptable",
            additionalValue:"A-AAA",
            rating:60,
        },
        {
            id: 4,
            key: 4,
            title: "Neutral (AN)",
            value: "Neutral",
            additionalValue:"AN-AAA",
            rating:50
        },
        {
            id: 5,
            key: 5,
            title: "Not rateables(NR)",
            value: " NotRateables",
            additionalValue:"NR-AAA",
            rating:30
        },
        {
            id: 6,
            key: 6,
            title: "Unsatisfactory(B)",
            value: "Unsatisfactory",
            additionalValue:"B-AAA",
            rating:20,
        },
        {
            id: 7,
            key: 7,
            title: "Weak (C)",
            value: "Weak",
            additionalValue:"C-AAA",
            rating: 0  
         },
    ];
    const BisnodeOrCreditsafe = [
        {
            key: 1,
            text: "Bisnode",
            value: "Bisnode",
        },
    ];

    /* service */
    const integrationService = new IntegrationService();

    const integerationAPI = async (payloadData) => {
        try {
            setSpinnerVisibility(true)
            const { data } = await integrationService.integration({
                ...payloadData
            })
            setSpinnerVisibility(false)
            return data
        } catch (error) {
            setSpinnerVisibility(false)
            showToaster(TosterVariant.failed , "")
            console.log("error", error);
            return error
        }
    }
    /* Service End */

    /* submit handler for form */
    const onSubmit = async (formData) => {
        try {
            const payload = {
                event_type: EVENT_TYPE.upsertCreditCheckSettings,
                country_code: "NOR",
                integrated_with: "signicat",
                is_enabled_in_management_software: formData.is_enabled_in_management_software,
                enable_in_booking_portal_for: (formData.booking_portal_for_person && formData.booking_portal_for_business) ? "BOTH" : (formData.booking_portal_for_person ? "PERSONAL" : formData.booking_portal_for_business ? "BUSINESS" : ""),
                is_enabled_in_booking_portal: formData.is_enabled_in_booking_portal,
                enable_in_management_software_for: (formData.management_software_for_person && formData.management_software_for_business) ? "BOTH" : (formData.management_software_for_person ? "PERSONAL" : formData.management_software_for_business ? "BUSINESS" : ""),
                credit_check_validity: formData.credit_check_validity,
                credit_check_for_existing_clients: formData.credit_check_for_existing_clients,
                credit_check_limit_for_personal_user:(!credit_check_limit_for_personal_user || credit_check_limit_for_personal_user === "100-100") ? "0-100" : credit_check_limit_for_personal_user ,
                credit_check_limit_for_business_user:(!credit_check_limit_for_business_user || credit_check_limit_for_business_user === "AAA-AAA") ? "AAA-AAA" : credit_check_limit_for_business_user,
            }
            if ( watch("credit_check_validity") && watch("credit_check_validity")>=0 && watch("credit_check_validity") <= 24) {
                clearErrors('credit_check_validity')
            }
            else {
                setError('credit_check_validity', { type: 'custom', message: 'Value range must be 0 to 24' })
                return 
            }       

            const response = await integerationAPI(payload)
            if (response.status === STATUS.success) {
                showToaster(TosterVariant.success, "Settings saved successfully")
            }
        } catch (error) {
            showToaster(TosterVariant.failed, "Failed to Save Credit Check Settings")
        }

    }
    /* getting the datas of credit check Settings */
    const getCreditCheckSettings = async () => {
        try {
            const payload = {
                event_type: EVENT_TYPE.getCreditCheckSettings,
                country_code: "NOR",
                integrated_with: "signicat"
            }
            const data = await integerationAPI(payload);
            if (data?.status === STATUS.success) {
                setValue("is_enabled_in_management_software", data.body.is_enabled_in_management_software)
                setValue("is_enabled_in_booking_portal", data.body.is_enabled_in_booking_portal)
                setValue("credit_check_for_existing_clients", data.body.credit_check_for_existing_clients)
                setValue("credit_check_validity", data.body.credit_check_validity)
                setValue("management_software_for_business", (data?.body?.enable_in_management_software_for ==="BOTH" || data?.body?.enable_in_management_software_for ==="BUSINESS" ) ? true : false )
                setValue("management_software_for_person", (data?.body?.enable_in_management_software_for ==="BOTH" || data?.body?.enable_in_management_software_for ==="PERSONAL" ) ? true : false )
                setValue("booking_portal_for_business", (data?.body?.enable_in_booking_portal_for === "BOTH" || data?.body?.enable_in_booking_portal_for === "BUSINESS" ) ? true : false )
                setValue("booking_portal_for_person", (data?.body?.enable_in_booking_portal_for === "BOTH" || data?.body?.enable_in_booking_portal_for === "PERSONAL") ? true : false )
                set_credit_check_limit_for_personal_user( data?.body?.credit_check_limit_for_personal_user);
                set_credit_check_limit_for_business_user(data?.body?.credit_check_limit_for_business_user);
                set_credit_check_limit_for_business_user_rating((creditScoreBusinessOption?.find((option)=>option?.additionalValue === data?.body?.credit_check_limit_for_business_user))?.rating)
            }
        } catch (error) {
            showToaster(TosterVariant.failed, "fetch credit check settings details failed")
            console.log(error);
        }
    }

    /* onChange function for checkbox */
    const handleCheckedChange = (e, { name, checked }) => {
        setValue(name, checked);
        trigger(name);
    };

    // const handleChanged1 = (event) => {
    //     setAgreement1(event.target.checked);
    //   }
    //   const handleChanged2 = (event1) => {
    //     setAgreement2(event1.target.checked);
    //   }
    //   const handleChanged3 = (event2) => {
    //     setAgreement3(event2.target.checked);
    //   }
    //   const handleChanged4 = (event3) => {
    //     setAgreement4(event3.target.checked);
    //   }
     




    const handleCheckedChange2 = (e, { name, checked }) => {
        let value=Number(name.split("-")[0])
        if(checked){
            credit_check_limit_for_personal_user_arr.push(value)
            if(value < Number((credit_check_limit_for_personal_user ? credit_check_limit_for_personal_user : "100-100" )?.split("-")[0]) ){
                set_credit_check_limit_for_personal_user(name)
            }
        }
        else{
            let smallest=100;
            for (let i=0;i<credit_check_limit_for_personal_user_arr.length;i++){
                if(value!==credit_check_limit_for_personal_user_arr[i]){
                    smallest=smallest < credit_check_limit_for_personal_user_arr[i] ? smallest : credit_check_limit_for_personal_user_arr[i]
                }
            }
            set_credit_check_limit_for_personal_user(name)
            set_credit_check_limit_for_personal_user((creditScoreRating[Number(creditScoreRating?.findIndex((data)=>data?.additionalValue===name))-1])?.additionalValue)
            set_credit_check_limit_for_personal_user_arr(credit_check_limit_for_personal_user_arr.filter((data)=>data!==value))
            }
        
    };
    const handleCheckedChange1 = (e, { name, checked,rating }) => {
        if(checked){
            
            credit_check_limit_for_business_user_arr.push(rating)
            if(rating < credit_check_limit_for_business_user_rating ? credit_check_limit_for_business_user_rating : 101){
                set_credit_check_limit_for_business_user_rating(rating)
                set_credit_check_limit_for_business_user(name)
            }
        }
        else{
            let smallest=100;
            for (let i=0;i<credit_check_limit_for_business_user_arr.length;i++){
                if(rating!==credit_check_limit_for_business_user_arr[i]){
                    smallest=smallest < credit_check_limit_for_business_user_arr[i] ? smallest : credit_check_limit_for_business_user_arr[i]
                }
            }
            set_credit_check_limit_for_business_user_rating((creditScoreBusinessOption[Number(creditScoreBusinessOption?.findIndex((data)=>(data.rating)===rating))-1])?.rating)
            set_credit_check_limit_for_business_user((creditScoreBusinessOption[Number(creditScoreBusinessOption?.findIndex((data)=>(data.rating)===rating))-1])?.additionalValue)
            set_credit_check_limit_for_business_user_arr(credit_check_limit_for_business_user_arr?.filter((data)=>data!==rating))
            }

    };


    /* onChange function for simaticUI inputfield */
    const handlValueChange = (e, { name, value }) => {
        if (!isNaN(value)) {
            console.log(value);
            if (value && value >= 0 && value <= 24) {
                clearErrors(name)
                setValue(name, value);
                trigger(name);
            }
            else {
                setError(name, { type: 'custom', message: 'Value range must be 0 to 24' })
                setValue(name, value);
                trigger(name);
            }
        }

    };
    useEffect(()=>{
        if(watch("is_enabled_in_management_software") && !(watch("management_software_for_business") || watch("management_software_for_person"))){
           setError('is_enabled_in_management_software' , { type: 'custom', message: '*Please select atleast one option' })
           return
       }
       else{
           clearErrors('is_enabled_in_management_software')
       }

       if(watch("is_enabled_in_booking_portal") && !(watch("booking_portal_for_person") || watch("booking_portal_for_business"))){
           setError('is_enabled_in_booking_portal' , { type: 'custom', message: '*Please select atleast one option' })
           return
       }
       else{
           clearErrors('is_enabled_in_booking_portal')
       }
    },[watch("is_enabled_in_management_software") ,watch("management_software_for_business"),watch("management_software_for_person"),watch("is_enabled_in_booking_portal") , watch("booking_portal_for_person"), watch("booking_portal_for_business")])

    useEffect(() => {
        getCreditCheckSettings();
    }, []) 

    return (
        <div className="py-2">
            <div className="mx-2">
                <h2 className="text-white fs-6 mb-4 fw-300 d-flex align-items-center">
                    Settings
                    <span className="fs-8 PageSub-title px-1 ml-1">
                        <img
                            height="17"
                            width="17"
                            src="/assets/images/creditt-checkk.svg"
                            alt="Filter"
                        />
                        <span className="veritical-align-text-top pl-1">
                            Credit Check Settings
                        </span>
                    </span>
                </h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="bg-white shadow border-radius-15">
                        <h5 className="border-bottom py-3 px-3 fs-6 fw-500 text-primary-light">
                            CREDIT CHECK FEATURE
                        </h5>
                        <div className="px-3 py-3">
                            <Checkbox
                                className="mb-3 fs-7"
                                toggle
                                label="Enable credit check feature in Management Software"
                                name="is_enabled_in_management_software"
                                checked={watch("is_enabled_in_management_software")}
                                onChange={handleCheckedChange}

                            />
                            {watch("is_enabled_in_management_software") && <div className="col-md-10 col-sm-12">
                                <div className="row">
                                    <div className="col-lg-2 col-md-4 col-sm-12">
                                        <div className="d-flex align-items-center my-sm-2">
                                            <input id='ccPersonalUserSoftware' type="checkbox" name="agreement" className="mr-1" checked={watch("management_software_for_person")} {...register("management_software_for_person")} />
                                            <label htmlFor='ccPersonalUserSoftware'>Personal User</label>
                                           
                                        </div>
                                        
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12">
                                        <div className="d-flex align-items-center my-sm-2">
                                            <input id='ccBusinessUserSoftware' type="checkbox" className="mr-1" checked={watch("management_software_for_business")}  {...register("management_software_for_business")} />
                                            <label htmlFor='ccBusinessUserSoftware'>Business/Corporate user</label>
                                        </div>
                                    </div>
                                </div>
                                {/* <p className="text-red">{(!agreement1 && !agreement2) ? "*please select atleast one option " : ""}</p> */}
                                <p className="text-red">{errors?.is_enabled_in_management_software ? "*Please select atleast one option " : ""}</p>
                               
                            </div>}
                        </div>

                        <div className="px-3 py-3">
                            <Checkbox
                                className="mb-3 fs-7"
                                toggle
                                label="Enable credit check feature in Booking Portal"
                                name="is_enabled_in_booking_portal"
                                checked={watch("is_enabled_in_booking_portal")}
                                onChange={handleCheckedChange}
                            />
                           {watch("is_enabled_in_booking_portal") && <div className="col-md-10 col-sm-12">
                                <div className="row">
                                    <div className="col-lg-2 col-md-4 col-sm-12">
                                        <div className="d-flex align-items-center my-sm-2">
                                        <input id='ccPersonalUserBookingPortal' type="checkbox" className="mr-1" checked={watch("booking_portal_for_person")} {...register("booking_portal_for_person")} />
                                            <label htmlFor='ccPersonalUserBookingPortal'>Personal User</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12">
                                        <div className="d-flex align-items-center my-sm-2">
                                        <input id='ccBusinessUserBookingPortal' type="checkbox" className="mr-1" checked={watch("booking_portal_for_business")} {...register("booking_portal_for_business")} />
                                            <label htmlFor='ccBusinessUserBookingPortal'>Business/Corporate user</label>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-red">{errors?.is_enabled_in_booking_portal ? "*Please select atleast one option " : ''}</p>
                            </div>}
                        </div>
                        <div className="px-3 py-3">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12 px-2 mb-2 mb-0 mt-1">
                                    <div class="field">
                                        <span className="mt-3 fw-500">
                                            Credit check for Personal User
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 px-2 mb-2 ">
                                    <div class="field">
                                        <Dropdown
                                            placeholder="Bisnode"
                                        mini
                                            clearable
                                            fluid
                                            floating
                                            selection
                                            options={BisnodeOrCreditsafe}
                                            defaultValue = {BisnodeOrCreditsafe[0]}
                                            
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12 px-2 mb-2 mb-0 mt-1">
                                    <div class="field">
                                        <span className="mt-3 fw-500">
                                            Credit check for Business User
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 px-2 mb-2 ">
                                    <div class="field">
                                        <Dropdown
                                            placeholder="Bisnode"
                                        mini    
                                            clearable
                                            fluid
                                            floating
                                            selection
                                            options={BisnodeOrCreditsafe}
                                            defaultValue = {BisnodeOrCreditsafe[0]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white shadow border-radius-15 mt-2">
                        <h5 className="border-bottom py-3 px-3 fs-6 fw-500 text-primary-light">
                            CREDIT CHECK PERFORMANCE
                        </h5>
                        <div className="px-3 py-3" >
                            <div>
                                <span className="mr-2"> The credit checks will be limited to performing more than once within</span>
                              <div className="inputField inlineblockText"> 
                                <Input
                                    label="Hrs"
                                    size="mini"
                                    labelPosition="right"
                                    placeholder="hours"
                                    maxLength="2"
                                    name="credit_check_validity"
                                    value={watch("credit_check_validity")}
                                    onChange={handlValueChange}
                                />
                                  <div className='errorText'>
                                {errors?.credit_check_validity && <label className='w-30 text-nowrap fw-500 mb-sm-2'>*{errors?.credit_check_validity?.message}</label>}
                            </div> 
                            </div>
                                <span className="ml-2">
                                 hours of the previous credit check.
                                </span>
                             
                            </div>
                          
                            <div className="d-flex justify-content-start mb-3 mt-2">
                                <p className="border-dashed-purple border-radius-5 bg-light-purple d-flex align-items-center p-1 fs-8">
                                    <svg
                                        className="min-width-1"
                                        id="warning-svgrepo-com_1_"
                                        data-name="warning-svgrepo-com (1)"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 43 43"
                                    >
                                        <path
                                            id="Path_15052"
                                            data-name="Path 15052"
                                            d="M20.717,2A18.717,18.717,0,1,0,39.433,20.716,18.717,18.717,0,0,0,20.717,2Zm0,34.759A16.043,16.043,0,1,1,36.759,20.717,16.043,16.043,0,0,1,20.717,36.759Z"
                                            transform="translate(0.674 0.674)"
                                            fill="none"
                                        />
                                        <rect
                                            id="_Transparent_Rectangle_"
                                            data-name="&lt;Transparent Rectangle&gt;"
                                            width="43"
                                            height="43"
                                            fill="none"
                                        />
                                        <circle
                                            id="Ellipse_3"
                                            data-name="Ellipse 3"
                                            cx="17"
                                            cy="17"
                                            r="17"
                                            transform="translate(4 4)"
                                            fill="#ffe99b"
                                        />
                                        <g
                                            id="Group_7137"
                                            data-name="Group 7137"
                                            transform="translate(19.385 9.829)"
                                        >
                                            <rect
                                                id="Rectangle_1690"
                                                data-name="Rectangle 1690"
                                                width="3"
                                                height="16"
                                                transform="translate(0.615 0.171)"
                                                fill="#ffc700"
                                            />
                                            <path
                                                id="Path_15053"
                                                data-name="Path 15053"
                                                d="M16.505,22a2.005,2.005,0,1,0,2.005,2.005A2.005,2.005,0,0,0,16.505,22Z"
                                                transform="translate(-14.5 -2.417)"
                                                fill="#ffc700"
                                            />
                                        </g>
                                    </svg>
                                    <span className="ml-1 fw-500">
                                        You can only perform a credit check once within X hours of the previous time.
                                    </span>
                                </p>
                            </div>
                            {/* <Checkbox
                                className="mb-3 fs-7"
                                toggle
                                label="Do you want to check the credit check for existing customers"
                                name="credit_check_for_existing_clients"
                                checked={watch("credit_check_for_existing_clients")}
                                onChange={handleCheckedChange}
                            />
                            <div className="d-flex justify-content-start mb-3">
                                <p className="border-dashed-purple border-radius-5 bg-light-purple d-flex align-items-center p-1 fs-8">
                                    <svg
                                        className="min-width-1"
                                        id="warning-svgrepo-com_1_"
                                        data-name="warning-svgrepo-com (1)"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 43 43"
                                    >
                                        <path
                                            id="Path_15052"
                                            data-name="Path 15052"
                                            d="M20.717,2A18.717,18.717,0,1,0,39.433,20.716,18.717,18.717,0,0,0,20.717,2Zm0,34.759A16.043,16.043,0,1,1,36.759,20.717,16.043,16.043,0,0,1,20.717,36.759Z"
                                            transform="translate(0.674 0.674)"
                                            fill="none"
                                        />
                                        <rect
                                            id="_Transparent_Rectangle_"
                                            data-name="&lt;Transparent Rectangle&gt;"
                                            width="43"
                                            height="43"
                                            fill="none"
                                        />
                                        <circle
                                            id="Ellipse_3"
                                            data-name="Ellipse 3"
                                            cx="17"
                                            cy="17"
                                            r="17"
                                            transform="translate(4 4)"
                                            fill="#ffe99b"
                                        />
                                        <g
                                            id="Group_7137"
                                            data-name="Group 7137"
                                            transform="translate(19.385 9.829)"
                                        >
                                            <rect
                                                id="Rectangle_1690"
                                                data-name="Rectangle 1690"
                                                width="3"
                                                height="16"
                                                transform="translate(0.615 0.171)"
                                                fill="#ffc700"
                                            />
                                            <path
                                                id="Path_15053"
                                                data-name="Path 15053"
                                                d="M16.505,22a2.005,2.005,0,1,0,2.005,2.005A2.005,2.005,0,0,0,16.505,22Z"
                                                transform="translate(-14.5 -2.417)"
                                                fill="#ffc700"
                                            />
                                        </g>
                                    </svg>
                                    <span className="ml-1 fw-500">
                                        You can check existing customer's credit score
                                    </span>
                                </p>
                            </div> */}
                        </div>
                    </div>
                    <div className="bg-white shadow border-radius-15 mt-2">
                        <h5 className="border-bottom py-3 px-3 fs-6 fw-500 text-primary-light">
                            CREDIT SCORE
                        </h5>
                        <div className="px-3 py-3">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 px-2 mb-2 mb-0 mt-1">
                                    <div class="field">
                                        <span className="mt-3 fw-500">
                                            Allow customers to move-in with the credit score for
                                            personal user
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 px-2 mb-2 ">
                                    <div class="field">
                                        <Dropdown className="CheckDropdown" onMouseLeave={()=>SetOpenDropdown(false)} simple={openDropdown} onClick={()=>SetOpenDropdown(true)} fluid item placeholder='Credit Score'>
                                            <Dropdown.Menu>
                                                {creditScoreRating.map(({     id, title,additionalValue}) => (
                                                    <Dropdown.Item key={id}>
                                                        <Checkbox label={title}
                                                        name={additionalValue} 
                                                        checked={Number(additionalValue?.split("-")[0]) >= Number(credit_check_limit_for_personal_user?.split("-")[0])}
                                                        onChange={handleCheckedChange2}/>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 px-2 mb-2 mb-0 mt-1">
                                    <div class="field">
                                        <span className="mt-3 fw-500">
                                            Allow customers to move-in with the credit score for
                                            business user
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 px-2 mb-2 ">
                                    <div class="field">
                                        <Dropdown className="CheckDropdown" onMouseLeave={()=>SetOpenDropdown(false)} simple={openDropdown} onClick={()=>SetOpenDropdown(true)} fluid item placeholder='Credit Score'>
                                            <Dropdown.Menu>
                                                {creditScoreBusinessOption.map(({ id, title ,additionalValue, rating  }) => (
                                                    <Dropdown.Item key={id}>
                                                        <Checkbox label={title} 
                                                        name={additionalValue} 
                                                        rating={rating}
                                                        checked={rating >= credit_check_limit_for_business_user_rating}
                                                        onChange={handleCheckedChange1}/>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center my-3">
                        {/* <button type="button" className="ui button text-primary-light bg-transparent border-primary fw-400 px-5 mx-1">
                            Cancel
                        </button> */}
                        <button className="ui button bg-primary-light text-white fw-400 px-5 mx-1"  >
                            Save
                        </button>
                    </div>
                </form>
                <WelcomePopup />
            </div>
        </div>
    );
}