import React from "react";
import CreditCheckReport from "./CreditCheckReport";
import EsignatureReport from "./EsignatureReport";
import BankIdReports from "./BankIdReports";
import WelcomePopup from "../components/WelcomePopup";

const Reports = (props) => {
    return (
        <>
            {props.creditcheck && <CreditCheckReport />}
            {props.esign && <EsignatureReport />}
            {props.bankid && <BankIdReports />}
            <WelcomePopup />
        </>
    )
}

export default Reports