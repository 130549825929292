import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import HeaderComponent from "./app/components/HeaderComponent";
import Spinner from "./app/components/Spinner";
import SidebarComponent from "./app/components/SidebarComponent";
import CreditCheckSetting from "./app/pages/CreditCheckSetting";
import Dashboard from "./app/pages/Dashboard";
import EsignatureSetting from "./app/pages/EsignatureSetting";
import Integration from "./app/pages/Integration";
import Settings from "./app/pages/Settings";
import Tenants from "./app/pages/Tenants";
import { appContext } from "./app/context/appContext";
import "./styles.css";
import Toster from "./app/components/Toster/Toster";
import Login from "./app/auth/Login";
import Callback from "./app/auth/Callback";
import jwtDecode from "jwt-decode";
import EsignatureReport from "./app/pages/EsignatureReport";
import CreditCheckReport from "./app/pages/CreditCheckReport";
import EsignatureTenants from "./app/pages/EsignatureTenants";
import CreditCheckTenants from "./app/pages/CreditCheckTenants";
import CryptoJS from "crypto-js";
import Reports from "./app/pages/Reports";
import TenantsProfile from "./app/pages/TenantsProfile";
import { STATUS, EVENT_TYPE, TosterVariant, onKeyDown } from "./app/constant/constant"
import { IntegrationService } from './app/services/integrationService';


function App() {
  const windowWidth = window.innerWidth
  const [mobileWidth, SetmobileWidth] = useState()
  const [sidebar, ShowSidebar] = useState(false)

  var timeout;


  /* context */
  const [spinnerVisibility, setSpinnerVisibility] = useState(false)
  const [toasterProps, setToasterProps] = useState({ type: TosterVariant.success, message: "Success Message", visibility: false });
  const [userDetails, setUserDetails] = useState({})
  const [settingDetails, setSettingDetails] = useState({})
  const [hideHeaders,setHideHeaders]=useState(false)

  /* toster function */
  const showToaster = (type, message) => {
    clearTimeout(timeout)
    setToasterProps({ type, message, visibility: true });
    timeout = setTimeout(() => {
      setToasterProps({ type, message, visibility: false });
    }, TosterVariant.toasterDuration);
  }

  const menuvalidate = (data) => {
    alert(data);
  }

  const [showCreditCheckdashboard, setshowCreditCheckdashboard] = useState(true);
  const [showEsigndashboard, setshowEsigndashboard] = useState(false);
  const [showBankIddashboard, setshowBankIddashboard] = useState(false);

  const [esignEnable, setEsignEnable] = useState(false);
  const [creditCheckEnable, setCreditCheckEnable] = useState(false);
  const [bankIdEnable, setBankIdEnable] = useState(false);
  const [checkedValue,setCheckedValue]=useState([])

  const showCreditCheckInfo = (prop) => {
    if (creditCheckEnable || prop) {
      setshowCreditCheckdashboard(true)
      setshowEsigndashboard(false)
      setshowBankIddashboard(false)
    }
  }

  const showEsignInfo = (prop) => {
    if (esignEnable || prop) {
      setshowEsigndashboard(true)
      setshowBankIddashboard(false)
      setshowCreditCheckdashboard(false)
    }
  }

  const showBankIdInfo = (prop) => {
    if (bankIdEnable || prop) {
      setshowBankIddashboard(true)
      setshowEsigndashboard(false)
      setshowCreditCheckdashboard(false)
    }
  }

  const contextItem = { spinnerVisibility, hideHeaders, checkedValue, setCheckedValue, setHideHeaders , setSpinnerVisibility, showToaster, toasterProps, setToasterProps, userDetails, setUserDetails, settingDetails, setSettingDetails }
  /* context end */

  const clientData = (sessionStorage.getItem('oidc:sso')) ? sessionStorage.getItem('oidc:sso') : false;
  const dectiptiondata = async () => {
    if (clientData) {
      var decrypted = CryptoJS.AES.decrypt(clientData, "33d044268d5467b57bba473dd23bd66fac99825a977041e6b68358abe2882bf0");
      var decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
      var userdetailsDecrypt = JSON.parse(decryptedData);
      sessionStorage.setItem("access_token",userdetailsDecrypt?.access_token)
      var decodeAccessToken = jwtDecode(userdetailsDecrypt.access_token)
      setUserDetails(userdetailsDecrypt)
      sessionStorage.setItem("client_id", userdetailsDecrypt?.profile?.tenant)
      sessionStorage.setItem("client_name", userdetailsDecrypt?.profile?.name)
    }
  }

  /* Services */
  const integrationService = new IntegrationService();

  const integerationAPI = async (payloadData) => {
    try {
      setSpinnerVisibility(true)
      const { data } = await integrationService.integration({
        ...payloadData
      })
      setSpinnerVisibility(false)
      return data
    } catch (error) {
      setSpinnerVisibility(false)
      console.log("error", error);
      return error
    }
  }
  /* Service End */

  const getClientIntegration = async () => {
    try {
      const payload = {
        event_type: EVENT_TYPE.getClientIntegration,
        country_code: "NOR",
        integrated_with: "signicat"
      }
      const data = await integerationAPI(payload)
      if (data?.status === STATUS.success) {
        if (data?.body?.third_party_client_secret?.trim()?.length !== 0 && data?.body?.third_party_client_id?.trim()?.length !==0 ) {
          setBankIdEnable(true)
          setshowBankIddashboard(true)
          setshowEsigndashboard(false)
          setshowCreditCheckdashboard(false)
        }
        if (data?.body?.enable_esign) {
          setEsignEnable(true)
          setshowEsigndashboard(true)
          setshowBankIddashboard(false)
          setshowCreditCheckdashboard(false)
        }
        if (data?.body?.enable_credit_check) {
          setCreditCheckEnable(true)
          setshowCreditCheckdashboard(true)
          setshowEsigndashboard(false)
          setshowBankIddashboard(false)
        }
        if(!(data?.body?.enable_credit_check) && !(data?.body?.enable_esign) && !(data?.body?.third_party_client_secret?.trim()?.length !== 0 && data?.body?.third_party_client_id?.trim()?.length !==0 )){
          localStorage.removeItem('integration')
        }

      }
    } catch (error) {
      showToaster(TosterVariant.failed, "fetch ClientIntegration details failed")
      console.log(error);
    }
  }
  useEffect(() => {
    dectiptiondata();
    getClientIntegration();
  }, [])

  useEffect(() => {
    SetmobileWidth(windowWidth)
  }, [mobileWidth])

  if (clientData) {
    return (
      <>
        <appContext.Provider value={contextItem} >
        { <Spinner />}
          <SidebarComponent width={mobileWidth} sidebar={sidebar} showSidebar={ShowSidebar} />
          <div className={` pusher ${mobileWidth > 980 && 'pusher-desktop '} `} id='mainContent'>
            <HeaderComponent width={mobileWidth} sidebar={sidebar} showSidebar={ShowSidebar} onCreditCheck={showCreditCheckInfo} onEsign={showEsignInfo} onBankId={showBankIdInfo} creditcheck={showCreditCheckdashboard} esign={showEsigndashboard} bankid={showBankIddashboard} creditCheckEnable={creditCheckEnable} esignEnable={esignEnable} bankIdEnable={bankIdEnable} />
            <div className='main-wrapper'>
              <Routes>
                <Route path='/' element={<Dashboard creditcheck={showCreditCheckdashboard} esign={showEsigndashboard} bankid={showBankIddashboard} />} />
                <Route path='/tenants' element={<Tenants creditcheck={showCreditCheckdashboard} esign={showEsigndashboard} bankid={showBankIddashboard} />} />
                <Route path='/reports' element={<Reports creditcheck={showCreditCheckdashboard} esign={showEsigndashboard} bankid={showBankIddashboard} />} />
                <Route path="/creditcheckTenants" element={<CreditCheckTenants />} />
                <Route path="/esignatureTenants" element={<EsignatureTenants />} />
                <Route path='/integration' element={<Integration setBankIdEnable={setBankIdEnable} setCreditCheckEnable={setCreditCheckEnable} setEsignEnable={setEsignEnable} setshowCreditCheckdashboard={setshowCreditCheckdashboard} setshowEsigndashboard={setshowEsigndashboard} setshowBankIddashboard={setshowBankIddashboard} creditcheck={showCreditCheckdashboard} esign={showEsigndashboard} bankid={showBankIddashboard} creditCheckEnable={creditCheckEnable} esignEnable={esignEnable} bankIdEnable={bankIdEnable} onCreditCheck={showCreditCheckInfo} onEsign={showEsignInfo} onBankId={showBankIdInfo} />} />
                <Route path='/creditcheckReport' element={<CreditCheckReport menuvalidate={menuvalidate} />} />
                <Route path='/esignatureReport' element={<EsignatureReport />} />
                <Route path='/settings' element={<Settings creditcheck={showCreditCheckdashboard} esign={showEsigndashboard} bankid={showBankIddashboard} />} />
                <Route path='/creditcheckSettings' element={<CreditCheckSetting />} />
                <Route path='/esignatureSettings' element={<EsignatureSetting />} />
              </Routes>
            </div>
          </div>
          <Toster />
        </appContext.Provider>
      </>
    );
  }
  else {
    return (
      <div className="App">
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/callback' element={<Callback />} />
        </Routes>
      </div>
    );
  }

}

export default App;
