import {  Image } from 'semantic-ui-react';

export const API_ENDPOINTS = {
    integration: "integration",
    creditCheck: "creditcheck"
}

export const EVENT_TYPE = {
    createClientIntegration: "CREATE_CLIENT_INTEGRATION",
    enableClientFetures: "ENABLE_CLIENT_FETURES",
    enableDigitalSolutionsApplication: "ENABLE_DIGITAL_SOLUTIONS_APPLICATION",
    getClientIntegration: "GET_CLIENT_INTEGRATION",
    getDigitalSolutionApplicationStatus: "GET_DIGITAL_SOLUTIONS_APPLICATION_STATUS",
    upsertCreditCheckSettings: "UPSERT_CREDIT_CHECK_SETTINGS",
    getCreditCheckSettings: "GET_CREDIT_CHECK_SETTINGS",
    getTenantCredidCheckDetails: "GET_TENANT_CREDIT_CHECK_DETAILS",
    getTenantBankIdDetails:"GET_TENANT_BANK_ID_VERIFICATION_DETAILS",
    getTenantEsignDetails: "GET_TENANT_ESIGN_DETAILS",
    upsertEsignSettings: "UPSERT_ESIGN_SETTINGS",
    getEsignSettings: "GET_ESIGN_SETTINGS",
    creditCheckEnquiry: "CREDIT_CHECK_ENQUIRY",
    getClientFeatureMetrics: "GET_CLIENT_FEATURE_METRICS",
    getTenantDetailsAndStore : "GET_TENANT_DETAILS_AND_STORE",
    getLocation: "GET_LOCATIONS",
    getBuilding: "GET_BUILDINGS"


}
export const STATUS = {
    success: 200,
    failure: 400
}

/* toster */
export const TosterVariant = {
    success: "Success",
    failed: "Failed",
    toasterDuration: 2000
}

/* on keydown function for not submittiong the form on enter key is pressed */
export const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
        keyEvent.preventDefault();
    }
}

/* rows per page selection */
export const ROW_OPTION = [10, 25, 50,100]

/* photo url */

export const PHOTO_URl_PERSONAL_USER = (data) => {
    const url = {
        1_20: "/assets/images/1-20.png",
        21_29: "/assets/images/21-29.png",
        30_50: "/assets/images/30-50.png",
        51_70: "/assets/images/51-70.png",
        71_100: "/assets/images/71-100.png"
    }
    if (0 < data && data < 20) {
        return url[1_20]
    }
    else if (21 < data && data < 29) {
        return url[21_29]
    }
    else if (30 < data && data < 50) {
        return url[30_50]
    }
    else if (51 < data && data < 70) {
        return url[51_70]
    }
    else if (71 < data && data < 100) {
        return url[71_100]
    }

}

export const PHOTO_URl_BUSINESS_USER = {
    A: "/assets/images/A.png",
    AA: "/assets/images/AA.png",
    AN: "/assets/images/AN.png",
    B: "/assets/images/B.png",
    C: "/assets/images/c.png",
    NR: "/assets/images/NR.png",

}

/* take laste 5 months function  */

export const takeLastFiveMonth = () => {
    var month = [];
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "Apr";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sep";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";

    var chartLabels = [];
    var d = new Date();
    d.setDate(1);
    for (var m_month = 0; m_month < 5; m_month++) {
        chartLabels[m_month] = month[d.getMonth()];
        d.setMonth(d.getMonth() - 1);
    }

    return chartLabels.reverse()
}

/* dropdown options and common for dashboard */

export const sortUnitOptions = [
    { key: 'All', text: 'All', value: 'All', name: "all" },
    { key: 'Today', text: 'Today', value: 'Today', name: "today" },
    { key: 'Yesterday', text: 'Yesterday', value: 'Yesterday', name: "yesterday" },
    { key: 'This Month', text: 'This Month', value: 'This Month', name: "this_month" }
]
export const CardOptions = [
    { key: 'All', text: 'All', value: 'All', name: "all" },
    { key: 'Today', text: 'Today', value: 'Today', name: "today" },
    { key: 'Yesterday', text: 'Yesterday', value: 'Yesterday', name: "yesterday" },
    { key: 'This Month', text: 'This Month', value: 'This Month', name: "this_month" }
]

export const trigger = (
    <span>
        <Image className='widget-dropdown-img' avatar src="/assets/images/menu.svg" />
    </span>
)

/* dashboard data end */

/* tenants page data */
export const tenantStatusOption = [
    {
        key: 1,
        text: "All",
        value: "ALL",
    },
    {
        key: 2,
        text: "Active",
        value: "ACTIVE",
    },
    {
        key: 2,
        text: "Inactive",
        value: "INACTIVE",
    },
];
export const tenantTypeOption = [
    {
        key: 1,
        text: "All",
        value: "All",
    },
    {
        key: 2,
        text: "Personal User",
        value: "PERSONAL_USER",
    },
    {
        key: 2,
        text: "Business User",
        value: "BUSINESS_USER",
    },
];
export const tenantLocationOption = [
    {
        key: 1,
        text: "All",
        value: "All",
    },
    {
        key: 2,
        text: "Missouri",
        value: "Missouri",
    },
];
/* tenant page data end */