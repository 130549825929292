import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

const EsignatureTab = ({tenantEsignDetails}) => {
  return (
    <div className="row py-1">
      <div className="col-lg-3 col-md-3 col-sm-6 mb-2">
        <p className="fs-7 fw-500">E-Signature</p>
      </div>
      <div className="col-lg-9 col-md-9 col-sm-6 col-sm-6 mb-2">
        <p className="fs-7 d-flex">
          <span className="fs-7 fw-500 mr-3">:</span>{tenantEsignDetails?.esign_details ? "Signed with BankID" : "N/A"}
        </p>
      </div>
      <div className="col-lg-3 col-md-3 col-sm-6 mb-2">
        <p className="fs-7 fw-500">Signed in date & time</p>
      </div>
      <div className="col-lg-9 col-md-9 col-sm-6 mb-2">
        <p className="fs-7 d-flex">
          <span className="fs-7 fw-500 mr-3">:</span>{tenantEsignDetails?.esign_details?.modified_on ? moment(tenantEsignDetails?.esign_details?.modified_on).format("DD-MM-YYYY hh:mm") : <p>N/A</p>}
        </p>
      </div>
      <div className="col-lg-3 col-md-3 col-sm-6 mb-2">
        <p className="fs-7 fw-500">Documents</p>
      </div>
      <div className="col-lg-9 col-md-9 col-sm-6 mb-2">
        <p className="fs-7 d-flex">
          <span className="fs-7 fw-500 mr-3">:</span>
          {tenantEsignDetails?.esign_details?.document_path ? <a target={"_blank"} href={tenantEsignDetails?.esign_details?.document_path}>Lease Agreements</a> : <p>N/A</p>}
        </p>
      </div>
    </div>
  );
};

export default EsignatureTab;
